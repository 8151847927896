import React, { Dispatch, SetStateAction } from "react";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";

import FunctionOfBehaviorChart from "./FunctionOfBehaviorChart";
import useFunctionChart from "../../hooks/BehaviorPlans/useFunctionChart";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlanForm: BehaviorPlanRecord;
  setBehaviorPlanForm: Dispatch<SetStateAction<BehaviorPlanRecord>>;
};

const TargetBehaviorAnalysis: React.FC<Props> = ({
  selectedStudent,
  behaviorPlanForm,
  setBehaviorPlanForm,
}) => {
  const { chartData } = useFunctionChart({
    selectedStudent,
  });

  return <>{chartData && <FunctionOfBehaviorChart chart={chartData} />}</>;
};

export default TargetBehaviorAnalysis;
