import { useState } from "react";
import { fetchData } from "../../utils/util";
import { EmailNextSteps } from "../../types/enums";
import { isValidEmail } from "../../utils/authUtils";

const useContinueWithPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailNextSteps, setEmailNextSteps] = useState<EmailNextSteps | null>(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    isValidEmail(e.target.value) ? setIsEmailValid(true) : setIsEmailValid(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const searchForStaffEmail = async () => {
    setLoading(true);
    const response = await fetchData({ url: "api/checkForAuthUser", payload: { email } });
    const { providers } = await response.json();
    if (providers.length === 0) {
      setEmailNextSteps(EmailNextSteps.SIGN_UP);
    } else if (providers.includes("google.com")) {
      setEmailNextSteps(EmailNextSteps.GOOGLE);
    } else if (providers.includes("password")) {
      setEmailNextSteps(EmailNextSteps.SIGN_IN);
    } else {
      setEmailNextSteps(EmailNextSteps.ERROR);
      console.error(`AUTH ERROR: provider not found for ${email}`);
    }
    setLoading(false);
  };

  return {
    searchForStaffEmail,
    email,
    handleEmailChange,
    emailNextSteps,
    password,
    handlePasswordChange,
    isEmailValid,
    loading,
  };
};

export default useContinueWithPassword;
