import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BLANK_SURVEY_RESULT_FORM } from "../libraries/blankForms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { surveyFormAtom, surveyQuestionsAtom } from "../recoil/surveyAtoms";
import useAddFSDoc from "../hooks/shared/useAddFSDoc";
import useUpdateFSDoc from "../hooks/shared/useUpdateFSDoc";
import useGetFSDocs from "../hooks/shared/useGetFSDocs";
import useGetFSDoc from "../hooks/shared/useGetFSDoc";
import { Box, Button, Paper, Typography } from "@mui/material";
import { StudentRecord, SurveyLinkRecord, SurveyQuestionRecord } from "../types/types";
import SurveyForm from "../components/Surveys/SurveyForm";
import { Collections } from "../types/enums";
import { parseStudentResponse, parseSurveyQuestionsResponse } from "../libraries/parsers";
import { selectedStudentAtom } from "../recoil/userAtoms";

function SurveyLinkPage() {
  const [surveyForm, setSurveyForm] = useRecoilState(surveyFormAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const [questions, setQuestions] = useRecoilState(surveyQuestionsAtom);
  const [notAnswered, setNotAnswered] = useState<string[]>([]);
  const [surveyLink, setSurveyLink] = useState<SurveyLinkRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { getFSDocs } = useGetFSDocs();
  const { getFSDoc } = useGetFSDoc();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const studentId = queryParams.get("studentId");
  const passcode = queryParams.get("passcode");
  const staffId = queryParams.get("staffId");
  const behaviorId = queryParams.get("behaviorId");
  const name = queryParams.get("name");

  useEffect(() => {
    const getSelectedStudent = async () => {
      if (!studentId) return;
      const result = await getFSDoc<StudentRecord>({ col: Collections.Students, id: studentId });
      if (!result) return;
      setSelectedStudent(parseStudentResponse(result));
    };
    getSelectedStudent();
  });

  useEffect(() => {
    const getQuestions = async () => {
      const results = await getFSDocs<SurveyQuestionRecord>({
        col: Collections.Survey_Questions,
        config: { orderBy: ["order", "asc"] },
      });
      setQuestions(parseSurveyQuestionsResponse(results));
    };
    getQuestions();
  }, [getFSDocs, setQuestions]);

  useEffect(() => {
    setLoading(true);
    if (!studentId || !passcode || !staffId) return;
    const getSurveyLink = async () => {
      const response = await getFSDocs<SurveyLinkRecord>({
        col: "surveyLinks",
        config: {
          where: ["studentId", "==", studentId],
        },
      });
      if (response) {
        const currentLink = response.filter(
          (surveyLink) => surveyLink.passcode === passcode && surveyLink.compleated === false
        );

        if (currentLink.length === 1) {
          setSurveyLink(currentLink[0]);
        }
      }
      setLoading(false);
    };
    getSurveyLink();
  }, [getFSDocs, passcode, studentId, staffId]);

  const validateQuestions = () => {
    if (!questions) return;
    const tempArray: string[] = [];
    questions.forEach((question) => {
      if (!surveyForm.responses[question.id]) {
        tempArray.push(question.id);
      }
    });
    setNotAnswered(tempArray);
    return tempArray.length === 0 ? true : false;
  };
  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!studentId || !surveyLink || !staffId) return;
    const validated = validateQuestions();
    if (validated) {
      updateFSDoc({
        col: "surveyLinks",
        id: surveyLink.id,
        data: { ...surveyLink, compleated: true },
      });
      const data = { ...surveyForm, studentId: studentId, staffId: staffId };
      if ("id" in surveyForm) {
        updateFSDoc({ col: Collections.Surevey_Results, id: surveyForm.id, data: data });
      } else {
        addFSDoc({ col: Collections.Surevey_Results, data: data });
      }
      setSurveyForm(BLANK_SURVEY_RESULT_FORM);
    }
  };
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box sx={{ height: "100VH" }}>
          {surveyLink && behaviorId && name ? (
            <Box sx={{ pt: 4, pl: 8, pr: 8, pb: 4 }}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h2">Student Behavior Survey</Typography>
                <SurveyForm
                  notAnswered={notAnswered}
                  setNotAnswered={setNotAnswered}
                  behaviorId={behaviorId}
                  name={name}
                />
                <Button
                  fullWidth
                  sx={{ mt: 2, padding: 2 }}
                  onClick={handleSave}
                  variant="contained"
                >
                  Submit
                </Button>
              </Paper>
            </Box>
          ) : (
            <Box sx={{ pt: 12, pl: 8, pr: 8, pb: 4 }}>
              <Paper sx={{ padding: 6, textAlign: "center" }}>
                <Typography variant="h4">
                  This Link is Expired, Please Reach Out To The Person Who Sent You This Link To Get
                  A New Link.
                </Typography>
              </Paper>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default SurveyLinkPage;
