import { useRef } from "react";
import { StudentRecord } from "../../types/types";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import "react-quill/dist/quill.snow.css";
import { toolbarOptions } from "../../utils/quillUtils";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { COLORS } from "../../types/enums";
import GenerateRerunReason from "../shared/GenerateRerunReason";
import useReinforceStrategies from "../../hooks/BehaviorPlans/useReinforcementStrategies";
import InfoBar from "../InfoBar/InfoBar";

type Props = {
  selectedStudent: StudentRecord;
};

const ReinforceStrategies = ({ selectedStudent }: Props) => {
  const playerRef = useRef<any>(null);
  const {
    loading,
    handleFetchStrategy,
    handleResetAI,
    setRerunReason,
    behaviorPlanForm,
    handleTextChange,
    setOpen,
    open,
  } = useReinforceStrategies({ selectedStudent });

  return (
    <>
      <Typography textAlign={"center"} variant="h3">
        Reinforcement Strategies
      </Typography>
      <Box sx={{ mt: 1 }}>
        <InfoBar
          content="Reinforcement strategies are strategies that should be consistently used whenever the child
        correctly displaces the replacement behavior. They provide positive reinforcement increasing
        the likelihood that the child will continue to display the replacement behavior."
        />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {loading ? (
        <Box sx={{ minHeight: 300 }}>
          <Typography sx={{ color: COLORS.PRIMARY, mt: 2 }} variant="h5" textAlign={"center"}>
            Loading Your AI Recommended Reinforcement Strategies
          </Typography>
          <Player
            ref={playerRef}
            autoplay={true}
            loop={true}
            style={{ width: 300 }}
            src="https://lottie.host/4170c946-13ca-46b6-9e69-1bcf94cdf652/a6FOLfF0bI.json"
          />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, mb: 4 }}>
            {behaviorPlanForm.reinforcementStrategies ? (
              <Button color="primary" onClick={handleResetAI} size="large" variant="contained">
                Generate a New Set of Strategies
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={handleFetchStrategy}
                size="large"
              >
                Generate Reinforcement Strategies
              </Button>
            )}
          </Box>

          <ReactQuill
            theme="snow"
            value={behaviorPlanForm.reinforcementStrategies}
            onChange={handleTextChange}
            modules={{
              toolbar: toolbarOptions,
              clipboard: {
                allowed: {
                  tags: ["a", "u", "s", "i", "br", "ul", "ol", "li", "span"],
                  attributes: ["href"],
                },
              },
            }}
          />
        </>
      )}
      <GenerateRerunReason open={open} setOpen={setOpen} setReason={setRerunReason} />
    </>
  );
};

export default ReinforceStrategies;
