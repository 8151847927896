import React from "react";
import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./providers/AuthProvider";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorProvider from "./providers/ErrorProvider";

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <ErrorProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <AppBootstrap />
            </LocalizationProvider>
          </ThemeProvider>
        </ErrorProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
