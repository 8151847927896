import React from "react";
import LoginContainer from "../components/Login/LoginContainer";

const LoginPage = () => {
  return (
    <>
      <LoginContainer />
    </>
  );
};

export default LoginPage;
