import { TourProvider } from "@reactour/tour";
import React, { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { COLORS } from "../types/enums";
import useUpdateFSDoc from "../hooks/shared/useUpdateFSDoc";
import { loggedInStaffAtom } from "../recoil/userAtoms";
import { Collections } from "../types/enums";
import { tourNameAtom, tourStepsAtom } from "../recoil/tourAtoms";

type Props = {
  children: React.ReactNode;
};

const BXTourProvider = ({ children }: Props) => {
  const steps = useRecoilValue(tourStepsAtom);
  const tour = useRecoilValue(tourNameAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const tourKey = useMemo(() => JSON.stringify(steps), [steps]);
  const [loggedInStaff, setLoggedInStaff] = useRecoilState(loggedInStaffAtom);
  console.log(steps);

  const handleTourClose = async () => {
    if (!loggedInStaff || !tour) return;
    try {
      await updateFSDoc({
        col: Collections.Staff,
        data: { completedTours: [...loggedInStaff.completedTours, tour] },
        id: loggedInStaff.id,
      });
      setLoggedInStaff((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          completedTours: [...prev.completedTours, tour],
        };
      });
    } catch (error) {
      // handleError({
      //   error: `Error saving ${tour} tour completion: ${error}`,
      //   snackbarMessage: "Error updating tour status",
      //   eventType: LogEventType.ERROR_CLOSING_TOUR,
      //   file: "WillowTourProvider.tsx",
      // });
      console.error(`Error saving ${tour} tour completion: ${error}`);
    }
  };
  return (
    <TourProvider
      steps={steps}
      key={tourKey}
      styles={{
        popover: (base) => {
          return {
            ...base,
            fontSize: "14px",
            color: "#333",
            marginTop: 12,
            maxWidth: 300,
            borderRadius: 8,
          };
        },
        dot: (base) => {
          return {
            ...base,
            background: base.background === "none" ? "none" : COLORS.PRIMARY,
          };
        },
        badge: (base) => ({
          ...base,
          left: "auto",
          right: "-0.8125em",
          color: "#FFF",
          backgroundColor: COLORS.PRIMARY,
        }),
        controls: (base) => ({ ...base, marginTop: 20 }),
      }}
      onClickClose={async ({ setIsOpen }) => {
        await handleTourClose();
        setIsOpen(false);
      }}
      onClickMask={() => {
        return null;
      }}
    >
      {children}
    </TourProvider>
  );
};

export default BXTourProvider;
