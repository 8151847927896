import { Grid, TextField } from "@mui/material";
import React from "react";
import ProfilePicture from "../shared/ProfilePicture";
import useStudentFormChange from "../../hooks/ManageStudentDialog/useStudentFormChange";
import { useRecoilState } from "recoil";
import { studentFormAtom } from "../../recoil/userAtoms";
import { Collections } from "../../types/enums";

const ManageStudentForm = () => {
  const { handleTextChange } = useStudentFormChange();
  const [studentForm, setStudentForm] = useRecoilState(studentFormAtom);
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <ProfilePicture
            size={100}
            fileName={studentForm.avatar ? studentForm.avatar : ""}
            collection={Collections.Students}
            documentId={null}
            edit={true}
            setForm={setStudentForm}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={studentForm.firstName}
            onChange={handleTextChange}
            name="firstName"
            fullWidth
            label="First Name"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={studentForm.lastName}
            onChange={handleTextChange}
            name="lastName"
            fullWidth
            label="Last Name"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={studentForm.externalId}
            onChange={handleTextChange}
            name="externalId"
            fullWidth
            label="External Student Id (optional)"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={studentForm.grade}
            onChange={handleTextChange}
            name="grade"
            fullWidth
            label="Grade Level"
            required
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <DatePicker
            value={studentForm.dob ? DateTime.fromISO(studentForm.dob) : null}
            onChange={(date: DateTime | null) => handleDateChange({ name: "dob", value: date })}
            name="dob"
            sx={{ width: "100%" }}
            label="Date of Birth"
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default ManageStudentForm;
