import React from "react";
import { SurveyResultRecord } from "../../types/types";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { convertISOToReadable } from "../../utils/dateTimeUtils";
import { behaviors } from "../../libraries/abcData";
import FeatherIcon from "../shared/FeatherIcon";
import pieChart from "../../assets/icons/pie-chart.svg";
import { TourSelector } from "../../types/enums";

type Props = {
  surveyResult: SurveyResultRecord;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedSurvey: React.Dispatch<React.SetStateAction<SurveyResultRecord | null>>;
};

type FormatProps = {
  behaviors: { id: string; label: string }[];
  behaviorId: string;
};

const formatBehavior = ({ behaviorId, behaviors }: FormatProps) => {
  return behaviors.find((behavior) => behavior.id === behaviorId)?.label ?? "";
};

const SurveyCard = ({ surveyResult, setDeleteId, setSelectedSurvey }: Props) => {
  const handleDelete = () => {
    setDeleteId(surveyResult.id);
  };

  const handleView = () => {
    setSelectedSurvey(surveyResult);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          border: "1px solid #eee",
          borderRadius: 2,
          minHeight: 120,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{`${surveyResult.submitter}`}</Typography>
            <Box sx={{ borderRadius: 8, border: "1px solid #ddd", px: 2, py: 1, ml: 1 }}>
              <Typography>{surveyResult.relationship}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Tooltip title="View Survey Results">
              <img
                src={pieChart}
                data-tut={TourSelector.SEE_SURVEY_RESULTS}
                alt="Location"
                onClick={() => handleView()}
                style={{
                  width: 20,
                  cursor: "pointer",
                  filter:
                    "invert(21%) sepia(85%) saturate(5016%) hue-rotate(275deg) brightness(90%) contrast(93%)",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Survey">
              <IconButton disableRipple onClick={handleDelete}>
                <FeatherIcon icon="trashcan" width="16" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography>Target Behavior:</Typography>
          <Typography variant="h6" sx={{ ml: 1 }}>
            {formatBehavior({ behaviorId: surveyResult.behaviorId, behaviors })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography variant="body1">Date:</Typography>
          <Typography variant="h6" sx={{ ml: 1 }}>
            {convertISOToReadable(surveyResult.createdAt)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SurveyCard;
