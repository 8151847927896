import { useState } from "react";

const useSignInForm = () => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return { password, handlePasswordChange };
};

export default useSignInForm;
