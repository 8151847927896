import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../types/enums";
import clipboard from "../../assets/icons/clipboard.svg";
import stack from "../../assets/icons/stack.svg";
import chart from "../../assets/icons/chart.svg";
import right from "../../assets/icons/chevron-right.svg";

export default function TopNavbarTracker() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Box sx={topBarStyle}>
      <Box
        onClick={() => navigate(Routes.COLLECT_DATA)}
        sx={pathname === Routes.COLLECT_DATA ? selectedStyle : unselectedStyle}
      >
        <img src={clipboard} alt="Collect Data" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          1. Collect Data
        </Typography>
      </Box>
      <Box>
        <img src={right} alt="Right Arrow" style={{ width: 20 }} />
      </Box>
      <Box
        onClick={() => navigate(Routes.BUILD_PLAN)}
        sx={pathname === Routes.BUILD_PLAN ? selectedStyle : unselectedStyle}
      >
        <img src={stack} alt="Collect Data" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          2. Build Behavior Plan
        </Typography>
      </Box>
      <Box>
        <img src={right} alt="Right Arrow" style={{ width: 20 }} />
      </Box>
      <Box
        onClick={() => navigate(Routes.TRACK_PROGRESS)}
        sx={pathname === Routes.TRACK_PROGRESS ? selectedStyle : unselectedStyle}
      >
        <img src={chart} alt="Collect Data" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          3. Track Progress
        </Typography>
      </Box>
    </Box>
  );
}

const topBarStyle = {
  flexGrow: 1,
  height: "64px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  p: 1,
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
};

const selectedStyle = {
  borderRadius: 8,
  border: "1px solid #C077F8",
  background: "rgba(192, 119, 248,.06)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  px: 3,
  py: 3,
  height: 0,
  mx: 1,
  cursor: "pointer",
};

const unselectedStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  px: 3,
  py: 3,
  height: 0,
  borderRadius: 8,
  border: "1px solid #ddd",
  background: "#fafafa",
  mx: 1,
  cursor: "pointer",
  "&:hover": {
    border: "1px solid #C077F8",
    background: "rgba(192, 119, 248,.06)",
  },
};
