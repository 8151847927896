import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { surveyResultsAtom } from "../../recoil/surveyAtoms";
import { StudentRecord } from "../../types/types";
import { observationsAtom } from "../../recoil/observationAtoms";
import { behaviors } from "../../libraries/abcData";
import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";
import useCountTargetBehaviors from "../../hooks/BehaviorPlans/useCountTargetBehaviors";

// const DEMO_TEXT =
//   "When Jeremy is frustrated, he will shut down and stop doing his work. If you ask him to do something, he will say 'I can't do it' and refuse to try. He will also put his head down on his desk and refuse to engage with the teacher or the work.";

type Props = {
  selectedStudent: StudentRecord;
  setNextDisabled: (disabled: boolean) => void;
};

const TargetBehavior = ({ selectedStudent, setNextDisabled }: Props) => {
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { createBehaviorLabelCount } = useCountTargetBehaviors();
  const [behaviorsWithCountsInLabel, setBehaviorsWithCountsInLabel] = useState<
    { id: string; label: JSX.Element }[]
  >([]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setBehaviorPlanForm((prev) => ({ ...prev, targetBehavior: event.target.value }));
    setNextDisabled(false);
  };
  const surveys = useRecoilValue(surveyResultsAtom);
  const observations = useRecoilValue(observationsAtom);

  //basic text change handler
  const handleTextChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setBehaviorPlanForm((prev) => ({ ...prev, targetBehaviorNotes: value }));
    },
    [setBehaviorPlanForm]
  );

  useEffect(() => {
    const selectedStudentObservations = observations.filter(
      (observation) => observation.studentId === selectedStudent.id
    );
    const selectedStudentSurveys = surveys.filter(
      (survey) => survey.studentId === selectedStudent.id
    );
    const behaviorsWithCounts = createBehaviorLabelCount({
      behaviors,
      surveys: selectedStudentSurveys,
      observations: selectedStudentObservations,
    });
    setBehaviorsWithCountsInLabel(behaviorsWithCounts);
  }, [selectedStudent, surveys, observations, createBehaviorLabelCount]);
  return (
    <>
      <Typography textAlign={"center"} variant="h2">
        Target Behavior
      </Typography>
      <Typography textAlign={"center"} variant="body1" sx={{ mt: 1 }}>
        {`The target behavior is the behavior that you would like to see change through this behavior plan. Please select the behavior that you would like to target for ${selectedStudent.firstName}. Behaviors without a survey are not included in the list.`}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Select
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          value={behaviorPlanForm.targetBehavior}
          onChange={handleSelectChange}
          fullWidth
        >
          {behaviorsWithCountsInLabel.map((behavior) => (
            <MenuItem
              sx={{ wordBreak: "break-word", whiteSpace: "normal" }}
              key={behavior.id}
              value={behavior.id}
              className={isDropdownOpen ? "dropdown-open" : ""}
            >
              {behavior.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {behaviorPlanForm.targetBehavior && (
        <Box sx={{ mt: 2 }}>
          <Typography>
            {`Share in your own words a little more about what the target behavior looks like for ${selectedStudent.firstName}:`}
          </Typography>
          <TextField
            onChange={handleTextChange}
            fullWidth
            multiline
            rows={2}
            value={behaviorPlanForm.targetBehaviorNotes}
          />
          {/* <Button
            onClick={() =>
              setBehaviorPlanForm((prev) => ({ ...prev, targetBehaviorNotes: DEMO_TEXT }))
            }
          >
            Use Demo Data
          </Button> */}
        </Box>
      )}
    </>
  );
};

export default TargetBehavior;
