import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { surveyFormAtom } from "../../recoil/surveyAtoms";
import { SelectChangeEvent } from "@mui/material";
import { loggedInStaffAtom } from "../../recoil/userAtoms";

type Props = {
  behaviorId?: string;
  name?: string;
};

function useSurveyForm({ behaviorId, name }: Props) {
  const [surveyForm, setSurveyForm] = useRecoilState(surveyFormAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    const preFillName = loggedInStaff
      ? loggedInStaff.firstName + " " + loggedInStaff.lastName
      : name
      ? name
      : "";
    setSurveyForm((pV) => ({
      ...pV,
      submitter: preFillName,
      behaviorId: behaviorId || "",
    }));
  }, [behaviorId, loggedInStaff, name, setSurveyForm]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurveyForm((pV) => ({ ...pV, [event.target.name]: event.target.value }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSurveyForm((pV) => ({ ...pV, behaviorId: event.target.value }));
  };
  return { handleSelectChange, handleTextChange, surveyForm };
}

export default useSurveyForm;
