import React, { useState } from "react";
import logo from "../../assets/images/bp-logo-purple.png";
import { Box, Container, Typography } from "@mui/material";
import SupportMessageForm from "./SupportMessageForm";
import SupportConfirmationDialog from "./SupportConfirmationDialog";

const SupportContainer = () => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        mt: 8,
      }}
    >
      <img src={logo} alt="logo" width="200px" />
      <Typography variant="h1" sx={{ mt: 2 }}>
        Support
      </Typography>
      <Container maxWidth="sm">
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center", fontSize: 16 }}>
          Behavior Plan Assistant is currently in its pilot phase. Any issues or feedback is really
          appreciated and will help us make the app better for teachers and students across the
          country. Your message will go directly to the CEO and you will get a response, typically
          within 24 hours.
        </Typography>
        <SupportMessageForm setConfirmationOpen={setConfirmationOpen} />
      </Container>
      <SupportConfirmationDialog open={confirmationOpen} setOpen={setConfirmationOpen} />
    </Box>
  );
};

export default SupportContainer;
