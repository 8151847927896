import React from "react";
import { Box, Grid, Slider, Typography } from "@mui/material";
import GoalChart from "./GoalChart";
import { formatConsistency, formatEntrenched } from "../../utils/goalUtils";

const Goals = () => {
  const [frequency, setFrequency] = React.useState<number>(10);
  const [consistency, setConsistency] = React.useState<number>(0);
  const [entrenched, setEntrenched] = React.useState<number>(0);

  const handleFrequencyChange = (event: Event, newValue: number | number[]) => {
    setFrequency(newValue as number);
  };

  const handleConsistencyChange = (event: Event, newValue: number | number[]) => {
    setConsistency(newValue as number);
  };

  const handleEntrenchedChange = (event: Event, newValue: number | number[]) => {
    setEntrenched(newValue as number);
  };
  return (
    <>
      <Typography textAlign={"center"} variant="h3">
        Goals
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6">{`Current Frequency: ${frequency}`}</Typography>
          <Typography variant="body2">
            What is the current average frequency of the behavior per day?
          </Typography>
          <Box sx={{ p: 2 }}>
            <Slider
              min={0}
              max={30}
              aria-label="Frequency"
              value={frequency}
              onChange={handleFrequencyChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6">{`Consistency: ${formatConsistency(consistency)}`}</Typography>
          <Typography variant="body2">
            How confident are you the plan will be implemented consistently during the school day?
          </Typography>
          <Box sx={{ p: 2 }}>
            <Slider
              min={1}
              max={4}
              step={1}
              marks
              aria-label="Consistency"
              value={consistency}
              onChange={handleConsistencyChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6">{`Entrenched: ${formatEntrenched(entrenched)}`}</Typography>
          <Typography variant="body2">
            How long has the child been engaging in this behavior?
          </Typography>
          <Box sx={{ p: 2 }}>
            <Slider
              min={1}
              max={4}
              step={1}
              marks
              aria-label="Entrenched"
              value={entrenched}
              onChange={handleEntrenchedChange}
            />
          </Box>
        </Grid>
      </Grid>
      <GoalChart startingFrequency={frequency} consistency={consistency} entrenched={entrenched} />
    </>
  );
};

export default Goals;
