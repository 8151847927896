import React, { useCallback, useContext, useState } from "react";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { useRecoilState } from "recoil";
import { locationsAtom } from "../../recoil/observationAtoms";
import { Collections } from "../../types/enums";
import { ErrorContext } from "../../contexts/ErrorContext";

type Props = {
  editId: string;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const useEditLocation = ({ editId, setEditId }: Props) => {
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const { setErrorMessage } = useContext(ErrorContext);
  const [locationName, setLocationName] = useState(
    locations.find((loc) => loc.id === editId)?.name || ""
  );
  const { updateFSDoc } = useUpdateFSDoc();

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationName(e.target.value);
  }, []);

  const handleEdit = useCallback(async () => {
    try {
      await updateFSDoc({ col: Collections.Locations, data: { name: locationName }, id: editId });
      setLocations((prev) =>
        prev.map((loc) => (loc.id === editId ? { ...loc, name: locationName } : loc))
      );
      setEditId(null);
    } catch (error) {
      console.error(error);
      setErrorMessage("Sorry, there was an error updating the location. Please try again.");
    }
  }, [editId, locationName, setEditId, setErrorMessage, setLocations, updateFSDoc]);

  return { handleTextChange, handleEdit, locationName };
};

export default useEditLocation;
