import { atom, selector } from "recoil";
import {
  SurveyQuestion,
  SurveyQuestionRecord,
  SurveyResult,
  SurveyResultRecord,
} from "../types/types";
import { BLANK_SURVEY_QUESTION_FORM, BLANK_SURVEY_RESULT_FORM } from "../libraries/blankForms";
import { selectedStudentAtom } from "./userAtoms";
// import { behaviorDataDateRangeAtom } from "./behaviorPlansAtoms";

// const filterSurveysByDateGetter = ({ get }: { get: GetRecoilValue }) => {
//   const dateRange = get(behaviorDataDateRangeAtom);
//   const surveyResults = get(functionSurveyResultsAtom);
//   const [start, end] = dateRange;
//   if (!start || !end || !surveyResults) return [];
//   const filteredSurveyResults = surveyResults.filter(
//     (surveyResult) =>
//       surveyResult.createdAt &&
//       surveyResult.createdAt.toMillis() > start.toMillis() &&
//       surveyResult.createdAt.toMillis() < end.toMillis()
//   );
//   return filteredSurveyResults;
// };

// export const questionsObjectGetter = ({ get }: { get: GetRecoilValue }) => {
//   const questions = get(functionSurveyQuestionsAtom);
//   if (!questions) return null;
//   const tempObj: { [key: string]: SurveyQuestionRecord } = {};
//   questions.forEach((question) => {
//     tempObj[question.id] = question;
//   });
//   return tempObj;
// };

export const surveyQuestionsAtom = atom<SurveyQuestionRecord[]>({
  key: "surveyQuestions",
  default: [],
});

export const surveyQuestionFormAtom = atom<SurveyQuestion | SurveyQuestionRecord>({
  key: "surveyQuestionForm",
  default: BLANK_SURVEY_QUESTION_FORM,
});

export const surveyOpenAtom = atom({
  key: "surveyOpen",
  default: false,
});

export const surveyResultsAtom = atom<SurveyResultRecord[]>({
  key: "surveyResults",
  default: [],
});

export const surveyFormAtom = atom<SurveyResult | SurveyResultRecord>({
  key: "surveyForm",
  default: BLANK_SURVEY_RESULT_FORM,
});

export const selectedStudentSurveyResultsAtom = selector({
  key: "selectedStudentSurveyResults",
  get: ({ get }) => {
    const selectedStudent = get(selectedStudentAtom);
    const surveyResults = get(surveyResultsAtom);
    if (!selectedStudent) return [];
    return surveyResults.filter((surveyResult) => surveyResult.studentId === selectedStudent.id);
  },
});

// export const surveysByDateAtom = selector({
//   key: "filteredSurveysByDate",
//   get: filterSurveysByDateGetter,
// });
