import { FUNCTIONS_OF_BEHAVIOR } from "../types/enums";
import { ABCData } from "../types/types";

export const antecedents: ABCData[] = [
  {
    id: "w2ivcmhs1hrz6ur3kwx101ah",
    label: "Child engages in the behavior when alone",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
  {
    id: "raxeg279b6dw92k7833ovuv0",
    label: "Activity or item removed",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "pfniqkn8mk6jfdlgzsmxh7w9",
    label: "Told to do a task or activity",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "37i8kig31a0fgfu8bqxnjpec",
    label: "Nothing",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "1805owjs6p7tm0u5m9fnu5pc",
    label: "A transition between activities or periods",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "o29la2vwu3c95oin5yfgtsw2",
    label: "Told no to a request",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "at9ckin5979b9yw0b0w98jk4",
    label: "Denied attention",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
];

export const behaviors: ABCData[] = [
  {
    id: "pp86ycdwltmbv1scnht8bkos",
    label: "Physicality (hitting, kicking, etc.)",
  },
  { id: "i5bl83dv2j97fp9i7lkwdnd0", label: "Task refusal" },
  { id: "cls99e5qrxwv3gi2v3jrcu68", label: "Elopement" },
  {
    id: "pfutmgwejqto8kq6xwnbc0t0",
    label: "Tantrums (yelling, destroying things, etc.)",
  },
  { id: "5tvxyxvucs515h7i5ivz2o7f", label: "Threats / rude language" },
  { id: "32ju11wwi41qdh0ok98jcwoi", label: "Self injurious behavior" },
  { id: "grghetkpu7jzl4gq9aw2ehcn", label: "Sexualized behaviors" },
  { id: "xtqzxhetakl8h9t6aobum1ed", label: "Blurting / interruptions / talking out" },
];

export const consequences: ABCData[] = [
  {
    id: "qu73yo3tl6flyncezftj7mje",
    label: "Self Soothing (rocking or flapping)",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
  {
    id: "6ao3q9nuhx42j4p5bhls0rmr",
    label: "Told no /stop",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "gb8nm36hk7p31q33dzg9cuqk",
    label: "Task or activity ended",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "wdzcgzi4j1baue0d4ufynezv",
    label: "Item given back",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "vp1xw7nji8i9jrryfticd5z1",
    label: "Access continued to be given",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "46h68wnydtog9x4jhipfzedk",
    label: "Ignored",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "1sg5xxpovrr7c9yhl61sjbjs",
    label: "Isolated within class",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "8i6admhzplg0za47lrrds8qy",
    label: "Verbal redirection",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "vv7gn8qqyrt6kavhoa2opa26",
    label: "Peer attention",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
];

// export const settings: ABCData[] = [
//   { id: "9i9nb755izt42muqhjyj73vh", label: "Classroom" },
//   { id: "gl70ccrwtsati4qd36p4god5", label: "Playground" },
//   { id: "zauc4to992p2go2ay78qu06p", label: "Cafeteria" },
//   { id: "1xp58wfeawqc31fy7ncuuu4j", label: "Hallway" },
//   { id: "7avj1n689huezz0t9lqh9e6j", label: "Bathroom" },
//   { id: "i99smsists1f03wyc0mjze3u", label: "Gym" },
//   { id: "lohriiq9nwgqiix3xtbhwygy", label: "Office" },
//   { id: "bsridpq027jqehjx4s4pyb66", label: "Other" },
// ];

export const replacementBehaviors = [
  {
    id: "e0lsoxj31o0c9rtb4on8wzno",
    label: "Raising their hand and waiting to be called on",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "nzoax3a8u93i0f332xakfy0w",
    label: "Asking a peer to play and then waiting for their response",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "zmh34nzevu8ti7l3mkx91u60",
    label: "Using a special hand gesture to indicate they need something from the teacher",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "8wjjl32cls1gg8ckf1x8qf6k",
    label: "Using a designated card to indicate they need something from the teacher",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ATTENTION,
  },
  {
    id: "wrim84uszq3xoaxsxs89ei7e",
    label: "Asking for a break",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "hi2y6ienxry7zyh8kbj8072j",
    label: "Asking for a reduced assignment",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "9s51vr55os6mt6fjux13lyqg",
    label: "Asking for an alternate assignment",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "3xmp6ke9it5ajvgr4h6ibu23",
    label: "Asking for help from the teacher",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "d320ncqhd2om8kyir6leir9c",
    label: "Asking for help from a designated buddy",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ESCAPE,
  },
  {
    id: "2hyi5gr19e0qz8l8acpayien",
    label: "Raising their hand to ask for what they want",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "td05jwdbxrt4z6plk2gs78sp",
    label: "Using kind words when asking a peer to share or gain access to an item",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "obw1jflr5q1afqhzgikuqtpk",
    label: "Using picture cards to indicate what they want",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "ndqsg7elusto1zfwo1mqqa7k",
    label: "Asking for an alternate item if what they want is unavailable",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.ACCESS,
  },
  {
    id: "gtir0hs0ef9za77gonj7fzbf",
    label: "Asking for noise cancelling headphones",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
  {
    id: "bs9gpu77mpougti7bhuz2gd9",
    label: "Asking for a fidget",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
  {
    id: "6lvduuzq1fcfzjzqzs27pejy",
    label: "Asking for special seating or furniture",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
  {
    id: "to97b4agxhw79p53bp27u3mr",
    label: "Asking to go to a designated place in the classroom to meet a sensory need",
    functionOfBehavior: FUNCTIONS_OF_BEHAVIOR.SENSORY,
  },
];
