import React from "react";
import BehaviorPlanForPrint from "./BehaviorPlanForPrint";
import Header from "./Header";
import Footer from "./Footer";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlan: BehaviorPlanRecord;
};

const PrintTable = ({ selectedStudent, behaviorPlan }: Props) => {
  return (
    <>
      <Header />
      <Footer />
      <table>
        <thead>
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <BehaviorPlanForPrint selectedStudent={selectedStudent} behaviorPlan={behaviorPlan} />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default PrintTable;
