import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import useEditLocation from "../../hooks/Settings/useEditLocation";

type Props = {
  editId: string;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const EditLocation = ({ editId, setEditId }: Props) => {
  const { handleTextChange, handleEdit, locationName } = useEditLocation({ editId, setEditId });

  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Location</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ width: "100%", mt: 1 }}
            label="Location Name"
            value={locationName}
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditId(null)}>Cancel</Button>
          <Button onClick={handleEdit}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLocation;
