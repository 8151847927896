import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { StudentRecord } from "../../types/types";
// import { convertISOtoMMDDYYYY } from "../../utils/dateTimeUtils";
import FeatherIcon from "../shared/FeatherIcon";
import useStudentCard from "../../hooks/Settings/useStudentCard";

type Props = {
  student: StudentRecord;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StudentCard = ({ student, setOpen, setDeleteId, setDeleteOpen }: Props) => {
  const { avatarURL, handleEdit, handleDelete } = useStudentCard({
    student,
    setOpen,
    setDeleteId,
    setDeleteOpen,
  });

  return (
    <Box sx={{ borderRadius: 4, border: "1px solid #ddd", p: 2, m: 1 }}>
      <Avatar
        src={avatarURL ? avatarURL : ""}
        sx={{ bgcolor: "#fac433", width: 80, height: 80, margin: "auto" }}
      >
        {student.firstName.charAt(0)}
        {student.lastName.charAt(0)}
      </Avatar>
      <Typography align="center" variant="h6" sx={{ mt: 1 }}>
        {student.firstName} {student.lastName}
      </Typography>
      <Typography align="center" variant="body1">
        Grade: {student.grade}
      </Typography>

      {/* <Typography align="center" variant="body1">
        Birthday: {convertISOtoMMDDYYYY(student.dob)}
      </Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            onClick={handleEdit}
            sx={{ mt: 2 }}
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<FeatherIcon icon="edit" width="20" />}
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleDelete}
            sx={{ mt: 2 }}
            size="small"
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<FeatherIcon icon="trashcan" width="20" />}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentCard;
