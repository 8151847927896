import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { locationsAtom, observationFormAtom } from "../../recoil/observationAtoms";
import { LocationRecord } from "../../types/types";

const DesktopLocationPicker = () => {
  const [observationForm, setObservationForm] = useRecoilState(observationFormAtom);
  const locations = useRecoilValue(locationsAtom);

  return (
    <>
      <Autocomplete
        id="location-picker"
        sx={{ mt: 1 }}
        getOptionLabel={(option) => option.name}
        options={locations}
        value={locations.find((location) => location.id === observationForm.locationId) ?? null}
        onChange={(event: any, newValue: LocationRecord | null) => {
          setObservationForm((pV) => ({ ...pV, locationId: newValue?.id ?? "" }));
        }}
        renderInput={(params) => <TextField {...params} label="Location" />}
      />
    </>
  );
};

export default React.memo(DesktopLocationPicker);
