import {
  FUNCTIONS_OF_BEHAVIOR,
  FUNCTIONS_OF_BEHAVIOR_DISPLAY,
  FUNCTION_SURVEY_OPTIONS,
} from "../types/enums";
import { behaviors } from "../libraries/abcData";

export const formatResults = (result: string) => {
  let color = "#fff";
  switch (result) {
    case FUNCTION_SURVEY_OPTIONS.AGREE:
      color = "#CEF3E1";
      break;
    case FUNCTION_SURVEY_OPTIONS.DISAGREE:
      color = "#FFD6E4";
      break;
    case FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE:
      color = "#CBE0F6";
      break;
    case FUNCTION_SURVEY_OPTIONS.NA:
      color = "#eee";
      break;
    default:
      color = "#fff";
  }
  return color;
};

export const formatResultsText = (result: string) => {
  let color = "#333";
  switch (result) {
    case FUNCTION_SURVEY_OPTIONS.AGREE:
      color = "#0C311F";
      break;
    case FUNCTION_SURVEY_OPTIONS.DISAGREE:
      color = "#3D0015";
      break;
    case FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE:
      color = "#0F3357";
      break;
    case FUNCTION_SURVEY_OPTIONS.NA:
      color = "#333";
      break;
    default:
      color = "#fff";
  }
  return color;
};

type FormatNameProps = {
  questionString: string;
  firstName: string;
};

export const formatTextWithName = ({ questionString, firstName }: FormatNameProps) => {
  return questionString.replaceAll("*child*", `${firstName}`);
};

export const getBehaviorLabel = (behaviorId: string) => {
  return behaviors.find((behavior) => behavior.id === behaviorId)?.label;
};

export const formatFunction = (functionOfBehavior: FUNCTIONS_OF_BEHAVIOR) => {
  switch (functionOfBehavior) {
    case FUNCTIONS_OF_BEHAVIOR.ACCESS:
      return FUNCTIONS_OF_BEHAVIOR_DISPLAY.ACCESS;
    case FUNCTIONS_OF_BEHAVIOR.ATTENTION:
      return FUNCTIONS_OF_BEHAVIOR_DISPLAY.ATTENTION;
    case FUNCTIONS_OF_BEHAVIOR.ESCAPE:
      return FUNCTIONS_OF_BEHAVIOR_DISPLAY.ESCAPE;
    case FUNCTIONS_OF_BEHAVIOR.SENSORY:
      return FUNCTIONS_OF_BEHAVIOR_DISPLAY.SENSORY;
    default:
      return "Unknown";
  }
};
