import { useCallback, useState } from "react";

const useFetchStrategy = () => {
  const [loading, setLoading] = useState(false);

  type Props = {
    prompt: string;
    rerunReason: string;
    promptResponse: string;
  };

  const fetchStrategy = useCallback(
    async ({ prompt, rerunReason, promptResponse }: Props) => {
      if (!prompt) return;
      const promptToRun = rerunReason
        ? `${prompt} \n The last time you ran this prompt you gave this response ${promptResponse}. \n The user said they wanted something different and gave this reason as to why: ${rerunReason} so please really focus on meeting that need this time.`
        : prompt;
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}${`/ai/runAIPromptResponse`}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ prompt: promptToRun }),
          }
        );
        const { response } = await res.json();
        setLoading(false);
        return response;
      } catch (e: any) {
        console.error(e);
      }
      setLoading(false);
    },
    []
  );
  return { fetchStrategy, loading };
};

export default useFetchStrategy;
