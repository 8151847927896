import React from "react";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRecoilValue } from "recoil";
import { observationFormAtom } from "../../recoil/observationAtoms";
import { DateTime } from "luxon";
import useObservationFormChange from "../../hooks/Observations/useObservationFormChange";

const DesktopDatePicker = () => {
  const observationForm = useRecoilValue(observationFormAtom);
  const { handleDateChange } = useObservationFormChange();

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <DatePicker
          onChange={handleDateChange}
          sx={{ width: "100%" }}
          label="Observation Date"
          value={DateTime.fromISO(observationForm.date)}
          slotProps={{ textField: { fullWidth: true } }}
        />
      </Box>
    </>
  );
};

export default DesktopDatePicker;
