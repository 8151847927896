import React from "react";
import MobileObservationContainer from "../components/Observations/MobileObservationContainer";
import useWindowDimensions from "../hooks/shared/useWindowDimensions";
import DesktopDialog from "../components/Observations/DesktopObservationDialog";
import SideNavigation from "../components/Navigation/SideNavigation";
import { Box } from "@mui/material";

const ObservationPage = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideNavigation />
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <MobileObservationContainer />
        </Box>
      )}
      <DesktopDialog open={!isMobile} />
    </>
  );
};

export default ObservationPage;
