import React, { useEffect, useRef } from "react";
import { StudentRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { selectedStudentObservationsAtom } from "../../recoil/userAtoms";
import { locationsAtom } from "../../recoil/observationAtoms";
import { countLocationOccurrences } from "../../utils/calcUtils";
import { Box, Typography } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS } from "../../types/enums";

const colors = [COLORS.PRIMARY_DARK, COLORS.PRIMARY_MID, COLORS.NEUTRAL_DARK];

type Props = {
  selectedStudent: StudentRecord;
  targetBehavior: string;
};

const LocationsReport = ({ selectedStudent, targetBehavior }: Props) => {
  const observations = useRecoilValue(selectedStudentObservationsAtom);
  const locations = useRecoilValue(locationsAtom);
  const data = countLocationOccurrences(observations, locations, targetBehavior);
  const count = observations.filter((obs) => obs.behaviorId === targetBehavior).length;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const chart = chartRef.current;

    if (scrollContainer && chart) {
      const scrollToCenter = () => {
        const containerWidth = scrollContainer.clientWidth;
        const chartWidth = chart.clientWidth;
        const scrollLeft = (chartWidth - containerWidth) / 2;
        scrollContainer.scrollLeft = scrollLeft;
      };

      scrollToCenter();

      window.addEventListener("resize", scrollToCenter);

      return () => {
        window.removeEventListener("resize", scrollToCenter);
      };
    }
  }, []);
  return (
    <>
      <>
        <Typography variant="h3" sx={{ mt: 2 }} align="center">
          {`Total Observations with The Selected Behavior: ${count}`}
        </Typography>
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            overscrollBehaviorX: "contain",
          }}
          ref={scrollContainerRef}
        >
          <Box
            sx={{
              width: "100%",
              minWidth: "600px",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              ref={chartRef}
              sx={{
                width: "600px",
                height: "100%",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={1000} height={350}>
                  <Pie
                    data={data}
                    innerRadius={90}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={({ name, value }) => `${name} (${value})`}
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
      </>
    </>
  );
};

export default LocationsReport;
