import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import useDeleteLocationConfirmation from "../../hooks/Settings/useDeleteLocationConfirmation";

type Props = {
  deleteId: string;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
};

const DeleteLocationConfirmation = ({ deleteId, setDeleteId }: Props) => {
  const { handleDelete, deleteLoading } = useDeleteLocationConfirmation({ deleteId, setDeleteId });
  return (
    <>
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogTitle>Delete Location</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this location? This can not be undone and all observations
          tied to this location will no longer have a location associated with them.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(null)}>Cancel</Button>
          <Button color="error" onClick={handleDelete} disabled={deleteLoading}>
            {deleteLoading ? <CircularProgress size={20} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteLocationConfirmation;
