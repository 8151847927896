import useUpdateFSDoc from "../shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import { SurveyResultRecord } from "../../types/types";

type Props = {
  surveyId: string;
  recordToSubmit: SurveyResultRecord;
};

const useUpdateSurveyAnswerInFS = () => {
  const { updateFSDoc } = useUpdateFSDoc();
  const updateSurveyAnswerInFS = async ({ surveyId, recordToSubmit }: Props) => {
    try {
      await updateFSDoc({
        col: Collections.Surevey_Results,
        data: recordToSubmit,
        id: surveyId,
      });
    } catch (error) {}
  };
  return { updateSurveyAnswerInFS };
};

export default useUpdateSurveyAnswerInFS;
