import React from "react";
import TargetBehavior from "./TargetBehavior";
import { StudentRecord } from "../../types/types";
import ReplacementBehavior from "./ReplacementBehavior";
import TargetBehaviorAnalysis from "./TargetBehaviorAnalysis";
import { useRecoilState } from "recoil";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";

type Props = {
  selectedStudent: StudentRecord;
  setNextDisabled: (disabled: boolean) => void;
};

const Behaviors = ({ selectedStudent, setNextDisabled }: Props) => {
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  return (
    <>
      <TargetBehavior selectedStudent={selectedStudent} setNextDisabled={setNextDisabled} />
      {behaviorPlanForm.targetBehavior && (
        <TargetBehaviorAnalysis
          selectedStudent={selectedStudent}
          behaviorPlanForm={behaviorPlanForm}
          setBehaviorPlanForm={setBehaviorPlanForm}
        />
      )}
      <ReplacementBehavior selectedStudent={selectedStudent} />
    </>
  );
};

export default Behaviors;
