import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import FunctionOfBehaviorChart from "../BehaviorPlans/FunctionOfBehaviorChart";
import { ABCData, SurveyQuestionRecord, SurveyResultRecord } from "../../types/types";
import { surveyQuestionsAtom } from "../../recoil/surveyAtoms";
import { FUNCTION_SURVEY_OPTIONS, FUNCTIONS_OF_BEHAVIOR } from "../../types/enums";
import { behaviors } from "../../libraries/abcData";
import { ChartReport } from "../../types/types";
import { formatFunction } from "../../utils/formatUtils";

type Props = {
  selectedSurvey: SurveyResultRecord;
};

function returnPositiveOrZero(number: number): number {
  return number > 0 ? number : 0;
}

type CountFunctionProps = {
  questions: SurveyQuestionRecord[];
  selectedSurvey: SurveyResultRecord;
};

const countFunctions = ({ questions, selectedSurvey }: CountFunctionProps) => {
  const tempCounter: { [key: string]: number } = {
    [FUNCTIONS_OF_BEHAVIOR.ACCESS]: 0,
    [FUNCTIONS_OF_BEHAVIOR.ATTENTION]: 0,
    [FUNCTIONS_OF_BEHAVIOR.ESCAPE]: 0,
    [FUNCTIONS_OF_BEHAVIOR.SENSORY]: 0,
  };
  questions.forEach((question) => {
    if (!question.functionOfBehavior) return;
    const result = selectedSurvey.responses[question.id];
    switch (result) {
      case FUNCTION_SURVEY_OPTIONS.AGREE:
        tempCounter[question.functionOfBehavior]++;
        break;
      case FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE:
        tempCounter[question.functionOfBehavior] = tempCounter[question.functionOfBehavior] + 2;
        break;
      case FUNCTION_SURVEY_OPTIONS.DISAGREE:
        tempCounter[question.functionOfBehavior] = tempCounter[question.functionOfBehavior] - 1;
        break;
      default:
        break;
    }
  });
  return tempCounter;
};

function FunctionOfBehaviorContainer({ selectedSurvey }: Props) {
  const questions = useRecoilValue(surveyQuestionsAtom);
  const [chartData, setChartData] = useState<ChartReport | null>();
  const [selectedBehavior, setSelectedBehavior] = useState<ABCData | null>(null);

  useEffect(() => {
    if (!selectedSurvey) return;
    setSelectedBehavior(
      behaviors.find((behavior) => behavior.id === selectedSurvey.behaviorId) ?? null
    );
  }, [selectedSurvey]);

  useEffect(() => {
    if (!selectedBehavior || !questions) return;
    const counter = countFunctions({ questions, selectedSurvey });

    const tempChartReport: ChartReport = {
      behaviorLabel: selectedBehavior.label,
      bars: [
        {
          name: formatFunction(FUNCTIONS_OF_BEHAVIOR.ACCESS),
          amt: returnPositiveOrZero(counter[FUNCTIONS_OF_BEHAVIOR.ACCESS]),
        },
        {
          name: formatFunction(FUNCTIONS_OF_BEHAVIOR.ATTENTION),
          amt: returnPositiveOrZero(counter[FUNCTIONS_OF_BEHAVIOR.ATTENTION]),
        },
        {
          name: formatFunction(FUNCTIONS_OF_BEHAVIOR.ESCAPE),
          amt: returnPositiveOrZero(counter[FUNCTIONS_OF_BEHAVIOR.ESCAPE]),
        },
        {
          name: formatFunction(FUNCTIONS_OF_BEHAVIOR.SENSORY),
          amt: returnPositiveOrZero(counter[FUNCTIONS_OF_BEHAVIOR.SENSORY]),
        },
      ],
    };
    setChartData(tempChartReport);
  }, [selectedBehavior, selectedSurvey, questions]);

  return (
    <>{chartData && <FunctionOfBehaviorChart key={chartData.behaviorLabel} chart={chartData} />}</>
  );
}

export default FunctionOfBehaviorContainer;
