import { useEffect } from "react";

const useEnterKeyPress = (buttonId: string) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        const button = document.getElementById(buttonId) as HTMLButtonElement;
        if (button) {
          button.click();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [buttonId]);
};

export default useEnterKeyPress;
