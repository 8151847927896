import React from "react";
import { consequences } from "../../libraries/abcData";
import { Box, Typography } from "@mui/material";
import { observationFormAtom } from "../../recoil/observationAtoms";
import { useRecoilValue } from "recoil";
import useObservationFormChange from "../../hooks/Observations/useObservationFormChange";

//used for mobile and desktop

const ConsequencesList = () => {
  const observationForm = useRecoilValue(observationFormAtom);
  const { handleClick } = useObservationFormChange();

  return (
    <>
      <Typography textAlign="center" variant="h6" sx={{ mt: 2 }}>
        What Came After?
      </Typography>
      <Box sx={{ maxHeight: "40VH", overflowY: "scroll" }}>
        {consequences.map((consequence, index) => (
          <Box key={consequence.label} sx={{ m: 2 }}>
            <Box
              sx={
                observationForm.consequenceId === consequence.id ? selectedStyle : unselectedStyle
              }
              onClick={() => handleClick({ value: consequence.id, name: "consequenceId" })}
            >
              <Typography textAlign="center">{consequence.label}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default React.memo(ConsequencesList);

const selectedStyle = {
  background: "linear-gradient(90deg, #FBFFE8 0%, #CFFBF5 50%, #EEF3FF 100%)",
  borderRadius: "12px",
  px: 2,
  py: 1,
  margin: 1,
  cursor: "pointer",
  border: "1px solid #ddd",
};

const unselectedStyle = {
  background: "#fff",
  borderRadius: "12px",
  border: "1px solid #ddd",

  px: 2,
  py: 1,
  margin: 1,
  cursor: "pointer",
  "&:hover": {
    background: "linear-gradient(90deg, #FBFFE8 0%, #CFFBF5 50%, #EEF3FF 100%)",
    borderRadius: "12px",
    boxShadow: "0px 1px 4px rgba(200, 200, 200, 0.6)",
  },
};
