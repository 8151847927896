import { Alert, Box, Container, Divider, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import ContinueWithGoogle from "./ContinueWithGoogle";
import ContinueWithPassword from "./ContinueWithPassword";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/bp-logo-purple.png";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";

//currently can log in with google or email/password
const LoginContainer = () => {
  //error is log in error, message is for reset password
  const { error, message, setMessage, authUser } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (authUser) navigate("/collect-data");
  }, [authUser, navigate]);

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isMobile ? "100vh" : "80vh",
        }}
      >
        <Box>
          <Box>
            <img
              src={logo}
              alt="logo"
              style={{ width: "300px", margin: "auto", display: "block" }}
            />
          </Box>
          <Typography textAlign="center" variant="h2" sx={{ mt: 4 }}>
            Login or Sign Up
          </Typography>
          <ContinueWithGoogle />
          <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider>
          <ContinueWithPassword />
          {error && (
            <Alert
              sx={{ mt: 2, alignItems: "center" }}
              severity="error"
              variant="outlined"
              hidden={!error}
            >
              {error}
            </Alert>
          )}
          {message && (
            <Alert
              sx={{ mt: 2, alignItems: "center" }}
              severity="info"
              variant="outlined"
              onClose={() => setMessage(null)}
            >
              {message}
            </Alert>
          )}
        </Box>
      </Container>
    </>
  );
};

export default LoginContainer;
