import { TourSelector } from "../types/enums";

export const postSurveySteps = [
  {
    selector: `[data-tut="${TourSelector.SEE_SURVEY_RESULTS}"]`,
    content: "View the results of your survey to see the function of the behavior.",
  },
  {
    selector: `[data-tut="${TourSelector.BUILD_BX_PLAN}"]`,
    content: "Now that you've filled out a survey, you can build a behavior plan.",
  },
];
