import React from "react";
import { FUNCTION_SURVEY_OPTIONS } from "../../types/enums";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

type Props = {
  value: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

const RadioOptions = ({ value, handleChange }: Props) => {
  return (
    <>
      <FormControl>
        <RadioGroup onChange={handleChange} value={value} row name="question-response-options">
          <FormControlLabel
            value={FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE}
            control={<Radio />}
            label={FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE}
          />
          <FormControlLabel
            value={FUNCTION_SURVEY_OPTIONS.AGREE}
            control={<Radio />}
            label={FUNCTION_SURVEY_OPTIONS.AGREE}
          />
          <FormControlLabel
            value={FUNCTION_SURVEY_OPTIONS.DISAGREE}
            control={<Radio />}
            label={FUNCTION_SURVEY_OPTIONS.DISAGREE}
          />
          <FormControlLabel
            value={FUNCTION_SURVEY_OPTIONS.NA}
            control={<Radio />}
            label={FUNCTION_SURVEY_OPTIONS.NA}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioOptions;
