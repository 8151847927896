import { atom } from "recoil";
import { LocationRecord, Observation, ObservationRecord } from "../types/types";
import { BLANK_OBSERVATION_FORM } from "../libraries/blankForms";

export const observationFormAtom = atom<Observation>({
  key: "observationForm",
  default: BLANK_OBSERVATION_FORM,
});

export const observationEditIdAtom = atom<string | null>({
  key: "observationEditId",
  default: null,
});

export const mobileStageAtom = atom<number>({
  key: "mobileStage",
  default: 0,
});

export const observationsAtom = atom<ObservationRecord[]>({
  key: "observations",
  default: [],
});

export const locationsAtom = atom<LocationRecord[]>({
  key: "locations",
  default: [],
});
