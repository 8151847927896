import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { locationsAtom } from "../../recoil/observationAtoms";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import LocationCard from "./LocationCard";
import DeleteLocationConfirmation from "./DeleteLocationConfirmation";
import EditLocation from "./EditLocation";
import useLocationsContainer from "../../hooks/Settings/useLocationsContainer";

const LocationsContainer = () => {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [editId, setEditId] = useState<string | null>(null);
  const locations = useRecoilValue(locationsAtom);
  const { handleSubmit, handleTextChange, newLocation } = useLocationsContainer();
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 2, p: 2 }}>
        <Typography textAlign={"center"} variant="h3">
          Manage Locations
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            backgroundColor: "#fff",
            p: 2,
            mb: 2,
          }}
        >
          <TextField
            sx={{ width: "60%", mr: 2 }}
            label="Location Name"
            value={newLocation}
            onChange={handleTextChange}
          />
          <Button onClick={handleSubmit}>Add Location</Button>
        </Box>
        {locations.map((location) => (
          <LocationCard
            key={location.id}
            location={location}
            setDeleteId={setDeleteId}
            setEditId={setEditId}
          />
        ))}
      </Container>
      {editId && <EditLocation editId={editId} setEditId={setEditId} />}
      {deleteId && <DeleteLocationConfirmation deleteId={deleteId} setDeleteId={setDeleteId} />}
    </>
  );
};

export default LocationsContainer;
