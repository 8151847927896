import { useEffect, useState } from "react";
import { ChartReport, StudentRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { FUNCTIONS_OF_BEHAVIOR } from "../../types/enums";
import { formatFunction } from "../../utils/formatUtils";
import { selectedStudentObservationsAtom } from "../../recoil/userAtoms";

type Props = {
  selectedStudent: StudentRecord;
  targetBehavior: string;
};

const useFunctionsChart = ({ selectedStudent, targetBehavior }: Props) => {
  const selectedStudentObservations = useRecoilValue(selectedStudentObservationsAtom);
  const [chartData, setChartData] = useState<ChartReport | null>(null);

  useEffect(() => {
    const functionCounts = {
      [FUNCTIONS_OF_BEHAVIOR.ACCESS]: 0,
      [FUNCTIONS_OF_BEHAVIOR.ESCAPE]: 0,
      [FUNCTIONS_OF_BEHAVIOR.ATTENTION]: 0,
      [FUNCTIONS_OF_BEHAVIOR.SENSORY]: 0,
    };
    const relevantObservations = selectedStudentObservations.filter(
      (obs) => obs.behaviorId === targetBehavior
    );
    relevantObservations.forEach((obs) => {
      if (!obs.functionOfBehavior) return;
      functionCounts[obs.functionOfBehavior] += obs.functionConfidence;
    });
    setChartData({
      behaviorLabel: targetBehavior,
      bars: Object.entries(functionCounts).map(([functionName, count]) => ({
        name: formatFunction(functionName as FUNCTIONS_OF_BEHAVIOR),
        amt: Math.max(count, 0), // Ensure positive values for the chart
      })),
    });
  }, [selectedStudentObservations, selectedStudent.id, targetBehavior]);
  return { chartData };
};

export default useFunctionsChart;
