import { ABCData, LocationRecord, ObservationRecord } from "../types/types";

export function countBehaviorOccurrences(
  observations: ObservationRecord[],
  behaviors: ABCData[]
): { name: string; value: number }[] {
  const behaviorCounts: { [behaviorId: string]: number } = {}; // Object to track counts

  observations.forEach((observation) => {
    const behaviorId = observation.behaviorId;

    // If behaviorId is already seen, increment count, otherwise initialize to 1
    behaviorCounts[behaviorId] = (behaviorCounts[behaviorId] || 0) + 1;
  });

  // Transform the count object into an array
  const result = Object.entries(behaviorCounts).map(([behaviorId, count]) => ({
    behaviorId,
    count,
  }));
  const tableData = result.map((item) => {
    return {
      name: behaviors.find((behavior) => behavior.id === item.behaviorId)?.label || "",
      value: item.count,
    };
  });
  return tableData;
}

export function countLocationOccurrences(
  observations: ObservationRecord[],
  locations: LocationRecord[],
  targetBehavior: string
): { name: string; value: number }[] {
  const locationCounts: { [locationId: string]: number } = {}; // Object to track counts

  observations.forEach((observation) => {
    if (observation.behaviorId !== targetBehavior) return;
    const locationId = observation.locationId;

    // If behaviorId is already seen, increment count, otherwise initialize to 1
    locationCounts[locationId] = (locationCounts[locationId] || 0) + 1;
  });

  // Transform the count object into an array
  const result = Object.entries(locationCounts).map(([locationId, count]) => ({
    locationId,
    count,
  }));
  const tableData = result.map((item) => {
    return {
      name: locations.find((location) => location.id === item.locationId)?.name || "",
      value: item.count,
    };
  });
  return tableData;
}
