import { useState, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

type GetFSDocProps = {
  col: string;
  id: string;
};

type DBRecord = {
  id: string;
};

type GetFSDoc = <T extends DBRecord>(sendRequest: GetFSDocProps) => Promise<T | null>;

type GetDocType = {
  getFSDoc: GetFSDoc;
  isLoading: boolean;
  error: string | null;
};

const useGetFSDoc = (): GetDocType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getFSDoc = useCallback<GetFSDoc>(async <TS>({ col, id }: GetFSDocProps) => {
    setIsLoading(true);
    setError(null);
    try {
      const docRef = doc(db, col, id);
      const results = await getDoc(docRef);

      if (!results.data()) {
        return null;
      } else {
        return { ...results.data(), id: results.id } as TS;
      }
    } catch (err: any) {
      setError(err.message || "Something went wrong!");
      setIsLoading(false);
      return null;
    }
  }, []);
  return { isLoading, error, getFSDoc };
};

export default useGetFSDoc;
