import React from "react";
import { behaviors } from "../../libraries/abcData";
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

type Props = {
  targetBehavior: string | null;
  setTargetBehavior: React.Dispatch<React.SetStateAction<string | null>>;
};

const TargetBehaviorSelect = ({ targetBehavior, setTargetBehavior }: Props) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setTargetBehavior(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mt: 2 }}>
        <Typography variant="h5">Select Target Behavior</Typography>
        <Select
          sx={{ minWidth: 200, fontFamily: "InterDisplayMedium" }}
          value={targetBehavior ? targetBehavior : ""}
          onChange={handleSelectChange}
        >
          {behaviors.map((behavior) => (
            <MenuItem key={behavior.id} value={behavior.id}>
              {behavior.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default TargetBehaviorSelect;
