type FetchDataProps = {
  url: string;
  payload: any;
};

export const fetchData = async ({ url, payload }: FetchDataProps) => {
  const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response;
};
