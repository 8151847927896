import { useState, useCallback } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { BaseRecord } from "../../types/types";

interface SendRequestProps {
  col: string;
  data: {};
}

type AddFSDocType = (
  props: SendRequestProps
) => Promise<{ id: string; createdAt: string; lastUpdatedAt: string } | null>;

type AddDocType = {
  isLoading: boolean;
  error: string | null;
  addFSDoc: AddFSDocType;
};

const useAddFSDoc = (): AddDocType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const addFSDoc = useCallback<AddFSDocType>(async ({ col, data }) => {
    setIsLoading(true);
    setError(null);
    const now = new Date().toISOString();
    const finalData = {
      ...data,
      createdAt: now,
      lastUpdatedAt: now,
      active: true,
      lastActivity: now,
    };
    const docRef = collection(db, col);
    const results = await addDoc(docRef, finalData);
    setIsLoading(false);
    return ({ id: results.id, createdAt: now, lastUpdatedAt: now } as BaseRecord) || null;
  }, []);
  return { isLoading, error, addFSDoc };
};

export default useAddFSDoc;
