import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { staffFormAtom } from "../../recoil/userAtoms";

const useStaffFormChange = () => {
  const setStaffForm = useSetRecoilState(staffFormAtom);
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setStaffForm((pV) => (pV ? { ...pV, [name]: value } : null));
    },
    [setStaffForm]
  );

  return { handleTextChange };
};

export default useStaffFormChange;
