import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentObservationsAtom } from "../../recoil/userAtoms";
import { behaviors } from "../../libraries/abcData";
import BehaviorsChart from "./BehaviorsChart";
import FunctionChartContainer from "./FunctionsChart";
import TargetBehaviorSelect from "./TargetBehaviorSelect";
import { countBehaviorOccurrences } from "../../utils/calcUtils";
import { StudentRecord } from "../../types/types";
import ReportSelectMenu from "./ReportSelectMenu";
import LocationsReport from "./LocationsReport";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudent: StudentRecord;
};

const ObservationReportsDialog = ({ open, setOpen, selectedStudent }: Props) => {
  const observations = useRecoilValue(selectedStudentObservationsAtom);
  const tableData = countBehaviorOccurrences(observations, behaviors);
  const [selectedReport, setSelectedReport] = useState("frequency");
  const [targetBehavior, setTargetBehavior] = useState<string | null>(null);

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <Typography sx={{ p: 2 }} variant="h3">
          Observation Reports
        </Typography>
        <DialogContent>
          <ReportSelectMenu setSelectedReport={setSelectedReport} />
          {selectedReport === "frequency" && <BehaviorsChart data={tableData} />}
          {/* Having a target behavior selected is required for the two reports below */}
          {selectedReport !== "frequency" && (
            <TargetBehaviorSelect
              targetBehavior={targetBehavior}
              setTargetBehavior={setTargetBehavior}
            />
          )}
          {selectedReport === "function" && targetBehavior && (
            <FunctionChartContainer
              selectedStudent={selectedStudent}
              targetBehavior={targetBehavior}
            />
          )}

          {selectedReport === "location" && targetBehavior && (
            <LocationsReport selectedStudent={selectedStudent} targetBehavior={targetBehavior} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ObservationReportsDialog;
