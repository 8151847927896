import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";

const TypewriterText = ({ text, speed = 50 }: { text: string; speed: number }) => {
  const [displayText, setDisplayText] = useState("");
  const currentTextRef = useRef("");

  useEffect(() => {
    let i = 0;

    const typingInterval = setInterval(() => {
      if (i < text.length) {
        const nextChar = text.charAt(i);
        currentTextRef.current += nextChar;
        setDisplayText(currentTextRef.current);
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, speed]);

  return (
    <Typography textAlign={"left"} variant="h5" sx={{ fontWeight: 300 }}>
      {displayText}
    </Typography>
  );
};

export default TypewriterText;
