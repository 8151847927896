import React, { useCallback } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../types/enums";
import DesktopObservationForm from "./DesktopObservationForm";
import useSaveObservation from "../../hooks/Observations/useSaveObservation";
import { useRecoilState } from "recoil";
import { observationFormAtom } from "../../recoil/observationAtoms";
import { BLANK_OBSERVATION_FORM } from "../../libraries/blankForms";
import { isObservationFormValid } from "../../utils/formUtils";

type Props = {
  open: boolean;
};

const DesktopObservationDialog = ({ open }: Props) => {
  const { saveObservation } = useSaveObservation();
  const [observationForm, setObservationForm] = useRecoilState(observationFormAtom);
  const navigate = useNavigate();

  const handleSave = useCallback(() => {
    saveObservation();
    setObservationForm({ ...BLANK_OBSERVATION_FORM });
    navigate(Routes.DASHBOARD);
  }, [navigate, saveObservation, setObservationForm]);

  const handleCancel = useCallback(() => {
    setObservationForm(BLANK_OBSERVATION_FORM);
    navigate(Routes.DASHBOARD);
  }, [navigate, setObservationForm]);

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={() => navigate(Routes.DASHBOARD)}>
        <DialogTitle>Submit Observation</DialogTitle>
        <DialogContent>
          <DesktopObservationForm />
        </DialogContent>
        <DialogActions>
          <Button color="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={!isObservationFormValid(observationForm)} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DesktopObservationDialog;
