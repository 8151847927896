import { Box, CircularProgress } from "@mui/material";
import React from "react";

type Props = {
  size?: number;
};

const Loading = ({ size = 40 }: Props) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loading;
