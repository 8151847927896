import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { useState } from "react";
import ProfilePicture from "../shared/ProfilePicture";
import { Collections } from "../../types/enums";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, staffFormAtom } from "../../recoil/userAtoms";
import ProfileForm from "./ProfileForm";
import ProfileCard from "./ProfileCard";
import CloseButton from "../shared/CloseButton";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileDialog = ({ open, setOpen }: Props) => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [editMode, setEditMode] = useState(false);
  const setStaffForm = useSetRecoilState(staffFormAtom);

  return (
    <>
      {loggedInStaff && (
        <>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 1,
                px: 2,
                alignItems: "center",
              }}
            >
              <DialogTitle>Manage Profile</DialogTitle>
              <CloseButton onClick={() => setOpen(false)} />
            </Box>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProfilePicture
                    size={100}
                    fileName={loggedInStaff?.avatar ?? ""}
                    collection={Collections.Staff}
                    documentId={loggedInStaff.id}
                    edit={true}
                    setForm={setStaffForm}
                  />
                </Grid>
              </Grid>
              {editMode ? <ProfileForm setEditMode={setEditMode} /> : <ProfileCard />}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? "Cancel" : "Edit"}
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default ProfileDialog;
