import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import useCompleteProfileDialog from "../../hooks/CompleteProfile/useCompleteProfileDialog";
import { useAuth } from "../../contexts/AuthContext";
import { isStaffFormValid } from "../../utils/formUtils";
import TypewriterText from "./TypewriterText";
import logo from "../../assets/images/bp-logo-purple.png";
import useEnterKeyPress from "../../hooks/shared/useEnterKeyPress";
import { LoadingButton } from "@mui/lab";

const CompleteProfileDialog = () => {
  useEnterKeyPress("complete-profile-save-button");
  const { staffSignupOpen, handleTextChange, handleSave, staffForm, loading } =
    useCompleteProfileDialog();
  const { signOut, authUser } = useAuth();

  return (
    <Dialog open={staffSignupOpen} fullWidth maxWidth="md">
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <img src={logo} alt="logo" style={{ width: "200px", height: "120px" }} />
        </Box>
        <TypewriterText
          text="Welcome to Behavior Plan Assistant! We know all kids are capable of great things, some of them just need the right plan to get there. That's where we come in. Let's get started by filling out your profile."
          speed={35} // Adjust the speed as needed (milliseconds per character)
        />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              onChange={handleTextChange}
              fullWidth
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="lastName" onChange={handleTextChange} fullWidth label="Last Name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField disabled value={authUser?.email} fullWidth label="Email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="jobTitle" onChange={handleTextChange} fullWidth label="Job Title" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="districtName"
              onChange={handleTextChange}
              fullWidth
              label="School District Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="schoolName"
              onChange={handleTextChange}
              fullWidth
              label="School Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="schoolURL" onChange={handleTextChange} fullWidth label="School URL" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button color="error" onClick={signOut}>
          Logout
        </Button>

        <LoadingButton
          id="complete-profile-save-button"
          variant="contained"
          disabled={!isStaffFormValid(staffForm)}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteProfileDialog;
