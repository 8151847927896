import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import pieChart from "../../assets/icons/pie-chart.svg";
import plus from "../../assets/icons/plus.svg";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom, selectedStudentObservationsAtom } from "../../recoil/userAtoms";
import ObservationCard from "./ObservationCard";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../types/enums";
import DeleteObservationDialog from "./DeleteObservationDialog";
import ObservationReportsDialog from "../ObservationReports/ObservationReportsDialog";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";
import InfoBar from "../InfoBar/InfoBar";

const Observations = () => {
  const selectedStudentObservations = useRecoilValue(selectedStudentObservationsAtom);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [reportsOpen, setReportsOpen] = useState(false);
  const navigate = useNavigate();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { isMobile } = useWindowDimensions();
  if (!selectedStudent) return null;

  return (
    <>
      <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3" sx={{ color: "#555" }}>
          Optional Observations
        </Typography>

        <Box>
          <Button
            variant="defaultOutlined"
            sx={{ marginRight: 2 }}
            onClick={() => setReportsOpen(true)}
          >
            <img
              src={pieChart}
              alt="View Results"
              style={{ width: 20, marginRight: isMobile ? 0 : 4 }}
            />
            {!isMobile ? "View Results" : ""}
          </Button>
          <Button variant="defaultOutlined" onClick={() => navigate(Routes.OBSERVATION_FORM)}>
            <img src={plus} alt="Add New" style={{ width: 20, marginRight: isMobile ? 0 : 4 }} />
            {!isMobile ? "Add New" : ""}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <InfoBar content={ABC_DATA_TEXT} />
      </Box>

      <Box sx={{ mt: 2 }}>
        {selectedStudentObservations.length === 0 ? (
          <Typography variant="h5">No Observations</Typography>
        ) : (
          <Grid container spacing={2} sx={{ maxHeight: 500, overflowY: "scroll" }}>
            {selectedStudentObservations.map((record) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={record.id}>
                <ObservationCard observation={record} setDeleteId={setDeleteId} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <DeleteObservationDialog id={deleteId} setId={setDeleteId} />
      <ObservationReportsDialog
        open={reportsOpen}
        setOpen={setReportsOpen}
        selectedStudent={selectedStudent}
      />
    </>
  );
};

export default Observations;

const ABC_DATA_TEXT =
  "Collecting real-time data as target behaviors occur is the most accurate way to determine the function of a behavior. Observations are not required, but can help our AI system write even better behavior plans.";
