import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { StudentRecord } from "../../types/types";
import { useRecoilState } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";

type Props = {
  students: StudentRecord[];
};

const SelectStudent = ({ students }: Props) => {
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);

  const handleChange = (e: SelectChangeEvent) => {
    const foundStudent = students.find((student) => student.id === e.target.value);
    setSelectedStudent(foundStudent ? foundStudent : students[0]);
  };

  return (
    <>
      {students.length > 0 && (
        <Select
          fullWidth
          value={selectedStudent ? selectedStudent.id : students[0].id}
          sx={{ height: 48 }}
          onChange={handleChange}
        >
          {students.map((student) => (
            <MenuItem key={student.id} value={student.id}>
              {student.firstName} {student.lastName}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default SelectStudent;
