import { EmailNextSteps } from "../types/enums";

const emailLoginMessages = {
  check: "Enter your email and we'll check to see if you have an account or need to set one up.",
  signIn: "Welcome back! Enter your password below to log in or click below to reset your password",
  signUp: "Welcome! Create a password to get started using Behavior Plan Assistant",
  useGoogle:
    "It looks like you use Google to sign in. Click the button above that says 'Continue with Google'",
  providerNotFound:
    "There was an error with your account. Please email ryan@fireflystudios.io for support.",
};

export const formatNextStepsMessage = (nextSteps: EmailNextSteps | null) => {
  switch (nextSteps) {
    case EmailNextSteps.SIGN_IN:
      return emailLoginMessages.signIn;
    case EmailNextSteps.GOOGLE:
      return emailLoginMessages.useGoogle;
    case EmailNextSteps.ERROR:
      return emailLoginMessages.providerNotFound;
    case EmailNextSteps.SIGN_UP:
      return emailLoginMessages.signUp;
    default:
      return emailLoginMessages.check;
  }
};

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
