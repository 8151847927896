import { DatabaseProvider } from "./providers/DataProvider";
import ErrorSnackbar from "./components/shared/ErrorSnackbar";
import BXTourProvider from "./providers/BXTourProvider";
import { routes } from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { siteLoadingAtom } from "./recoil/loadingAtoms";
import { Box } from "@mui/material";
import Loading from "./components/shared/Loading";

const AppBootstrap = () => {
  const siteLoading = useRecoilValue(siteLoadingAtom);

  return (
    <>
      <DatabaseProvider>
        <BXTourProvider>
          {siteLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Loading size={80} />
            </Box>
          ) : (
            <Router>{routes}</Router>
          )}
        </BXTourProvider>
      </DatabaseProvider>
      <ErrorSnackbar />
    </>
  );
};

export default AppBootstrap;
