import { useState, useCallback } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface SetFSDocProps {
  col: string;
  data: {};
  id: string;
}

const useSetFSDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const setFSDoc = useCallback(async <T>({ col, data, id }: SetFSDocProps): Promise<T | null> => {
    setIsLoading(true);
    setError(null);
    try {
      const now = new Date().toISOString();
      const finalData = {
        ...data,
        createdAt: now,
        lastUpdatedAt: now,
        active: true,
        lastActivity: now,
      };
      const docRef = doc(db, col, id);
      const results = await setDoc(docRef, finalData);
      return { ...finalData, id } as T;
    } catch (err: any) {
      setError(err.message || "Something went wrong!");
      setIsLoading(false);
      return err.message || "Something went wrong!";
    }
  }, []);
  return { isLoading, error, setFSDoc };
};

export default useSetFSDoc;
