import { Box, Chip, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { COLORS } from "../../types/enums";
import { useRecoilState, useRecoilValue } from "recoil";
import { locationsAtom, observationFormAtom } from "../../recoil/observationAtoms";
import { DateTime } from "luxon";
import useObservationFormChange from "../../hooks/Observations/useObservationFormChange";

const MobileSettings = () => {
  const [observationForm, setObservationForm] = useRecoilState(observationFormAtom);
  const locations = useRecoilValue(locationsAtom);
  const { handleTextFormChange, handleClick } = useObservationFormChange();

  const handleDateChange = useCallback(
    (date: DateTime | null) => {
      setObservationForm((pV) => ({ ...pV, date: date?.toISO() ?? "" }));
    },
    [setObservationForm]
  );

  return (
    <>
      <Box sx={{ p: 1 }}>
        {/* Date */}
        <Typography textAlign="left" variant="h6" sx={{ mt: 1 }}>
          Date
        </Typography>
        <Box sx={{ p: 1 }}>
          <DatePicker
            onChange={handleDateChange}
            sx={{ width: "100%" }}
            label="observation-date-pickers"
            value={DateTime.fromISO(observationForm.date)}
          />
        </Box>

        {/* Location */}
        <Typography textAlign="left" variant="h6" sx={{ mt: 2 }}>
          Location
        </Typography>
        <Box sx={{ maxHeight: "40VH", overflowY: "scroll" }}>
          {locations.map((setting) => (
            <Box key={setting.name} sx={{ m: 2 }}>
              <Chip
                sx={{
                  width: "100%",
                  backgroundColor:
                    observationForm.locationId === setting.id
                      ? `${COLORS.GREEN} !important`
                      : COLORS.LIGHT_GRAY,
                }}
                label={setting.name}
                onClick={() => handleClick({ value: setting.id, name: "locationId" })}
              />
            </Box>
          ))}
        </Box>

        {/* Anything Else */}
        <Typography textAlign="left" variant="h6" sx={{ mt: 2 }}>
          Anything Else
        </Typography>
        <Typography textAlign="left" variant="body2">
          (Optional)
        </Typography>
        <Box sx={{ p: 1 }}>
          <TextField
            onChange={handleTextFormChange}
            multiline
            fullWidth
            rows={2}
            value={observationForm.anythingElse}
            name="anythingElse"
          />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(MobileSettings);
