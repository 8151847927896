import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentBehaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";
import { BehaviorPlanRecord } from "../../types/types";
import { Box, Button, Grid, Typography } from "@mui/material";
import plus from "../../assets/icons/plus.svg";
import ManageBehaviorPlanDialog from "../BehaviorPlans/ManageBehaviorPlanDialog";
import ShowBehaviorPlanDialog from "../BehaviorPlans/ShowBehaviorPlanDialog";
import DeleteBehaviorPlanDialog from "./DeleteBehaviorPlanDialog";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import BehaviorPlanCard from "./BehaviorPlanCard";
import InfoBar from "../InfoBar/InfoBar";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";
import { selectedStudentSurveyResultsAtom } from "../../recoil/surveyAtoms";
import { Player } from "@lottiefiles/react-lottie-player";
import { COLORS } from "../../types/enums";
import { Link } from "react-router-dom";

const BuildBehaviorPlansContainer = () => {
  const selectedStudentBehaviorPlans = useRecoilValue(selectedStudentBehaviorPlansAtom);
  const selectedStudentSurveyResults = useRecoilValue(selectedStudentSurveyResultsAtom);
  const [selectedBehaviorPlan, setSelectedBehaviorPlan] = useState<BehaviorPlanRecord | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [behaviorPlanOpen, setBehaviorPlanOpen] = useState(false);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { isMobile } = useWindowDimensions();
  const playerRef = useRef<any>(null);

  if (!selectedStudent) return null;
  return (
    <>
      {selectedStudentSurveyResults.length === 0 ? (
        <>
          <Box sx={{ p: 4 }}>
            <Typography
              sx={{ color: COLORS.PRIMARY, mt: 2, fontSize: 26 }}
              variant="h2"
              textAlign={"center"}
            >
              Before you can build a behavior plan, you need to fill out at least one behavior
              survey on the Collect Data Page for {selectedStudent.firstName}.
            </Typography>
            <Link to="/collect-data">
              <Typography sx={{ mt: 1, color: "#333" }} textAlign={"center"} variant="h5">
                Click Here To Go To the Collect Data Page
              </Typography>
            </Link>
            <Player
              ref={playerRef}
              autoplay={true}
              loop={true}
              style={{ width: 400 }}
              src="https://lottie.host/8644fcd4-91bd-411f-a2cf-99a4a742b99b/GKZhRHWnKh.json"
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ p: isMobile ? 1 : 4, pt: isMobile ? 1 : 4, pr: isMobile ? 1 : 2 }}>
            <InfoBar content="The second step is to use the data you've collected to build a personalized behavior plan. Our AI assisted guide will help you create a plan that is tailored to the child's specific needs in just a few minutes." />
            <Box
              sx={{ display: "flex", mt: 2, justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography variant="h3">Behavior Plans</Typography>
              <Box>
                <Button variant="defaultOutlined" onClick={() => setBehaviorPlanOpen(true)}>
                  <img src={plus} alt="Add New" style={{ width: 20, marginRight: 4 }} />
                  Add New
                </Button>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              {selectedStudentBehaviorPlans.length === 0 ? (
                <Typography variant="h5">No Behavior Plans</Typography>
              ) : (
                <Box sx={{ maxHeight: 600, overflowY: "scroll" }}>
                  <Grid container spacing={2}>
                    {selectedStudentBehaviorPlans.map((record, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={4} key={record.id}>
                        <BehaviorPlanCard
                          record={record}
                          index={index}
                          setDeleteId={setDeleteId}
                          setSelectedBehaviorPlan={setSelectedBehaviorPlan}
                          selectedStudent={selectedStudent}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      <ManageBehaviorPlanDialog
        open={behaviorPlanOpen}
        setOpen={setBehaviorPlanOpen}
        selectedStudent={selectedStudent}
      />
      {selectedBehaviorPlan && (
        <ShowBehaviorPlanDialog
          selectedStudent={selectedStudent}
          behaviorPlan={selectedBehaviorPlan}
          setBehaviorPlan={setSelectedBehaviorPlan}
        />
      )}
      <DeleteBehaviorPlanDialog id={deleteId} setId={setDeleteId} />
    </>
  );
};

export default BuildBehaviorPlansContainer;
