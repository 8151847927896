import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { StudentRecord } from "../../types/types";
import useManageTrackActuals from "../../hooks/TrackProgress/useManageTrackActuals";
import { ChangeEvent } from "react";

type Props = {
  selectedStudent: StudentRecord;
};

const ManageTrackingActuals = ({ selectedStudent }: Props) => {
  const { handleSave, setActualValue, handleSelectChange, selectedWeek, actualValue } =
    useManageTrackActuals({
      selectedStudent,
    });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setActualValue(null);
    } else {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setActualValue(parsedValue);
      }
    }
  };

  return (
    <>
      <Grid container spacing={4} sx={{ alignItems: "center", px: 4 }}>
        <Grid item xs={12} sm={4}>
          <Select fullWidth value={selectedWeek} onChange={handleSelectChange}>
            <MenuItem value={"weekOneActual"}>Week 1</MenuItem>
            <MenuItem value={"weekTwoActual"}>Week 2</MenuItem>
            <MenuItem value={"weekThreeActual"}>Week 3</MenuItem>
            <MenuItem value={"weekFourActual"}>Week 4</MenuItem>
            <MenuItem value={"weekFiveActual"}>Week 5</MenuItem>
            <MenuItem value={"weekSixActual"}>Week 6</MenuItem>
            <MenuItem value={"weekSevenActual"}>Week 7</MenuItem>
            <MenuItem value={"weekEightActual"}>Week 8</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="outlined-basic"
            label="Average Incidents Per day"
            variant="outlined"
            fullWidth
            value={actualValue}
            type="number"
            onChange={handleChange}
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            onClick={handleSave}
            sx={{ p: "12px" }}
            size="large"
            fullWidth
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageTrackingActuals;
