import { StudentRecord } from "../../types/types";
import { Box } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { COLORS } from "../../types/enums";
import useFunctionsChart from "../../hooks/ObservationReports/useFunctionsChart";

type Props = {
  selectedStudent: StudentRecord;
  targetBehavior: string;
};

const FunctionsChart = ({ selectedStudent, targetBehavior }: Props) => {
  const { chartData } = useFunctionsChart({ selectedStudent, targetBehavior });

  return (
    <>
      {chartData && (
        <Box sx={{ mt: 2 }}>
          <ResponsiveContainer height={400} minWidth={900}>
            <BarChart
              data={chartData.bars}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="4 3" />
              <XAxis dataKey="name" interval={0} height={30} angle={0} />
              <YAxis />
              <Bar dataKey="amt" fill={COLORS.PRIMARY} name={chartData.behaviorLabel} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
};

export default FunctionsChart;
