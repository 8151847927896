import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";

interface CustomRouteProps {
  children: React.ReactNode;
}

const CustomRoute = ({ children }: CustomRouteProps) => {
  const { authUser, loading } = useContext(AuthContext);

  return (
    <>
      {authUser && !loading && children}
      {!authUser && !loading && <Navigate to="/login" />}
    </>
  );
};

export default CustomRoute;
