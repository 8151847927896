import { useEffect } from "react";

function Footer() {
  const today = new Date().toDateString();

  useEffect(() => {
    document.title = `printed on ${today}.`;
  }, [today]);

  return <footer className="page-footer"></footer>;
}

export default Footer;
