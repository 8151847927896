import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SurveyResultRecord } from "../../types/types";
import SurveyContainer from "./SurveyContainer";

type Props = {
  selectedSurvey: SurveyResultRecord | null;
  setSelectedSurvey: React.Dispatch<React.SetStateAction<SurveyResultRecord | null>>;
};

function SurveyResultsDialog({ selectedSurvey, setSelectedSurvey }: Props) {
  const handleClose = () => {
    setSelectedSurvey(null);
  };
  return (
    <>
      {selectedSurvey && (
        <Dialog open={Boolean(selectedSurvey)} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle sx={{ fontSize: 44, textAlign: "center" }}>Survey Result</DialogTitle>
          <DialogContent>
            <SurveyContainer
              selectedSurvey={selectedSurvey}
              setSelectedSurvey={setSelectedSurvey}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default SurveyResultsDialog;
