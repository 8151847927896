import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { SurveyQuestionRecord, SurveyResultRecord } from "../../types/types";
import { surveyResultsAtom } from "../../recoil/surveyAtoms";
import useUpdateSurveyAnswerInFS from "./useUpdateSurveyAnswerInFS";
import useHandleError from "../shared/useHandleError";
import { EVENT_TYPE, LOG_SEVERITY } from "../../types/logEnums";
import useLogger from "../shared/useLogger";
import { buildSurveyData } from "../../utils/surveyUtils";

type Props = {
  question: SurveyQuestionRecord | null;
  currentOption: string | null;
  setOpen: (pV: boolean) => void;
  selectedSurvey: SurveyResultRecord | null;
  setSelectedSurvey: React.Dispatch<React.SetStateAction<SurveyResultRecord | null>>;
};

function useEditAnswerDialog({
  question,
  currentOption,
  setOpen,
  selectedSurvey,
  setSelectedSurvey,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<string | null>(currentOption);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setSurveyResults = useSetRecoilState(surveyResultsAtom);
  const [loading, setLoading] = useState(false);
  const { updateSurveyAnswerInFS } = useUpdateSurveyAnswerInFS();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();

  //questions in the db have the string *child* in them, this replaces that with the child's name
  const formatTextWithName = (questionString: string) => {
    const replacementString = selectedStudent ? selectedStudent.firstName : "the student";
    return questionString.replaceAll("*child*", replacementString);
  };

  //set the saved answer as the selected option
  useEffect(() => {
    setSelectedOption(currentOption);
  }, [currentOption]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSave = useCallback(async () => {
    if (!selectedSurvey || !question || !selectedOption) return;
    setLoading(true);
    const recordToSubmit: SurveyResultRecord = buildSurveyData({
      selectedSurvey,
      question,
      selectedOption,
    });
    try {
      await updateSurveyAnswerInFS({ surveyId: selectedSurvey.id, recordToSubmit });
      setSurveyResults((prev) =>
        prev.map((record) => (record.id === selectedSurvey.id ? recordToSubmit : record))
      );
      setSelectedSurvey(recordToSubmit);
      submitLog({
        severity: LOG_SEVERITY.INFO,
        eventType: EVENT_TYPE.UPDATE_SURVEY_ANSWER,
        file: "useEditAnswerDialog.ts",
        changeLog: `Survey answer updated to ${selectedOption} for question ${question.id} for survey ${selectedSurvey.id}`,
      });
    } catch (error) {
      handleError({
        error,
        snackbarMessage: "There was an error saving your survey results, please try again.",
        eventType: EVENT_TYPE.UPDATE_SURVEY_ANSWER_FAILED,
        file: "useEditAnswerDialog.ts",
      });
      setLoading(false);
    }
    setSelectedOption(null);
    handleClose();

    setLoading(false);
  }, [
    handleClose,
    handleError,
    question,
    selectedOption,
    selectedSurvey,
    setSelectedSurvey,
    setSurveyResults,
    submitLog,
    updateSurveyAnswerInFS,
  ]);
  return {
    selectedOption,
    handleChange,
    handleSave,
    handleClose,
    formatTextWithName,
    loading,
  };
}

export default useEditAnswerDialog;
