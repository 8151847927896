import { Box } from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  Area,
} from "recharts";
import { useRecoilValue } from "recoil";
import { behaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";
import { useEffect, useState } from "react";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";

type Props = {
  selectedStudent: StudentRecord;
};

type Week = {
  name: string;
  goal: number;
  actual: number | null | string;
};

function findHighestValue(dataArray: Week[]) {
  let highest = -Infinity; // Start with the lowest possible number

  for (const dataPoint of dataArray) {
    const { goal, actual } = dataPoint;
    highest = Math.max(highest, goal, typeof actual === "number" ? actual : 0);
  }

  return highest;
}

function TrackingChart({ selectedStudent }: Props) {
  const behaviorPlans = useRecoilValue(behaviorPlansAtom);
  const [chartData, setChartData] = useState<Week[]>([]);
  const [domain, setDomain] = useState<number[]>([0, 20]);
  const [activeBehaviorPlan, setActiveBehaviorPlan] = useState<BehaviorPlanRecord | null>(null);
  useEffect(() => {
    const filteredBehaviorPlan = behaviorPlans.find(
      (plan) => plan.id === selectedStudent.activeBehaviorPlanId
    );
    setActiveBehaviorPlan(filteredBehaviorPlan || null);
  }, [behaviorPlans, selectedStudent.activeBehaviorPlanId]);

  useEffect(() => {
    if (!activeBehaviorPlan) return;
    const tempData: Week[] = [
      {
        name: "Week 1",
        goal: activeBehaviorPlan.weekOneTarget,
        actual: activeBehaviorPlan.weekOneActual,
      },
      {
        name: "Week 2",
        goal: activeBehaviorPlan.weekTwoTarget,
        actual: activeBehaviorPlan.weekTwoActual,
      },
      {
        name: "Week 3",
        goal: activeBehaviorPlan.weekThreeTarget,
        actual: activeBehaviorPlan.weekThreeActual,
      },
      {
        name: "Week 4",
        goal: activeBehaviorPlan.weekFourTarget,
        actual: activeBehaviorPlan.weekFourActual,
      },
      {
        name: "Week 5",
        goal: activeBehaviorPlan.weekFiveTarget,
        actual: activeBehaviorPlan.weekFiveActual,
      },
      {
        name: "Week 6",
        goal: activeBehaviorPlan.weekSixTarget,
        actual: activeBehaviorPlan.weekSixActual,
      },
      {
        name: "Week 7",
        goal: activeBehaviorPlan.weekSevenTarget,
        actual: activeBehaviorPlan.weekSevenActual,
      },
      {
        name: "Week 8",
        goal: activeBehaviorPlan.weekEightTarget,
        actual: activeBehaviorPlan.weekEightActual,
      },
    ];
    setChartData(tempData);
    setDomain([0, findHighestValue(tempData)]);
  }, [activeBehaviorPlan]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <ResponsiveContainer height={400} minWidth={600}>
          <AreaChart
            width={730}
            height={250}
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#C077F8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#C077F8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#CFFBF5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#CFFBF5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis domain={domain} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="goal"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="actual"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default TrackingChart;
