import React from "react";
import { Box, Typography } from "@mui/material";
import Observations from "./Observations";
import Surveys from "./Surveys";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { Routes } from "../../types/enums";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";

const CollectDataContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { isMobile } = useWindowDimensions();
  return (
    <Box sx={{ p: isMobile ? 1 : 4, pt: isMobile ? 1 : 4, pr: isMobile ? 1 : 2 }}>
      {selectedStudent ? (
        <>
          <Surveys />
          <Box sx={{ mt: 12 }}>
            <Observations />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">First, You Need To Add A Student</Typography>
          <Link to={Routes.STUDENTS}>Click Here to Go To Your Students Page</Link>
        </Box>
      )}
    </Box>
  );
};

export default CollectDataContainer;
