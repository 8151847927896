import { ABCData, BehaviorPlanRecord, StudentRecord } from "../types/types";

type InjectVariablesProps = {
  prompt: string;
  behaviors: ABCData[];
  replacementBehaviors: ABCData[];
  behaviorPlanForm: BehaviorPlanRecord;
  selectedStudent: StudentRecord;
};

export const injectVariables = ({
  prompt,
  behaviors,
  replacementBehaviors,
  behaviorPlanForm,
  selectedStudent,
}: InjectVariablesProps) => {
  let newPrompt = prompt;
  const targetBehavior = behaviors.find(
    (behavior) => behavior.id === behaviorPlanForm.targetBehavior
  );
  const replacementBehavior = replacementBehaviors.find(
    (behavior) => behavior.id === behaviorPlanForm.replacementBehavior
  );
  const functionOfBehavior = behaviorPlanForm.functionOfBehavior;
  const replacementBehaviorNotes = behaviorPlanForm.replacementBehaviorsNotes;
  const bio = behaviorPlanForm.bio;
  const firstName = selectedStudent.firstName;
  const grade = selectedStudent.grade;
  const preventionStrategies = behaviorPlanForm.preventionStrategies;
  newPrompt = newPrompt.replace("**firstName**", firstName);
  newPrompt = newPrompt.replace("**grade**", grade);
  newPrompt = newPrompt.replace("**targetBehavior**", targetBehavior?.label ?? "");
  newPrompt = newPrompt.replace("**functionOfBehavior**", functionOfBehavior);
  newPrompt = newPrompt.replace("**replacementBehavior**", replacementBehavior?.label ?? "");
  newPrompt = newPrompt.replace("**replacementBehaviorNotes**", replacementBehaviorNotes as string);
  newPrompt = newPrompt.replace("**bio**", bio);
  newPrompt = newPrompt.replace("**preventionStrategies**", preventionStrategies as string);
  return newPrompt;
};
