import { SurveyQuestionRecord, SurveyResultRecord } from "../types/types";

type BuildSurveyDataProps = {
  selectedSurvey: SurveyResultRecord;
  question: SurveyQuestionRecord;
  selectedOption: string;
};

export const buildSurveyData = ({
  selectedSurvey: { id, responses, ...restSelectedSurvey },
  question: { id: questionId },
  selectedOption,
}: BuildSurveyDataProps) => {
  return {
    ...restSelectedSurvey,
    id,
    responses: { ...responses, [questionId]: selectedOption },
  };
};
