import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import useStaffFormChange from "../../hooks/Settings/useStaffFormChange";
import Loading from "../shared/Loading";
import useProfileForm from "../../hooks/Settings/useProfileForm";

type Props = {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileForm = ({ setEditMode }: Props) => {
  const { handleTextChange } = useStaffFormChange();
  const { loading, handleSave, staffForm } = useProfileForm({ setEditMode });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {staffForm && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.firstName}
                  onChange={handleTextChange}
                  name="firstName"
                  fullWidth
                  label="First Name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.lastName}
                  onChange={handleTextChange}
                  name="lastName"
                  fullWidth
                  label="Last Name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.email}
                  onChange={handleTextChange}
                  name="email"
                  fullWidth
                  label="Email"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.jobTitle}
                  onChange={handleTextChange}
                  name="jobTitle"
                  fullWidth
                  label="Job Title"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.schoolName}
                  onChange={handleTextChange}
                  name="schoolName"
                  fullWidth
                  label="School Name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.districtName}
                  onChange={handleTextChange}
                  name="districtName"
                  fullWidth
                  label="District Name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={staffForm.schoolURL}
                  onChange={handleTextChange}
                  name="schoolURL"
                  fullWidth
                  label="School URL"
                  required
                />
              </Grid>
              <Grid item xs={12} sx={{ justifyContent: "center", textAlign: "center" }}>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ProfileForm;
