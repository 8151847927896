import { User } from "firebase/auth";
import { Dispatch, SetStateAction, createContext, useContext } from "react";

type AuthContextType = {
  authUser: User | null;
  loading: boolean;
  signInWithGoogle: () => void;
  signOut: () => void;
  signUpWithEmail: ({ email, password }: { email: string; password: string }) => void;
  signInWithPassword: ({ email, password }: { email: string; password: string }) => void;
  error: string | null;
  message: string | null;
  resetPassword: (email: string) => void;
  setMessage: Dispatch<SetStateAction<string | null>>;
};

const defaultValue: AuthContextType = {
  authUser: null,
  loading: true,
  signInWithGoogle: () => {},
  signOut: () => {},
  signUpWithEmail: () => {},
  signInWithPassword: () => {},
  error: null,
  message: null,
  resetPassword: () => {},
  setMessage: () => {},
};
export const AuthContext = createContext<AuthContextType>(defaultValue);

export const useAuth = () => {
  return useContext(AuthContext);
};
