import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import ManageStudentDialog from "../ManageStudentDialog/ManageStudentDialog";
import { useRecoilValue } from "recoil";
import { studentsAtom } from "../../recoil/userAtoms";
import StudentCard from "./StudentCard";
import DeleteStudentDialog from "./DeleteStudentDialog";
import { Player } from "@lottiefiles/react-lottie-player";
import { COLORS } from "../../types/enums";
import InfoBar from "../InfoBar/InfoBar";

const StudentsContainer = () => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const students = useRecoilValue(studentsAtom);
  const playerRef = useRef<any>(null);

  return (
    <>
      <Box sx={{ height: "100%", p: 2 }}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography sx={{ fontWeight: 800 }} variant="h3">
            Manage Students
          </Typography>
          <Button variant="contained" onClick={() => setOpen(true)} sx={{ p: 1 }}>
            Add Student
          </Button>
        </Box>
        {students.length === 0 ? (
          <Box sx={{ minHeight: 300 }}>
            <Typography sx={{ color: COLORS.PRIMARY, mt: 8 }} variant="h2" textAlign={"center"}>
              You don't have any students added yet, click "Add Student" to get started!
            </Typography>
            <Player
              ref={playerRef}
              autoplay={true}
              loop={true}
              style={{ width: 400 }}
              src="https://lottie.host/4f0f3f66-cea8-4a3e-8263-586c1f70aec0/W3R483NHVP.json"
            />
          </Box>
        ) : (
          <>
            {students.length === 1 && (
              <InfoBar content="Great work adding a student! You can add more or you can now click Collect Data in the menu to get started building a behavior plan." />
            )}
            <Grid container spacing={2}>
              {students.map((student) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={student.id}>
                  <StudentCard
                    student={student}
                    setOpen={setOpen}
                    setDeleteOpen={setDeleteOpen}
                    setDeleteId={setDeleteId}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
      <ManageStudentDialog open={open} setOpen={setOpen} />
      {deleteId && (
        <DeleteStudentDialog
          open={deleteOpen}
          setOpen={setDeleteOpen}
          id={deleteId}
          setDeleteId={setDeleteId}
        />
      )}
    </>
  );
};

export default StudentsContainer;
