import { useRecoilState, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, staffSignupOpenAtom } from "../../recoil/userAtoms";
import { useState } from "react";
import useSetFSDoc from "../shared/useSetFSDoc";
import { useAuth } from "../../contexts/AuthContext";
import { Collections, Routes } from "../../types/enums";
import { BLANK_STAFF_FORM } from "../../libraries/blankForms";
import useAddFSDoc from "../shared/useAddFSDoc";
import { locationsAtom } from "../../recoil/observationAtoms";
import { LocationRecord } from "../../types/types";
import { useNavigate } from "react-router-dom";

const locations = [
  "Arrival",
  "Language Arts",
  "Math",
  "Science",
  "Social Studies",
  "Lunch",
  "Recess",
  "Snack",
  "Dismissal",
];

const useCompleteProfileDialog = () => {
  const { authUser } = useAuth();
  const [staffSignupOpen, setStaffSignupOpen] = useRecoilState(staffSignupOpenAtom);
  const [staffForm, setStaffForm] = useState({ ...BLANK_STAFF_FORM, email: authUser?.email ?? "" });
  const { setFSDoc } = useSetFSDoc();
  const { addFSDoc } = useAddFSDoc();
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setLocations = useSetRecoilState(locationsAtom);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStaffForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleSave = async () => {
    if (!authUser?.email || !authUser?.uid) return;
    setLoading(true);
    setStaffSignupOpen(false);
    const nowISO = new Date().toISOString();
    await setFSDoc({
      col: Collections.Staff,
      data: { ...staffForm, email: authUser.email, lastUpdatedAt: nowISO, createdAt: nowISO },
      id: authUser.uid,
    });
    setLoggedInStaff({
      ...staffForm,
      email: authUser.email,
      id: authUser.uid,
      createdAt: nowISO,
      lastUpdatedAt: nowISO,
    });

    const locationPromises = locations.map(async (location) => {
      const results = await addFSDoc({
        col: Collections.Locations,
        data: {
          name: location,
          createdAt: nowISO,
          lastUpdatedAt: nowISO,
          staffId: authUser.uid,
        },
      });
      if (!results) return null;
      return {
        name: location,
        staffId: authUser.uid,
        ...results,
      } as LocationRecord;
    });

    const tempLocations = await Promise.all(locationPromises);
    const validLocations = tempLocations.filter(
      (location): location is LocationRecord => location !== null
    );

    setLocations(validLocations);
    setStaffSignupOpen(false);
    setLoading(false);
    setStaffForm({ ...BLANK_STAFF_FORM });
    navigate(Routes.STUDENTS);
  };

  return { staffSignupOpen, setStaffSignupOpen, handleTextChange, handleSave, staffForm, loading };
};

export default useCompleteProfileDialog;
