import { Grid } from "@mui/material";
import React from "react";
import MobileTargetBehavior from "./BehaviorsList";
import MobileAntecedent from "./AntecedentsList";
import MobileConsequence from "./ConsequencesList";
import DesktopLocationPicker from "./DesktopLocationPicker";
import DesktopDatePicker from "./DesktopDatePicker";
import DesktopAnythingElse from "./DesktopAnythingElse";

const DesktopObservationForm = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DesktopDatePicker />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DesktopLocationPicker />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MobileTargetBehavior />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MobileAntecedent />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MobileConsequence />
        </Grid>
        <Grid item xs={12}>
          <DesktopAnythingElse />
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopObservationForm;
