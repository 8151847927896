export enum Routes {
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  OBSERVATION_FORM = "/observation-form",
  SETTINGS = "/settings",
  PROMPTS = "/prompts",
  SUBSCRIBE = "/subscribe",
  COLLECT_DATA = "/collect-data",
  BUILD_PLAN = "/build-plan",
  TRACK_PROGRESS = "/track-progress",
  STUDENTS = "/students",
  SUPPORT = "/support",
}

export enum Collections {
  Staff = "staff",
  Observations = "observations",
  Students = "students",
  Locations = "locations",
  Survey_Questions = "surveyQuestions",
  Surevey_Results = "surveyResults",
  Behavior_Plans = "behaviorPlans",
  Prompts = "prompts",
}

export enum EmailNextSteps {
  SIGN_IN = "sign-in",
  SIGN_UP = "sign-up",
  GOOGLE = "google",
  ERROR = "error",
}

export enum Settings_Tabs {
  Profile = "profile",
  Students = "students",
  Locations = "locations",
}

export enum FUNCTION_SURVEY_OPTIONS {
  DISAGREE = "Disagree",
  AGREE = "Agree",
  STRONGLY_AGREE = "Strongly Agree",
  NA = "N/A",
}

export enum FUNCTIONS_OF_BEHAVIOR_DISPLAY {
  ACCESS = "Access to Item or Activity",
  ESCAPE = "Escape or Avoidance",
  ATTENTION = "Attention",
  SENSORY = "Sensory",
}

export enum FUNCTIONS_OF_BEHAVIOR {
  ACCESS = "access",
  ESCAPE = "escape",
  ATTENTION = "attention",
  SENSORY = "sensory",
}

export enum STAGES {
  STAGE_1 = 1,
  STAGE_2 = 2,
  STAGE_3 = 3,
  STAGE_4 = 4,
  STAGE_5 = 5,
  STAGE_6 = 6,
  STAGE_7 = 7,
}

export enum COLORS {
  PRIMARY_DARK = "#3B3863",
  PRIMARY_MID = "#406DDD",
  PRIMARY_LIGHT = "#BACAF3",
  SECONARY_DARK = "#FFD1C7",
  SECONDARY_LIGHT = "#FFEEEB",
  NEUTRAL_DARK = "#17121D",
  NEUTRAL_LIGHT = "#EEF2FC",
  GREEN = "#8cb369",
  LIGHT_GRAY = "#e5e5e5",
  PRIMARY = "#c100ff",
}

export enum USER_TYPES {
  ADMIN = "admin",
  STAFF = "staff",
  STUDENT = "student",
  DEVELOPER = "developer",
}

export enum STRATEGY_PROMPT_TYPES {
  PREVENTION = "prevention",
  REINFORCEMENT = "reinforcement",
  EXTINGUISH = "extinguish",
  ALL = "all",
}

export enum FREQUENCY_RATES {
  DAILY = "daily",
  WEEKLY = "weekly",
}

export enum TourSelector {
  BUILD_BX_PLAN = "build-bx-plan",
  SEE_SURVEY_RESULTS = "see-survey-results",
}

export enum TOUR_NAMES {
  POST_SURVEY_TOUR = "post-survey-tour",
}
