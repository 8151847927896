import { createContext, useCallback, useContext, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
  loggedInStaffAtom,
  selectedStudentAtom,
  staffSignupOpenAtom,
  studentsAtom,
} from "../recoil/userAtoms";
import {
  BehaviorPlanRecord,
  LocationRecord,
  ObservationRecord,
  PromptRecord,
  StaffRecord,
  StudentRecord,
  SurveyQuestionRecord,
  SurveyResultRecord,
} from "../types/types";
import useGetFSDoc from "../hooks/shared/useGetFSDoc";
import { useAuth } from "../contexts/AuthContext";
import { locationsAtom, observationsAtom } from "../recoil/observationAtoms";
import useGetFSDocs from "../hooks/shared/useGetFSDocs";
import {
  parseBehaviorPlanResponse,
  parseLocationResponse,
  parseObservationsResponse,
  parsePromptsResponse,
  parseStaffResponse,
  parseStudentsResponse,
  parseSurveyQuestionsResponse,
  parseSurveyResultsResponse,
} from "../libraries/parsers";
import { Collections } from "../types/enums";
import { surveyQuestionsAtom, surveyResultsAtom } from "../recoil/surveyAtoms";
import { behaviorPlansAtom } from "../recoil/behaviorPlanAtoms";
import { promptsAtom } from "../recoil/promptAtoms";
import { siteLoadingAtom } from "../recoil/loadingAtoms";

type Props = {
  children: JSX.Element;
};

// Create a Context with the default value
const DataLoaderContext = createContext(null);

export const useDataLoader = () => useContext(DataLoaderContext);

export const DatabaseProvider = ({ children }: Props) => {
  const { authUser } = useAuth();
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setSignUpOpen = useSetRecoilState(staffSignupOpenAtom);
  const setObservations = useSetRecoilState(observationsAtom);
  const setStudents = useSetRecoilState(studentsAtom);
  const setLocations = useSetRecoilState(locationsAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const setSurveyQuestions = useSetRecoilState(surveyQuestionsAtom);
  const setSurveyResults = useSetRecoilState(surveyResultsAtom);
  const setBehaviorPlans = useSetRecoilState(behaviorPlansAtom);
  const setPrompts = useSetRecoilState(promptsAtom);
  const { getFSDoc } = useGetFSDoc();
  const { getFSDocs } = useGetFSDocs();

  const setSelectedStudentAfterStudentsLoad = useCallback(
    (students: StudentRecord[]) => {
      if (students.length === 0) return;
      const student = students[0];
      setSelectedStudent(student);
    },
    [setSelectedStudent]
  );

  useEffect(() => {
    if (!authUser) {
      setSiteLoading(false);
      return;
    }
    setSiteLoading(true);
    const loadData = async () => {
      const dataLoaderPromises: [
        Promise<StaffRecord | null>,
        Promise<ObservationRecord[]>,
        Promise<StudentRecord[]>,
        Promise<LocationRecord[]>,
        Promise<SurveyQuestionRecord[]>,
        Promise<SurveyResultRecord[]>,
        Promise<BehaviorPlanRecord[]>,
        Promise<PromptRecord[]>
      ] = [
        getFSDoc<StaffRecord>({ col: "staff", id: authUser.uid }),
        getFSDocs<ObservationRecord>({
          col: Collections.Observations,
          config: { where: ["staffId", "==", authUser.uid], orderBy: ["date", "desc"] },
        }),
        getFSDocs<StudentRecord>({
          col: Collections.Students,
          config: { where: ["staffId", "==", authUser.uid], orderBy: ["createdAt", "desc"] },
        }),
        getFSDocs<LocationRecord>({
          col: Collections.Locations,
          config: { where: ["staffId", "==", authUser.uid], orderBy: ["name", "desc"] },
        }),
        getFSDocs<SurveyQuestionRecord>({
          col: Collections.Survey_Questions,
          config: { orderBy: ["order", "asc"] },
        }),
        getFSDocs<SurveyResultRecord>({
          col: Collections.Surevey_Results,
          config: { where: ["staffId", "==", authUser.uid], orderBy: ["createdAt", "desc"] },
        }),
        getFSDocs<BehaviorPlanRecord>({
          col: Collections.Behavior_Plans,
          config: { where: ["staffId", "==", authUser.uid], orderBy: ["createdAt", "desc"] },
        }),
        getFSDocs<PromptRecord>({
          col: Collections.Prompts,
        }),
      ];
      const [
        staffFromAuth,
        observationRecords,
        studentRecords,
        locationRecords,
        surveyQuestionRecords,
        surveyResultRecords,
        behaviorPlanRecords,
        promptRecords,
      ] = await Promise.all(dataLoaderPromises);
      //Once user logs in, see if they have a record in db. If not, force dialog to fill out db record info.
      staffFromAuth ? setLoggedInStaff(parseStaffResponse(staffFromAuth)) : setSignUpOpen(true);
      setObservations(parseObservationsResponse(observationRecords));
      setStudents(parseStudentsResponse(studentRecords));
      setLocations(parseLocationResponse(locationRecords));
      setSelectedStudentAfterStudentsLoad(parseStudentsResponse(studentRecords));
      setSurveyQuestions(parseSurveyQuestionsResponse(surveyQuestionRecords));
      setSurveyResults(parseSurveyResultsResponse(surveyResultRecords));
      setBehaviorPlans(parseBehaviorPlanResponse(behaviorPlanRecords));
      setPrompts(parsePromptsResponse(promptRecords));
      setSiteLoading(false);
    };
    loadData();
  }, [
    authUser,
    getFSDoc,
    getFSDocs,
    setBehaviorPlans,
    setLocations,
    setLoggedInStaff,
    setObservations,
    setSelectedStudentAfterStudentsLoad,
    setSignUpOpen,
    setStudents,
    setSurveyQuestions,
    setSurveyResults,
    setPrompts,
    setSiteLoading,
  ]);
  return <DataLoaderContext.Provider value={null}>{children}</DataLoaderContext.Provider>;
};
