import { Box } from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  Area,
} from "recharts";
import { calcDomain, generateData } from "../../utils/goalUtils";
import { useSetRecoilState } from "recoil";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";
import { useEffect, useState } from "react";

type Props = {
  startingFrequency: number;
  consistency: number;
  entrenched: number;
};

type Week = {
  name: string;
  incidents: number;
};

function GoalChart({ startingFrequency, consistency, entrenched }: Props) {
  const setBehaviorPlanForm = useSetRecoilState(behaviorPlanFormAtom);
  const [chartData, setChartData] = useState<Week[]>([]);
  const [domain, setDomain] = useState<number[]>([0, 0]);

  useEffect(() => {
    const data = generateData({ startingFrequency, consistency, entrenched });
    setChartData(data);
    const tempDomain = calcDomain(data[1].incidents);
    setDomain(tempDomain);

    setBehaviorPlanForm((prev) => ({
      ...prev,
      weekOneTarget: data[1].incidents,
      weekTwoTarget: data[2].incidents,
      weekThreeTarget: data[3].incidents,
      weekFourTarget: data[4].incidents,
      weekFiveTarget: data[5].incidents,
      weekSixTarget: data[6].incidents,
      weekSevenTarget: data[7].incidents,
      weekEightTarget: data[8].incidents,
    }));
  }, [consistency, entrenched, setBehaviorPlanForm, startingFrequency]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <ResponsiveContainer height={400} minWidth={600}>
          <AreaChart
            width={730}
            height={250}
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis domain={domain} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="incidents"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default GoalChart;
