import React from "react";
import { ObservationRecord } from "../../types/types";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { convertISOToReadable } from "../../utils/dateTimeUtils";
import { behaviors } from "../../libraries/abcData";
import FeatherIcon from "../shared/FeatherIcon";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  locationsAtom,
  observationEditIdAtom,
  observationFormAtom,
} from "../../recoil/observationAtoms";
import { Routes } from "../../types/enums";
import arrow from "../../assets/icons/chevron-right.svg";
import { antecedents, consequences } from "../../libraries/abcData";
import mapPin from "../../assets/icons/map-pin.svg";

type Props = {
  observation: ObservationRecord;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
};

type FormatProps = {
  behaviors: { id: string; label: string }[];
  behaviorId: string;
};

const formatBehavior = ({ behaviorId, behaviors }: FormatProps) => {
  return behaviors.find((behavior) => behavior.id === behaviorId)?.label ?? "";
};

const ObservationCard = ({ observation, setDeleteId }: Props) => {
  const navigate = useNavigate();
  const setObservationEditId = useSetRecoilState(observationEditIdAtom);
  const setObservationForm = useSetRecoilState(observationFormAtom);
  const locations = useRecoilValue(locationsAtom);
  const antecedent = antecedents.find((antecedent) => antecedent.id === observation.antecedentId);
  const consequence = consequences.find(
    (consequence) => consequence.id === observation.consequenceId
  );
  const location = locations.find((location) => location.id === observation.locationId);

  const handleEdit = () => {
    setObservationForm(observation);
    setObservationEditId(observation.id);
    navigate(Routes.OBSERVATION_FORM);
  };

  const handleDelete = () => {
    setDeleteId(observation.id);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          border: "1px solid #eee",
          borderRadius: 2,
          minHeight: 260,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">Target Behavior</Typography>
          <Box sx={{ display: "flex" }}>
            <Tooltip title="Edit Observation">
              <IconButton disableRipple onClick={handleEdit}>
                <FeatherIcon icon="edit" width="16" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Observation">
              <IconButton disableRipple onClick={handleDelete}>
                <FeatherIcon icon="trashcan" width="16" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Typography variant="h4">
          {formatBehavior({ behaviorId: observation.behaviorId, behaviors })}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography variant="body1">Date:</Typography>
          <Typography variant="h6" sx={{ ml: 1 }}>
            {convertISOToReadable(observation.date)}
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ mt: 1 }} columns={15}>
          <Grid item xs={7}>
            <Typography variant="body2">What Came Before</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={7}>
            <Typography variant="body2">What Happened After</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: "1px", alignItems: "center" }} columns={15}>
          <Grid item xs={7} sx={{ justifyContent: "flex-start" }}>
            <Typography sx={{ fontSize: 13 }}>{antecedent?.label}</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "left", justifyContent: "left", paddingLeft: "0px !important" }}
          >
            <img src={arrow} alt="arrow" style={{ width: 20 }} />
          </Grid>
          <Grid item xs={7}>
            <Typography sx={{ fontSize: 13 }}>{consequence?.label}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, display: "flex" }}>
          <Box
            sx={{
              borderRadius: 12,
              px: 2,
              py: 1,
              alignItems: "center",
              display: "flex",
              background: "linear-gradient(90deg, #FBFFE8 0%, #CFFBF5 50%, #EEF3FF 100%)",
            }}
          >
            <img
              src={mapPin}
              alt="Location"
              style={{
                width: 20,
                marginRight: 4,
                filter: "grayscale(100%) sepia(100%) brightness(20.4) contrast(0)",
              }}
            />
            <Typography>{location?.name}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ObservationCard;
