import React from "react";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { convertISOToReadable } from "../../utils/dateTimeUtils";
import { behaviors, replacementBehaviors } from "../../libraries/abcData";
import FeatherIcon from "../shared/FeatherIcon";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import { useSetRecoilState } from "recoil";
import useHandleError from "../../hooks/shared/useHandleError";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { EVENT_TYPE } from "../../types/logEnums";

type Props = {
  record: BehaviorPlanRecord;
  index: number;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedBehaviorPlan: React.Dispatch<React.SetStateAction<BehaviorPlanRecord | null>>;
  selectedStudent: StudentRecord;
};

type FormatProps = {
  behaviors: { id: string; label: string }[];
  behaviorId: string;
};

const formatBehavior = ({ behaviorId, behaviors }: FormatProps) => {
  return behaviors.find((behavior) => behavior.id === behaviorId)?.label ?? "";
};

const formatReplacementBehavior = ({ behaviorId, behaviors }: FormatProps) => {
  return replacementBehaviors.find((behavior) => behavior.id === behaviorId)?.label ?? "";
};

const BehaviorPlanCard = ({
  record,
  index,
  setDeleteId,
  setSelectedBehaviorPlan,
  selectedStudent,
}: Props) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleError } = useHandleError();
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const active = selectedStudent.activeBehaviorPlanId === record.id;
  const handleShow = () => {
    setSelectedBehaviorPlan(record);
  };

  const handleDelete = () => {
    setDeleteId(record.id);
  };

  const handleToggleActive = async () => {
    try {
      await updateFSDoc({
        col: Collections.Students,
        id: selectedStudent.id,
        data: { activeBehaviorPlanId: record.id },
      });

      setSelectedStudent((prev) =>
        prev === null ? null : { ...prev, activeBehaviorPlanId: record.id }
      );
    } catch (error) {
      handleError({
        error,
        snackbarMessage: "Error making behavior plan active, please refresh and try again.",
        eventType: EVENT_TYPE.MAKE_BEHAVIOR_PLAN_ACTIVE,
        file: "BehaviorPlanCard.tsx",
      });
    }
  };

  return (
    <>
      <Box sx={{ border: "1px solid #eee", borderRadius: 4, p: 2, mt: 2, minHeight: 260 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6">{convertISOToReadable(record.createdAt)}</Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {active ? (
              <Box
                sx={{
                  borderRadius: 12,
                  px: 2,
                  py: 1,
                  alignItems: "center",
                  display: "flex",
                  background: "linear-gradient(90deg, #FBFFE8 0%, #CFFBF5 50%, #EEF3FF 100%)",
                }}
              >
                <Typography variant="h6">Active</Typography>
              </Box>
            ) : (
              <>
                <Box
                  onClick={handleToggleActive}
                  sx={{
                    borderRadius: 12,
                    px: 2,
                    py: 1,
                    border: "1px solid #eee",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#fafafa",
                      boxShadow: "0px 1px 3px rgba(200, 200, 200, 0.3)",
                    },
                  }}
                >
                  <Typography>Make Active</Typography>
                </Box>
              </>
            )}
            <Tooltip title="Delete Behavior Plan">
              <IconButton disableRipple onClick={handleDelete}>
                <FeatherIcon icon="trashcan" width="16" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ mt: 1 }} variant="subtitle1">
            Target Behavior
          </Typography>
          <Typography variant="h4">
            {formatBehavior({ behaviorId: record.targetBehavior, behaviors })}
          </Typography>
          <Typography sx={{ mt: 1 }} variant="subtitle1">
            Replacement Behavior
          </Typography>
          <Typography variant="h5">
            {formatReplacementBehavior({ behaviorId: record.replacementBehavior, behaviors })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button fullWidth variant="outlined" onClick={handleShow}>
            Show Behavior Plan
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BehaviorPlanCard;
