import { StudentRecord, SurveyQuestionRecord, SurveyResultRecord } from "../../types/types";
import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import FeatherIcon from "../shared/FeatherIcon";
import { formatResults, formatResultsText, formatTextWithName } from "../../utils/formatUtils";
type Props = {
  question: SurveyQuestionRecord;
  index: number;
  selectedSurvey: SurveyResultRecord;
  setSelectedQuestion: (pV: SurveyQuestionRecord) => void;
  setCurrentOption: (pV: string) => void;
  setOpen: (pV: boolean) => void;
  selectedStudent: StudentRecord;
};

function Question({
  question,
  index,
  selectedSurvey,
  setSelectedQuestion,
  setCurrentOption,
  setOpen,
  selectedStudent,
}: Props) {
  const color = formatResults(selectedSurvey.responses[question.id]);
  const fontColor = formatResultsText(selectedSurvey.responses[question.id]);

  const handleEditClick = () => {
    setCurrentOption(selectedSurvey.responses[question.id]);
    setSelectedQuestion(question);
    setOpen(true);
  };

  return (
    <>
      <Paper sx={{ mt: 2, padding: 1 }}>
        <Grid container spacing={1} sx={{ minHeight: 50 }}>
          <Grid item xs={12} sm={6} md={7} lg={9}>
            <Typography sx={{ textAlign: "left" }}>{`${index + 1}: ${formatTextWithName({
              questionString: question.label,
              firstName: selectedStudent.firstName,
            })}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <Box
              sx={{
                backgroundColor: color,
                height: "100%",
                display: "flex",
                verticalAlign: "middle",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "3px",
                position: "relative",
                color: fontColor,
                p: "1px",
              }}
            >
              <Typography sx={{ color: fontColor }}>
                {selectedSurvey.responses[question.id]}
              </Typography>
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 1, right: 2 }}
                onClick={handleEditClick}
              >
                <FeatherIcon icon="edit" width="16px" strokeColor="#fff" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Question;
