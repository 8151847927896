import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { promptsAtom } from "../../recoil/promptAtoms";
import { Collections, STRATEGY_PROMPT_TYPES } from "../../types/enums";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import usePromptGenerator from "../../hooks/BehaviorPlans/usePromptGenerator";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { behaviorPlanForm } from "../../libraries/templateBehaviorPlanForPrompt";
import {
  preventionPromptAtom,
  extinguishPromptAtom,
  reinforcementPromptAtom,
  allPromptAtom,
} from "../../recoil/promptAtoms";
import useHandleError from "../../hooks/shared/useHandleError";
import { EVENT_TYPE } from "../../types/logEnums";

const PromptsContainer = () => {
  const [prompts, setPrompts] = useRecoilState(promptsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { generatePrompt } = usePromptGenerator();
  const { updateFSDoc } = useUpdateFSDoc();
  const preventionPrompt = useRecoilValue(preventionPromptAtom);
  const reinforcementPrompt = useRecoilValue(reinforcementPromptAtom);
  const extinguishPrompt = useRecoilValue(extinguishPromptAtom);
  const allPrompt = useRecoilValue(allPromptAtom);
  const { handleError } = useHandleError();

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setPrompts((prevPrompts) =>
      prevPrompts.map((prompt) => (prompt.type === name ? { ...prompt, prompt: value } : prompt))
    );
  };
  const handleSave = async () => {
    const promises: any[] = [];
    prompts.forEach((prompt) => {
      promises.push(updateFSDoc({ col: Collections.Prompts, id: prompt.id, data: prompt }));
    });
    try {
      await Promise.all(promises);
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage: "Failed to save prompts",
        eventType: EVENT_TYPE.UPDATE_PROMPTS_FAILED,
        file: "PromptsContainer.tsx",
      });
    }
  };
  return (
    <>
      {selectedStudent && (
        <Container maxWidth="lg" sx={{ p: 2 }}>
          <Typography variant="h2">Prompts</Typography>
          <Typography sx={{ mt: 2 }}>
            This is the set of prompts that are fed to the AI engine to generate the behavior
            strategies.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            The variable key is: **firstName**, **grade**, **targetBehavior**,
            **functionOfBehavior**, **replacementBehavior**, **replacementBehaviorNotes**, **bio**.
            Make sure to include a space before and after the stars.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Prevention Strategies Prompt</Typography>
            <TextField
              name={STRATEGY_PROMPT_TYPES.PREVENTION}
              multiline
              fullWidth
              onChange={handleTextChange}
              value={preventionPrompt?.prompt ?? ""}
              sx={{ mt: 2 }}
            />
            <Box>
              <Typography variant="h6">Example:</Typography>
              {generatePrompt({
                selectedStudent,
                behaviorPlanForm,
                type: STRATEGY_PROMPT_TYPES.PREVENTION,
              })}
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Reinforce Strategies Prompt</Typography>
            <TextField
              name={STRATEGY_PROMPT_TYPES.REINFORCEMENT}
              multiline
              fullWidth
              onChange={handleTextChange}
              value={reinforcementPrompt?.prompt ?? ""}
              sx={{ mt: 2 }}
            />
            <Box>
              <Typography variant="h6">Example:</Typography>
              {generatePrompt({
                selectedStudent,
                behaviorPlanForm,
                type: STRATEGY_PROMPT_TYPES.REINFORCEMENT,
              })}
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Extinguish Strategies Prompt</Typography>
            <TextField
              name={STRATEGY_PROMPT_TYPES.EXTINGUISH}
              multiline
              fullWidth
              onChange={handleTextChange}
              value={extinguishPrompt?.prompt ?? ""}
              sx={{ mt: 2 }}
            />
            <Box>
              <Typography variant="h6">Example:</Typography>
              {generatePrompt({
                selectedStudent,
                behaviorPlanForm,
                type: STRATEGY_PROMPT_TYPES.EXTINGUISH,
              })}
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Applied to All Strategies Prompt</Typography>
            <TextField
              name={STRATEGY_PROMPT_TYPES.ALL}
              multiline
              fullWidth
              rows={5}
              onChange={handleTextChange}
              value={allPrompt?.prompt ?? ""}
              sx={{ mt: 2 }}
            />
            <Box>
              <Typography variant="h6">Example:</Typography>
              {generatePrompt({
                selectedStudent,
                behaviorPlanForm,
                type: STRATEGY_PROMPT_TYPES.ALL,
              })}
            </Box>
          </Box>
          <Button onClick={handleSave} variant="contained" sx={{ mt: 2 }}>
            Save Prompts
          </Button>
        </Container>
      )}
    </>
  );
};

export default PromptsContainer;
