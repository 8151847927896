function Header() {
  return (
    <>
      <div className="page-header">
        {/* <img alt="The Math Equation" src="../the-math-equation.png" className="header-image" /> */}
      </div>
    </>
  );
}

export default Header;
