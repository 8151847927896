import MobileAntecedent from "./AntecedentsList";
import MobileConsequence from "./ConsequencesList";
import MobileTargetBehavior from "./BehaviorsList";
import MobileTracker from "./MobileTracker";
import MobileSettings from "./MobileSettings";
import MobileSuccess from "./MobileSuccess";
import { useRecoilValue } from "recoil";
import { mobileStageAtom } from "../../recoil/observationAtoms";

const MobileForm = () => {
  const mobileStage = useRecoilValue(mobileStageAtom);
  return (
    <>
      <MobileTracker />
      {mobileStage === 0 && <MobileTargetBehavior />}
      {mobileStage === 1 && <MobileAntecedent />}
      {mobileStage === 2 && <MobileConsequence />}
      {mobileStage === 3 && <MobileSettings />}
      {mobileStage === 4 && <MobileSuccess />}
    </>
  );
};

export default MobileForm;
