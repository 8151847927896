import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { surveyQuestionsAtom, surveyResultsAtom } from "../../recoil/surveyAtoms";
import { observationsAtom } from "../../recoil/observationAtoms";
import { ChartReport, StudentRecord } from "../../types/types";
import { FUNCTIONS_OF_BEHAVIOR, FUNCTION_SURVEY_OPTIONS } from "../../types/enums";
import { formatFunction } from "../../utils/formatUtils";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";

type Props = {
  selectedStudent: StudentRecord;
};

const useFunctionChart = ({ selectedStudent }: Props) => {
  const surveys = useRecoilValue(surveyResultsAtom);
  const observations = useRecoilValue(observationsAtom);
  const surveyQuestions = useRecoilValue(surveyQuestionsAtom);
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const [chartData, setChartData] = useState<ChartReport | null>(null);
  const [highestFunction, setHighestFunction] = useState<FUNCTIONS_OF_BEHAVIOR | null>(null);
  const targetBehavior = behaviorPlanForm.targetBehavior;

  useEffect(() => {
    const functionCounts = {
      [FUNCTIONS_OF_BEHAVIOR.ACCESS]: 0,
      [FUNCTIONS_OF_BEHAVIOR.ESCAPE]: 0,
      [FUNCTIONS_OF_BEHAVIOR.ATTENTION]: 0,
      [FUNCTIONS_OF_BEHAVIOR.SENSORY]: 0,
    };
    const relevantSurveys = surveys.filter(
      (survey) => survey.studentId === selectedStudent.id && survey.behaviorId === targetBehavior
    );
    const relevantObservations = observations.filter(
      (obs) => obs.studentId === selectedStudent.id && obs.behaviorId === targetBehavior
    );

    relevantSurveys.forEach((survey) => {
      surveyQuestions.forEach((question) => {
        const response = survey.responses[question.id];
        if (response && question.functionOfBehavior) {
          functionCounts[question.functionOfBehavior] +=
            response === FUNCTION_SURVEY_OPTIONS.STRONGLY_AGREE
              ? 2
              : response === FUNCTION_SURVEY_OPTIONS.AGREE
              ? 1
              : response === FUNCTION_SURVEY_OPTIONS.DISAGREE
              ? -1
              : 0; // Default to 0 for neutral or missing responses
        }
      });
    });

    relevantObservations.forEach((obs) => {
      if (!obs.functionOfBehavior) return;
      functionCounts[obs.functionOfBehavior] += obs.functionConfidence;
    });

    // Find the highest function, with prioritization if there's a tie
    const tempHighestFunction = Object.keys(functionCounts).reduce((a, b) =>
      functionCounts[a as FUNCTIONS_OF_BEHAVIOR] > functionCounts[b as FUNCTIONS_OF_BEHAVIOR] ||
      (functionCounts[a as FUNCTIONS_OF_BEHAVIOR] === functionCounts[b as FUNCTIONS_OF_BEHAVIOR] &&
        [
          FUNCTIONS_OF_BEHAVIOR.ATTENTION as string,
          FUNCTIONS_OF_BEHAVIOR.ACCESS as string,
          FUNCTIONS_OF_BEHAVIOR.ESCAPE as string,
          FUNCTIONS_OF_BEHAVIOR.SENSORY as string,
        ].indexOf(a) <
          [
            FUNCTIONS_OF_BEHAVIOR.ATTENTION as string,
            FUNCTIONS_OF_BEHAVIOR.ACCESS as string,
            FUNCTIONS_OF_BEHAVIOR.ESCAPE as string,
            FUNCTIONS_OF_BEHAVIOR.SENSORY as string,
          ].indexOf(b))
        ? a
        : b
    ) as FUNCTIONS_OF_BEHAVIOR;

    setBehaviorPlanForm((prev) => ({ ...prev, functionOfBehavior: tempHighestFunction }));

    // Prepare chart data (ensuring values are positive)
    setChartData({
      behaviorLabel: targetBehavior,
      bars: Object.entries(functionCounts).map(([functionName, count]) => ({
        name: formatFunction(functionName as FUNCTIONS_OF_BEHAVIOR),
        amt: Math.max(count, 0), // Ensure positive values for the chart
      })),
    });
  }, [observations, selectedStudent.id, setBehaviorPlanForm, surveyQuestions, surveys, targetBehavior]);
  return { chartData };
};

export default useFunctionChart;
