import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  observationFormAtom,
  observationsAtom,
  observationEditIdAtom,
} from "../../recoil/observationAtoms";
import useAddFSDoc from "../shared/useAddFSDoc";
import { Collections, FUNCTIONS_OF_BEHAVIOR } from "../../types/enums";
import { loggedInStaffAtom, selectedStudentAtom } from "../../recoil/userAtoms";
import { BaseRecord, Observation } from "../../types/types";
import useUpdateFSDoc from "../shared/useUpdateFSDoc";
import { useErrorContext } from "../../contexts/ErrorContext";
import { antecedents, consequences } from "../../libraries/abcData";

const useSaveObservation = () => {
  const [observationEditId, setObservationEditId] = useRecoilState(observationEditIdAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setObservations = useSetRecoilState(observationsAtom);
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const observationForm = useRecoilValue(observationFormAtom);
  const { setErrorMessage } = useErrorContext();

  //observation form has function and function confidence blank until submitted, so added after the form in the expanded final data submitted to firestore
  //if antecedent and consequence have the same function, function confidence is 2, otherwise 1 and we use the antecedent
  const determineFunction = (observationForm: Observation) => {
    const antecedent = antecedents.find(
      (antecedent) => antecedent.id === observationForm.antecedentId
    );
    const consequence = consequences.find(
      (consequence) => consequence.id === observationForm.consequenceId
    );
    const antecedentFunctionOfBehavior = antecedent?.functionOfBehavior || "";
    const consequenceFunctionOfBehavior = consequence?.functionOfBehavior || "";
    return {
      functionOfBehavior:
        (antecedentFunctionOfBehavior as FUNCTIONS_OF_BEHAVIOR) ||
        (consequenceFunctionOfBehavior as FUNCTIONS_OF_BEHAVIOR),
      functionConfidence: antecedentFunctionOfBehavior === consequenceFunctionOfBehavior ? 2 : 1,
    };
  };

  const editObservation = async (id: string) => {
    const functionData = determineFunction(observationForm);
    const lastUpdatedAt: string | null = await updateFSDoc({
      col: Collections.Observations,
      data: { ...observationForm, ...functionData },
      id: id,
    });
    if (!lastUpdatedAt) return;
    setObservations((pV) => {
      const toEdit = [...pV];
      const index = toEdit.findIndex((record) => record.id === observationEditId);
      toEdit[index] = { ...toEdit[index], ...observationForm, lastUpdatedAt };
      return [...toEdit];
    });
  };

  type CreateProps = {
    staffId: string;
    studentId: string;
  };

  const createObservation = async ({ staffId, studentId }: CreateProps) => {
    const functionData = determineFunction(observationForm);
    const record: Observation = { ...observationForm, staffId, studentId, ...functionData };
    const docData: BaseRecord | null = await addFSDoc({
      col: Collections.Observations,
      data: record,
    });
    if (!docData) return;
    setObservations((prev) => [{ ...docData, ...record }, ...prev]);
  };

  const saveObservation = async () => {
    if (!loggedInStaff) {
      setErrorMessage("No Logged In Staff Member");
      return;
    }
    if (!selectedStudent) {
      setErrorMessage("No Student Selected");
      return;
    }
    // Edit existing record
    if (observationEditId) {
      editObservation(observationEditId);
    } else {
      createObservation({ staffId: loggedInStaff.id, studentId: selectedStudent.id });
    }
    setObservationEditId(null);
  };
  return { saveObservation };
};

export default useSaveObservation;
