import { Route, Routes as RouterRoutes } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import LoginPage from "./pages/LoginPage";
import ObservationPage from "./pages/ObservationPage";
import { Routes } from "./types/enums";
import SettingsPage from "./pages/SettingsPage";
import SurveyLinkPage from "./pages/SurveyLinkPage";
import PromptsPage from "./pages/PromptsPage";
import CollectDataPage from "./pages/CollectDataPage";
import BuildPlanPage from "./pages/BuildPlanPage";
import TrackProgressPage from "./pages/TrackProgressPage";
import StudentsPage from "./pages/StudentsPage";
import SupportPage from "./pages/SupportPage";

export const routes = (
  <RouterRoutes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/behavior-survey" element={<SurveyLinkPage />} />
    <Route
      path={Routes.COLLECT_DATA}
      element={
        <CustomRoute>
          <CollectDataPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.BUILD_PLAN}
      element={
        <CustomRoute>
          <BuildPlanPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.TRACK_PROGRESS}
      element={
        <CustomRoute>
          <TrackProgressPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.OBSERVATION_FORM}
      element={
        <CustomRoute>
          <ObservationPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.SETTINGS}
      element={
        <CustomRoute>
          <SettingsPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.SUPPORT}
      element={
        <CustomRoute>
          <SupportPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.STUDENTS}
      element={
        <CustomRoute>
          <StudentsPage />
        </CustomRoute>
      }
    />
    <Route
      path={Routes.PROMPTS}
      element={
        <CustomRoute>
          <PromptsPage />
        </CustomRoute>
      }
    />
    <Route
      path="/*"
      element={
        <CustomRoute>
          <CollectDataPage />
        </CustomRoute>
      }
    />
  </RouterRoutes>
);
