import { FREQUENCY_RATES } from "../types/enums";
import { BehaviorPlanRecord } from "../types/types";

export const behaviorPlanForm: BehaviorPlanRecord = {
  targetBehavior: "i5bl83dv2j97fp9i7lkwdnd0",
  replacementBehavior: "hi2y6ienxry7zyh8kbj8072j",
  replacementBehaviorsNotes:
    "When the student is starting to feel overwhelmed, they will raise their hand, and when the teacher comes to them, they n will ask for a reduced amount of work. This could be skipping a question, it could be limiting the number of questions, or it could be not having to fully complete something, just doing part of a task.",
  functionOfBehavior: "escape",
  bio: "The student is a vibrant child with a passion for sports. They are a natural athlete with boundless energy, always eager to join a game on the playground. They are friendly and outgoing, but struggle to make friends and often feels like they are not smart enough in class. When they get overwhelmed with what they are asked to do, they shuts down and refuses to do any work, often shoving work off their desk.",
  targetBehaviorNotes: "",
  behaviorDefinition: "",
  antecedents: [],
  antecedentNotes: "",
  preventionStrategies:
    "<p><strong>Strategy 1:</strong></p><ul><li><strong>Check for Understanding:</strong> Before starting a new task, engage the student in a brief conversation to ensure they comprehend the instructions and expectations. Ask them to summarize or restate what they need to do. If there are any areas of confusion, provide additional clarification.<br/> </li></ul><p><strong>Strategy 2:</strong></p><ul><li><strong>Break Down Tasks:</strong> When assigning tasks that may overwhelm the student, consider breaking them down into smaller, manageable chunks. This can reduce the perceived difficulty and make the task less daunting for them.<br/> </li></ul>",
  reinforcementStrategies:
    "<p><strong>Strategy 1: Immediate Verbal Praise and Recognition</strong></p><ul><li>When the student raises their hand and asks for a reduced assignment, acknowledge their request immediately with a verbal compliment and a specific description of the desired behavior.<br/> </li></ul><ul><li>Example: &quot; I appreciate you coming to me and asking for help. It shows that you&#x27;re trying your best.&quot;<br/> </li></ul><p><strong>Strategy 2: Provide a Tangible Reward</strong></p><ul><li>Select a small, non-food reward that the student values, such as a sticker, token, or small toy.<br/></li></ul><ul><li>Reward the student immediately after they ask for a reduced assignment, while explaining the connection between the reward and the positive behavior.<br/> </li></ul><ul><li>Example: &quot; here is a sticker for asking nicely for a reduced assignment. Keep up the great work!&quot;<br/> </li></ul>",
  extinguishStrategies:
    "<p><strong>Strategy 1: Non-Confrontational Redirection</strong></p><ul><li>When the student refuses a task, calmly and quietly redirect him to the replacement behavior.<br/> </li></ul><ul><li>Say something like, &quot; I understand you may feel overwhelmed. Let&#x27;s work together to find a way to make this work that&#x27;s easier for you.&quot;<br/> </li></ul><ul><li>Guide them to raise their hand and ask for a reduced assignment.<br/> </li></ul><p><strong>Strategy 2: Task Breakdowns</strong></p><ul><li>If the student refuses a task that is particularly overwhelming, break it down into smaller, manageable chunks.<br/> </li></ul><ul><li>Assign them a portion of the task that they can complete without experiencing escape motivation.<br/> </li></ul><ul><li>As they successfully completes each chunk, gradually increase the workload until they can complete the full assignment.<br/> </li></ul>",
  studentId: "",
  staffId: "",
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  startingFrequency: 0,
  frequencyRate: FREQUENCY_RATES.DAILY,
  weekOneTarget: 0,
  weekOneActual: 0,
  weekTwoTarget: 0,
  weekTwoActual: 0,
  weekThreeTarget: 0,
  weekThreeActual: 0,
  weekFourTarget: 0,
  weekFourActual: 0,
  weekFiveTarget: 0,
  weekFiveActual: 0,
  weekSixTarget: 0,
  weekSixActual: 0,
  weekSevenTarget: 0,
  weekSevenActual: 0,
  weekEightTarget: 0,
  weekEightActual: 0,
};
