import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { DateTime } from "luxon";
import { studentFormAtom } from "../../recoil/userAtoms";

const useStudentFormChange = () => {
  const setStudentForm = useSetRecoilState(studentFormAtom);
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setStudentForm((pV) => ({ ...pV, [name]: value }));
    },
    [setStudentForm]
  );

  type DateProps = {
    name: string;
    value: DateTime | null;
  };

  const handleDateChange = useCallback(
    ({ name, value }: DateProps) => {
      setStudentForm((pV) => ({ ...pV, [name]: value?.toISO() ?? "" }));
    },
    [setStudentForm]
  );
  return { handleTextChange, handleDateChange };
};

export default useStudentFormChange;
