import { EVENT_TYPE, LOG_SEVERITY } from "../../types/logEnums";
import { fetchData } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { Log } from "../../types/types";

type SubmitLogProps = {
  severity: LOG_SEVERITY;
  eventType: EVENT_TYPE;
  changeLog: string;
  error?: Error | null;
  file: string;
};

// use the submitLog function to log events in the bxLogger, Event Type and Log Severity are in enums. Change log is a string with import ids or other relevant information. Error is optional and is an error object. File is the file the log is coming from.

const useLogger = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const submitLog = async ({
    severity,
    eventType,
    changeLog,
    error = null,
    file,
  }: SubmitLogProps) => {
    fetchData({
      url: "logger/bxLogger",
      payload: {
        severity,
        eventType,
        changeLog,
        error,
        file,
        timestamp: new Date().toISOString(),
        userId: loggedInStaff?.id || "No Logged In User",
      } as Log,
    });
  };
  return { submitLog };
};

export default useLogger;
