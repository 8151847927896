import React from "react";
import { useRecoilValue } from "recoil";
import { surveyQuestionsAtom } from "../../recoil/surveyAtoms";
import SurveyQuestion from "./SurveyQuestion";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { behaviors } from "../../libraries/abcData";
import { getBehaviorLabel } from "../../utils/formatUtils";
import useSurveyForm from "../../hooks/Surveys/useSurveyForm";
import { selectedStudentAtom } from "../../recoil/userAtoms";

type Props = {
  notAnswered: string[];
  setNotAnswered: (pV: string[]) => void;
  behaviorId?: string;
  name?: string;
};

function SurveyForm({ notAnswered, setNotAnswered, behaviorId, name }: Props) {
  const questions = useRecoilValue(surveyQuestionsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { handleSelectChange, handleTextChange, surveyForm } = useSurveyForm({
    behaviorId,
    name,
  });

  return (
    <>
      {selectedStudent && (
        <>
          <Box sx={{ mt: 1, padding: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={surveyForm.submitter}
                  name="submitter"
                  required
                  fullWidth
                  label="Name of Person Submitting Survey"
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={surveyForm.relationship}
                  fullWidth
                  required
                  name="relationship"
                  label="Relationship of Persion Submitting Survey to Child"
                  onChange={handleTextChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 1, padding: 1 }}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={6}>
                {!behaviorId ? (
                  <FormControl fullWidth>
                    <InputLabel id="target-behavior-label">Select Target Behavior</InputLabel>
                    <Select
                      value={surveyForm.behaviorId}
                      labelId="target-behavior-label"
                      required
                      label="Select Target Behavior"
                      onChange={handleSelectChange}
                    >
                      {behaviors &&
                        behaviors.map((behavior) => (
                          <MenuItem key={behavior.id} value={behavior.id}>
                            {behavior.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="h6">
                    {`The Target Behavior Is: ${getBehaviorLabel(behaviorId)}`}{" "}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={surveyForm.descriptionOfBehavior}
                  fullWidth
                  required
                  name="descriptionOfBehavior"
                  label={`What Does Behavior Look Like For ${selectedStudent.firstName}`}
                  onChange={handleTextChange}
                />
              </Grid>
            </Grid>
          </Box>
          {surveyForm.behaviorId && (
            <Box>
              {questions &&
                questions.map((question, index) => (
                  <SurveyQuestion
                    key={question.id}
                    question={question}
                    index={index}
                    notAnswered={notAnswered}
                    setNotAnswered={setNotAnswered}
                    selectedStudent={selectedStudent}
                  />
                ))}
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default SurveyForm;
