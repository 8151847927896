import { SelectChangeEvent } from "@mui/material";
import React, { useCallback, useState } from "react";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useRecoilValue } from "recoil";
import useAddFSDoc from "../../hooks/shared/useAddFSDoc";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  open: boolean;
  setOpen: (pV: boolean) => void;
};

function makeid() {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 16) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function useSendLinkDialog({ open, setOpen }: Props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { addFSDoc } = useAddFSDoc();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const passcode = makeid();
  const { authUser } = useAuth();
  const [behaviorId, setBehaviorId] = useState<string | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(async () => {
    if (!selectedStudent || !authUser) return;
    await addFSDoc({
      col: "mail",
      data: {
        to: email,
        message: {
          subject: `Survey Link for ${selectedStudent.firstName}`,
          html: `You have been invited to fill out a behavior survey for ${selectedStudent.firstName} ${selectedStudent.lastName}. To fill the survey out, please click the following link <a href="http://localhost:3000/behavior-survey?studentId=${selectedStudent.id}&passcode=${passcode}&staffId=${authUser.uid}&behaviorId=${behaviorId}&name=${name}">here</a>`,
        },
      },
    });
    await addFSDoc({
      col: "surveyLinks",
      data: {
        email: email,
        studentId: selectedStudent.id,
        compleated: false,
        passcode: passcode,
        staffId: authUser.uid,
        behaviorId: behaviorId,
        name: name,
      },
    });
    setOpen(false);
  }, [addFSDoc, authUser, behaviorId, email, name, passcode, selectedStudent, setOpen]);

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleSelectChange = useCallback((event: SelectChangeEvent) => {
    setBehaviorId(event.target.value);
  }, []);
  return {
    handleSelectChange,
    handleEmailChange,
    handleNameChange,
    handleSubmit,
    handleClose,
    behaviorId,
    email,
    name,
  };
}

export default useSendLinkDialog;
