import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import clipboard from "../../assets/icons/clipboard.svg";
import stack from "../../assets/icons/stack.svg";
import chart from "../../assets/icons/chart.svg";
import settings from "../../assets/icons/settings.svg";
import { Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../types/enums";
import { useAuth } from "../../contexts/AuthContext";

export default function MobileBottomBar() {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname.split("/")[1]);
  const { signOut } = useAuth();
  const ref = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStudents = () => {
    navigate(Routes.STUDENTS);
    handleClose();
  };
  const handleLocations = () => {
    navigate(Routes.SETTINGS);
    handleClose();
  };
  const handleHelp = () => {
    navigate(Routes.SUPPORT);
    handleClose();
  };
  const handleLogout = () => {
    signOut();
    handleClose();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    switch (newValue) {
      case "collect-data":
        navigate(Routes.COLLECT_DATA);
        setValue("collect-data");

        break;
      case "build-plan":
        navigate(Routes.BUILD_PLAN);
        setValue("build-plan");
        break;
      case "track-progress":
        navigate(Routes.TRACK_PROGRESS);
        setValue("track-progress");
        break;
      case "settings":
        handleClick(event as React.MouseEvent<HTMLButtonElement>);
        setValue("settings");
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Collect Data"
            icon={<img src={clipboard} alt="Collect Data" style={{ width: 20 }} />}
            value="collect-data"
            sx={value === "collect-data" ? activeItemStyle : {}}
          />
          <BottomNavigationAction
            label="Build Plan"
            icon={<img src={stack} alt="Build Behavior Plan" style={{ width: 20 }} />}
            value="build-plan"
            sx={value === "build-plan" ? activeItemStyle : {}}
          />
          <BottomNavigationAction
            label="Track Results"
            icon={<img src={chart} alt="Track Results" style={{ width: 20 }} />}
            value="track-progress"
            sx={value === "track-progress" ? activeItemStyle : {}}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<img src={settings} alt="Settings" style={{ width: 20 }} />}
            value="settings"
            sx={value === "settings" ? activeItemStyle : {}}
          />
        </BottomNavigation>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleStudents}>Students</MenuItem>
          <MenuItem onClick={handleLocations}>Locations</MenuItem>
          <MenuItem onClick={handleHelp}>Help</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
}

const activeItemStyle = {
  "& .MuiBottomNavigationAction-label": {
    fontSize: "0.775rem", // Adjust this value as needed
  },
};
