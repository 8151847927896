import { TextField, Typography } from "@mui/material";
import React from "react";
import { StudentRecord } from "../../types/types";
import { useRecoilState } from "recoil";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";

type Props = {
  selectedStudent: StudentRecord;
  setNextDisabled: (disabled: boolean) => void;
};

// const DEMO_TEXT =
//   "Jeremy is a vibrant 4rd grader with a passion for sports. He's a natural athlete with boundless energy, always eager to join a game on the playground. He is a friendly and outgoing boy, but struggles to make friends and often feels like they are not smart enough in class. When he gets overwhelmed with what he is asked to do, he shuts down and refuses to do any work, often shoving work off his desk.";

const Bio = ({ selectedStudent, setNextDisabled }: Props) => {
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const handleBioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBehaviorPlanForm((prev) => ({ ...prev, bio: e.target.value }));
  };
  return (
    <>
      <Typography variant="h4">Bio</Typography>
      <Typography variant="body1">
        {`The most important part of building a successful behavior plan is understanding ${selectedStudent.firstName}
        as a whole. Students in need of a behavior plan can often feel misunderstood or overlooked.
        By taking the time to understand ${selectedStudent.firstName}'s background, interests, relationships, and strengths, we
        can build a plan that is tailored to their unique needs. Please share positively framed details that will
        be helpful for people implementing this behavior plan and our AI asssistant.`}
      </Typography>
      <TextField
        value={behaviorPlanForm.bio}
        sx={{ mt: 2 }}
        multiline
        fullWidth
        label="Bio"
        onChange={handleBioChange}
        rows={4}
      />
      {/* <Button onClick={() => setBehaviorPlanForm((prev) => ({ ...prev, bio: DEMO_TEXT }))}>
        Use Demo Data
      </Button> */}
    </>
  );
};

export default Bio;
