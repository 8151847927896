import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
    button?: React.CSSProperties;
    sideMenu: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
    button?: React.CSSProperties;
    sideMenu?: React.CSSProperties;
  }
  interface Palette {
    cancel: Palette["primary"];
    // Add other custom colors here
  }

  interface PaletteOptions {
    cancel?: PaletteOptions["primary"];
    // Add other custom colors here
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    button: true;
    sideMenu: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    defaultOutlined: true; // Add the new variant
  }
  interface ButtonPropsColorOverrides {
    cancel: true;
  }
}
const fontFamily = '"Inter", sans-serif';

export const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          fontSize: 11,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          fontSize: 14,
          fontWeight: 700,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // Default styles for DialogTitle
          padding: "16px 24px", // Adjust padding as needed
          fontFamily: fontFamily,
          fontSize: 28,
          fontWeight: 500, // Set base font weight
        },
      },
    },
    MuiInputBase: {
      // Target the base component for TextFields
      styleOverrides: {
        input: {
          fontFamily: fontFamily,
          color: "#342143",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "defaultOutlined" },
          style: {
            borderRadius: 12,
            fontFamily: fontFamily,
            color: "#342143",
            textTransform: "none",

            boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.21)",

            // Customize other styles as needed (e.g., hover, focus)
            "&:hover": {
              backgroundColor: "#F0F0F0",
              borderColor: "#808080",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 18,
            fontFamily: fontFamily,
            fontSize: 14,
            color: "#fff",

            boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.21)",

            // Customize other styles as needed (e.g., hover, focus)
          },
        },
        {
          props: { variant: "text" },
          style: {
            borderRadius: 18,
            fontFamily: fontFamily,
            fontSize: 14,

            // Customize other styles as needed (e.g., hover, focus)
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 18,
            fontFamily: fontFamily,
            fontSize: 14,

            // Customize other styles as needed (e.g., hover, focus)
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: "#c100ff",
      contrastText: "#EEF2FC",
    },
    secondary: {
      main: "#EFFFB0",
      contrastText: "#333",
    },
    cancel: {
      main: "#666",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: 60,
      fontFamily: fontFamily,
      fontWeight: 300,
    },
    h2: {
      fontSize: 40,
      fontFamily: fontFamily,
      fontWeight: 300,
    },
    h3: {
      fontSize: 30,
      fontFamily: fontFamily,
      fontWeight: 700,
    },
    h4: {
      fontSize: 20,
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
    },
    h5: {
      fontSize: 18,
      fontFamily: fontFamily,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontFamily: fontFamily,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 12,
      fontFamily: fontFamily,
      fontWeight: 500,
      color: "#ABB9C9 ",
    },
    subtitle2: {
      fontSize: 12,
      fontFamily: fontFamily,
      fontWeight: 200,
    },
    body1: {
      fontSize: 14,
      fontFamily: fontFamily,
      fontWeight: 400,
      color: "#555555",
      letterSpacing: "0px",
    },
    body2: {
      fontSize: 12,
      fontFamily: fontFamily,
      fontWeight: 500,
    },
    sideMenu: {
      fontSize: 14,
      fontFamily: fontFamily,
      fontWeight: 400,
      color: "#342143",
      letterSpacing: "0px",
    },
    button: {
      fontSize: 16,
      fontFamily: fontFamily,
      fontWeight: 600,
    },
    caption: {
      fontSize: 10,
      fontFamily: fontFamily,
      fontWeight: 200,
    },
  },
});
