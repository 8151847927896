import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import FeatherIcon from "../shared/FeatherIcon";
import { COLORS } from "../../types/enums";
import { mobileStageAtom, observationFormAtom } from "../../recoil/observationAtoms";
import { useRecoilState, useRecoilValue } from "recoil";

const gridSX = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Center items horizontally
  justifyContent: "center", // Center items vertically
  textAlign: "center",
  pt: 1,
};
const boxSx = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Center items horizontally
  justifyContent: "center", // Center items vertically
  textAlign: "center",
};
const blankAvatarSX = {
  border: "2px dashed #aaa",
  backgroundColor: "#f9f9f9",
  color: "#777",
  mb: 1,
};

const filledAvatarSX = {
  backgroundColor: COLORS.GREEN,
  color: "#fff",
  mb: 1,
  border: `2px solid ${COLORS.GREEN}`,
};

const MobileTracker = () => {
  const [mobileStage, setMobileStage] = useRecoilState(mobileStageAtom);
  const observationForm = useRecoilValue(observationFormAtom);
  return (
    <Grid container spacing={0}>
      <Grid item xs={3} sx={gridSX}>
        <IconButton onClick={() => setMobileStage(0)} sx={{ p: 0, m: 0 }} disableRipple>
          <Box sx={boxSx}>
            <Avatar sx={observationForm.behaviorId ? filledAvatarSX : blankAvatarSX}>
              {observationForm.behaviorId ? <FeatherIcon icon="check" width="24" /> : "B"}
            </Avatar>
            <Typography sx={{ fontWeight: mobileStage === 0 ? 800 : 200 }} variant="caption">
              Behavior
            </Typography>
          </Box>
        </IconButton>
      </Grid>
      <Grid item xs={3} sx={gridSX}>
        <IconButton onClick={() => setMobileStage(1)} sx={{ p: 0, m: 0 }} disableRipple>
          <Box sx={boxSx}>
            <Avatar sx={observationForm.antecedentId ? filledAvatarSX : blankAvatarSX}>
              {observationForm.antecedentId ? <FeatherIcon icon="check" width="24" /> : "C"}
            </Avatar>
            <Typography sx={{ fontWeight: mobileStage === 1 ? 800 : 200 }} variant="caption">
              Came Before
            </Typography>
          </Box>
        </IconButton>
      </Grid>
      <Grid item xs={3} sx={gridSX}>
        <IconButton onClick={() => setMobileStage(2)} sx={{ p: 0, m: 0 }} disableRipple>
          <Box sx={boxSx}>
            <Avatar sx={observationForm.consequenceId ? filledAvatarSX : blankAvatarSX}>
              {observationForm.consequenceId ? <FeatherIcon icon="check" width="24" /> : "R"}
            </Avatar>
            <Typography sx={{ fontWeight: mobileStage === 2 ? 800 : 200 }} variant="caption">
              Response
            </Typography>
          </Box>
        </IconButton>
      </Grid>
      <Grid item xs={3} sx={gridSX}>
        <IconButton onClick={() => setMobileStage(3)} sx={{ p: 0, m: 0 }} disableRipple>
          <Box sx={boxSx}>
            <Avatar sx={observationForm.locationId ? filledAvatarSX : blankAvatarSX}>
              {observationForm.locationId ? <FeatherIcon icon="check" width="24" /> : "S"}
            </Avatar>
            <Typography sx={{ fontWeight: mobileStage === 3 ? 800 : 200 }} variant="caption">
              Setting
            </Typography>
          </Box>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MobileTracker;
