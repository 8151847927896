import React from "react";
import CompleteProfileDialog from "../components/CompleteProfile/CompleteProfileDialog";
import SideNavigation from "../components/Navigation/SideNavigation";
import { Box } from "@mui/material";
import TopNavbarTracker from "../components/Navigation/TopNavbarTracker";
import BuildBehaviorPlansContainer from "../components/BuildBehaviorPlans/BuildBehaviorPlansContainer";
import MobileBottomBar from "../components/Navigation/MobileBottomBar";
import useWindowDimensions from "../hooks/shared/useWindowDimensions";
import SelectStudent from "../components/Navigation/SelectStudent";
import { useRecoilValue } from "recoil";
import { studentsAtom } from "../recoil/userAtoms";

const BuildPlanPage = () => {
  const { isMobile } = useWindowDimensions();
  const students = useRecoilValue(studentsAtom);

  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideNavigation />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <TopNavbarTracker />
            <BuildBehaviorPlansContainer />
          </Box>
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box sx={{p: 1}}>
            <SelectStudent students={students} />
          </Box>
          <BuildBehaviorPlansContainer />
          <MobileBottomBar />
        </Box>
      )}
      <CompleteProfileDialog />
    </>
  );
};

export default BuildPlanPage;
