import { atom, selector } from "recoil";
import { BehaviorPlanRecord } from "../types/types";
import { selectedStudentAtom } from "./userAtoms";
import { BLANK_BEHAVIOR_PLAN_FORM } from "../libraries/blankForms";

export const behaviorPlansAtom = atom<BehaviorPlanRecord[]>({
  key: "behaviorPlans",
  default: [],
});

export const selectedStudentBehaviorPlansAtom = selector({
  key: "selectedStudentBehaviorPlans",
  get: ({ get }) => {
    const selectedStudent = get(selectedStudentAtom);
    const behaviorPlans = get(behaviorPlansAtom);
    if (!selectedStudent) return [];
    return behaviorPlans.filter((plan) => plan.studentId === selectedStudent.id);
  },
});

export const behaviorPlanFormAtom = atom<BehaviorPlanRecord>({
  key: "behaviorPlanForm",
  default: BLANK_BEHAVIOR_PLAN_FORM,
});
