import React, { useEffect, useState } from "react";
import { StudentRecord } from "../../types/types";
import { getImageURL } from "../../utils/imageUtils";
import { useSetRecoilState } from "recoil";
import { editStudentIdAtom, studentFormAtom } from "../../recoil/userAtoms";

type Props = {
  student: StudentRecord;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStudentCard = ({ student, setOpen, setDeleteId, setDeleteOpen }: Props) => {
  const [avatarURL, setAvatarURL] = useState<string | null>(null);
  const setStudentForm = useSetRecoilState(studentFormAtom);
  const setEditStudentId = useSetRecoilState(editStudentIdAtom);

  useEffect(() => {
    const fetchImage = async () => {
      if (student.avatar.length > 5) {
        const url = await getImageURL(student.avatar);
        setAvatarURL(url);
      }
    };
    fetchImage();
  }, [student.avatar]);

  const handleEdit = () => {
    setOpen(true);
    setEditStudentId(student.id);
    setStudentForm(student);
  };

  const handleDelete = () => {
    setDeleteOpen(true);
    setDeleteId(student.id);
  };
  return { avatarURL, handleEdit, handleDelete };
};

export default useStudentCard;
