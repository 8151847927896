import { Box, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";

const ProfileCard = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  return (
    <>
      {loggedInStaff && (
        <Box sx={{ px: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>First Name:</b> {loggedInStaff.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>Last Name:</b> {loggedInStaff.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>Email:</b> {loggedInStaff.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>School:</b> {loggedInStaff.schoolName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>District:</b> {loggedInStaff.districtName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <b>School Website:</b>{" "}
                <a href={loggedInStaff.schoolURL} target="_blank" rel="noopener noreferrer">
                  {loggedInStaff.schoolURL}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProfileCard;
