import { Box } from "@mui/material";
import React from "react";
import NoCircleX from "../../assets/icons/no-circle-x.svg";

type Props = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: Props) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "50%",
          border: "1px solid #ddd",
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={NoCircleX}
          width={30}
          alt="Close"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </>
  );
};

export default CloseButton;
