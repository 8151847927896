import { Box, Typography } from "@mui/material";
import MobileForm from "./MobileForm";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { BLANK_OBSERVATION_FORM } from "../../libraries/blankForms";
import { useRecoilState } from "recoil";
import { observationFormAtom, mobileStageAtom } from "../../recoil/observationAtoms";
import useSaveObservation from "../../hooks/Observations/useSaveObservation";
import { isObservationFormValid, isNextEnabled } from "../../utils/formUtils";
import { useCallback } from "react";
import { Routes } from "../../types/enums";

const MobileObservationContainer = () => {
  const [mobileStage, setMobileStage] = useRecoilState(mobileStageAtom);
  const [observationForm, setObservationForm] = useRecoilState(observationFormAtom);
  const { saveObservation } = useSaveObservation();

  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    setObservationForm(BLANK_OBSERVATION_FORM);
    setMobileStage(0);
    navigate(Routes.DASHBOARD);
  }, [navigate, setMobileStage, setObservationForm]);

  const handleSave = useCallback(async () => {
    await saveObservation();
    setMobileStage((prev) => prev + 1);
    setObservationForm(BLANK_OBSERVATION_FORM);
  }, [saveObservation, setMobileStage, setObservationForm]);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Typography textAlign="center" variant="h5" sx={{ mb: 1 }}>
          Submit Behavior Record
        </Typography>
        <MobileForm />
      </Box>
      <Box id="bottom-bar" sx={{ position: "fixed", bottom: 0, left: 0, width: "100%" }}>
        {mobileStage < 4 && (
          <Box sx={{ display: "flex", alignItems: "end", justifyContent: "end", p: 2 }}>
            {mobileStage > 0 && (
              <Button onClick={() => setMobileStage((prev) => prev - 1)}>Back</Button>
            )}
            {mobileStage < 3 && (
              <Button
                disabled={isNextEnabled(mobileStage, observationForm)}
                onClick={() => setMobileStage((prev) => prev + 1)}
              >
                Next
              </Button>
            )}
            {mobileStage === 3 && (
              <Button disabled={!isObservationFormValid(observationForm)} onClick={handleSave}>
                Save
              </Button>
            )}

            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MobileObservationContainer;
