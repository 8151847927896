import { Box } from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  Area,
} from "recharts";

import { useEffect, useState } from "react";
import { BehaviorPlanRecord } from "../../types/types";

type Props = {
  behaviorPlan: BehaviorPlanRecord;
};

type Week = {
  name: string;
  incidents: number;
};

function PreviewChart({ behaviorPlan }: Props) {
  const [chartData, setChartData] = useState<Week[]>([]);
  const [domain, setDomain] = useState<number[]>([0, 30]);

  function findLargestNumber(arr: number[]): number {
    if (arr.length === 0) {
      return 20; // Handle empty arrays
    }

    let largest = arr[0]; // Assume the first element is largest

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > largest) {
        largest = arr[i]; // Update if a larger number is found
      }
    }

    return largest;
  }

  useEffect(() => {
    setChartData([
      { name: "Week 1", incidents: behaviorPlan.weekOneTarget },
      { name: "Week 2", incidents: behaviorPlan.weekTwoTarget },
      { name: "Week 3", incidents: behaviorPlan.weekThreeTarget },
      { name: "Week 4", incidents: behaviorPlan.weekFourTarget },
      { name: "Week 5", incidents: behaviorPlan.weekFiveTarget },
      { name: "Week 6", incidents: behaviorPlan.weekSixTarget },
      { name: "Week 7", incidents: behaviorPlan.weekSevenTarget },
      { name: "Week 8", incidents: behaviorPlan.weekEightTarget },
    ]);
    const possibleValues = [
      behaviorPlan.weekOneTarget,
      behaviorPlan.weekTwoTarget,
      behaviorPlan.weekThreeTarget,
      behaviorPlan.weekFourTarget,
      behaviorPlan.weekFiveTarget,
      behaviorPlan.weekSixTarget,
      behaviorPlan.weekSevenTarget,
      behaviorPlan.weekEightTarget,
    ];
    const tempDomain = [0, findLargestNumber(possibleValues)];
    setDomain(tempDomain);
  }, [
    behaviorPlan.weekEightTarget,
    behaviorPlan.weekFiveTarget,
    behaviorPlan.weekFourTarget,
    behaviorPlan.weekOneTarget,
    behaviorPlan.weekSevenTarget,
    behaviorPlan.weekSixTarget,
    behaviorPlan.weekThreeTarget,
    behaviorPlan.weekTwoTarget,
  ]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <ResponsiveContainer height={400} minWidth={600}>
          <AreaChart
            width={730}
            height={250}
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis domain={domain} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="incidents"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default PreviewChart;
