import { ABCData, BehaviorPlanRecord, StudentRecord } from "../../types/types";
import { behaviors, replacementBehaviors } from "../../libraries/abcData";
import { promptsAtom } from "../../recoil/promptAtoms";
import { useRecoilValue } from "recoil";
import { STRATEGY_PROMPT_TYPES } from "../../types/enums";
import { injectVariables } from "../../utils/aiUtils";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlanForm: BehaviorPlanRecord;
  type: STRATEGY_PROMPT_TYPES;
  preview?: boolean;
};

const usePromptGenerator = () => {
  const typedReplacementBehaviors = replacementBehaviors as ABCData[];
  const prompts = useRecoilValue(promptsAtom);

  const generatePrompt = ({ selectedStudent, behaviorPlanForm, type, preview = false }: Props) => {
    const prompt = prompts.find((prompt) => prompt.type === type);
    const all = prompts.find((prompt) => prompt.type === STRATEGY_PROMPT_TYPES.ALL);
    if (!prompt) return "";

    //when previewing the prompt, we only want the prompt, not the additional prompt
    const combinedPrompt = preview ? prompt.prompt : `${prompt.prompt} ${all?.prompt}`;

    return injectVariables({
      prompt: combinedPrompt,
      behaviors,
      replacementBehaviors: typedReplacementBehaviors,
      behaviorPlanForm,
      selectedStudent,
    });
  };
  return { generatePrompt };
};

export default usePromptGenerator;
