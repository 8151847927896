import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom } from "../../recoil/userAtoms";
import { Collections, STAGES } from "../../types/enums";
import ManageBehaviorPlanRouter from "./ManageBehaviorPlanRouter";
import { behaviorPlanFormAtom, behaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import useGetFSDoc from "../../hooks/shared/useGetFSDoc";
import { useErrorContext } from "../../contexts/ErrorContext";
import useAddFSDoc from "../../hooks/shared/useAddFSDoc";
import { BLANK_BEHAVIOR_PLAN_FORM } from "../../libraries/blankForms";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editId?: string | null;
  selectedStudent: StudentRecord;
};

const ManageBehaviorPlanDialog = ({ open, setOpen, editId = null, selectedStudent }: Props) => {
  const [behvaiorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const [behaviorPlanStage, setBehaviorPlanStage] = useState<STAGES>(STAGES.STAGE_1);
  const { getFSDoc } = useGetFSDoc();
  const { setErrorMessage } = useErrorContext();
  const { addFSDoc } = useAddFSDoc();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setBehaviorPlans = useSetRecoilState(behaviorPlansAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const { isMobile } = useWindowDimensions();
  const [nextDisabled, setNextDisabled] = useState(true);
  const { updateFSDoc } = useUpdateFSDoc();

  useEffect(() => {
    if (!editId) return;
    const getBehaviorPlan = async () => {
      const behaviorPlan = await getFSDoc<BehaviorPlanRecord>({
        col: Collections.Behavior_Plans,
        id: editId,
      });
      if (!behaviorPlan) {
        console.error("No behavior plan found for id: ", editId);
        setErrorMessage(
          "There was an error retriving this behavior plan, please refresh and try again."
        );
        return;
      }
      setBehaviorPlanForm(behaviorPlan);
    };
    getBehaviorPlan();
  }, [editId, getFSDoc, setBehaviorPlanForm, setErrorMessage]);

  const handleSave = async () => {
    if (!selectedStudent || !loggedInStaff) {
      console.error("No student or staff found for behavior plan");
      setErrorMessage("There was an error saving this behavior plan, please try again.");
      return;
    }
    const results = await addFSDoc({
      col: Collections.Behavior_Plans,
      data: { ...behvaiorPlanForm, studentId: selectedStudent.id, staffId: loggedInStaff.id },
    });
    if (!results) return null;
    setBehaviorPlans((prev) => [
      { ...behvaiorPlanForm, studentId: selectedStudent.id, ...results },
      ...prev,
    ]);
    setSelectedStudent((prev) => (prev ? { ...prev, activeBehaviorPlanId: results.id } : prev));
    await updateFSDoc({
      col: Collections.Students,
      id: selectedStudent.id,
      data: { activeBehaviorPlanId: results.id },
    });
    setOpen(false);
    setBehaviorPlanForm(BLANK_BEHAVIOR_PLAN_FORM);
    setBehaviorPlanStage(STAGES.STAGE_1);
  };

  const handleNext = () => {
    setBehaviorPlanStage((prev) => prev + 1);
  };

  const handleBack = () => {
    setBehaviorPlanStage((prev) => prev - 1);
  };

  return (
    <>
      <Dialog open={open} fullWidth={!isMobile} fullScreen={isMobile} maxWidth="md">
        <DialogTitle sx={{ fontSize: 40, textAlign: "center", pb: 0, mb: 0 }}>
          {editId ? "Edit" : "New"} Behavior Plan
        </DialogTitle>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body1" sx={{ mt: 0, pt: 0 }}>
            {`For ${selectedStudent.firstName} ${selectedStudent.lastName}`}
          </Typography>
        </Box>
        <DialogContent>
          <ManageBehaviorPlanRouter
            behaviorPlanStage={behaviorPlanStage}
            selectedStudent={selectedStudent}
            setNextDisabled={setNextDisabled}
          />
        </DialogContent>
        <DialogActions>
          <Button color="cancel" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          {behaviorPlanStage !== STAGES.STAGE_1 && (
            <Button type="submit" variant="contained" onClick={handleBack}>
              Back
            </Button>
          )}
          {behaviorPlanStage === STAGES.STAGE_7 ? (
            <Button type="submit" variant="contained" onClick={handleSave} disabled={nextDisabled}>
              Save
            </Button>
          ) : (
            <Button type="submit" variant="contained" onClick={handleNext} disabled={nextDisabled}>
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageBehaviorPlanDialog;
