import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import { useSetRecoilState } from "recoil";
import { locationsAtom } from "../../recoil/observationAtoms";
import { useState } from "react";
import useHandleError from "../shared/useHandleError";
import { EVENT_TYPE } from "../../types/logEnums";

type Props = {
  deleteId: string;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
};

const useDeleteLocationConfirmation = ({ deleteId, setDeleteId }: Props) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const setLocations = useSetRecoilState(locationsAtom);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { handleError } = useHandleError();

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await updateFSDoc({ col: Collections.Locations, data: { active: false }, id: deleteId });
      setLocations((prev) => prev.filter((loc) => loc.id !== deleteId));
    } catch (error) {
      handleError({
        snackbarMessage: "Error deleting location",
        error,
        file: "useDeleteLocationConfirmation.ts",
        eventType: EVENT_TYPE.DELETE_LOCATION,
      });
    }
    setDeleteLoading(false);
    setDeleteId(null);
  };
  return { handleDelete, deleteLoading };
};

export default useDeleteLocationConfirmation;
