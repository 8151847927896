import { getDownloadURL, getStorage, ref } from "firebase/storage";

export const getImageURL = async (fileName: string) => {
  const storage = getStorage();
  let url = "";
  try {
    try {
      url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
    } catch (error) {
      url = await getDownloadURL(ref(storage, `${fileName}`));
    }
  } catch (error) {
    console.log(error);
    return null;
  }
  return url;
};
