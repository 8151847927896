import React, { useEffect, useState } from "react";
import { Collections } from "../../types/enums";
import { useRecoilState } from "recoil";
import { loggedInStaffAtom, staffFormAtom } from "../../recoil/userAtoms";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import useHandleError from "../../hooks/shared/useHandleError";
import { EVENT_TYPE } from "../../types/logEnums";

type Props = {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const useProfileForm = ({ setEditMode }: Props) => {
  const [loggedInStaff, setLoggedInStaff] = useRecoilState(loggedInStaffAtom);
  const [staffForm, setStaffForm] = useRecoilState(staffFormAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleError();

  useEffect(() => {
    if (!loggedInStaff) return;
    setStaffForm({ ...loggedInStaff });
  }, [loggedInStaff, setStaffForm]);

  const handleSave = async () => {
    if (!staffForm) return;
    setLoading(true);
    try {
      setLoggedInStaff(staffForm);
      await updateFSDoc({ col: Collections.Staff, data: staffForm, id: staffForm.id });
    } catch (error) {
      handleError({
        snackbarMessage: "Error saving profile, please refresh and try again",
        eventType: EVENT_TYPE.UPDATE_STAFF_PROFILE,
        error,
        file: "ProfileForm.tsx",
      });
    }
    setEditMode(false);
    setLoading(false);
  };
  return { handleSave, loading, staffForm };
};

export default useProfileForm;
