import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import useAddFSDoc from "../../hooks/shared/useAddFSDoc";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";

type Props = {
  setConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SupportMessageForm = ({ setConfirmationOpen }: Props) => {
  const [message, setMessage] = useState("");
  const { addFSDoc } = useAddFSDoc();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const handleClick = () => {
    addFSDoc({ col: "supportMessage", data: { message, from: loggedInStaff?.email } });
    setConfirmationOpen(true);
  };
  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Message"
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{
          mt: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px", // Adjust the value as needed
          },
        }}
      />
      <Button onClick={handleClick} sx={{ mt: 2 }} fullWidth variant="outlined" color="primary">
        Send Message
      </Button>
    </Box>
  );
};

export default SupportMessageForm;
