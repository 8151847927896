import React from "react";
import { StudentRecord, SurveyQuestionRecord } from "../../types/types";
import { Grid, Paper, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { surveyFormAtom } from "../../recoil/surveyAtoms";
import RadioOptions from "./RadioOptions";
import { formatTextWithName } from "../../utils/formatUtils";

type Props = {
  question: SurveyQuestionRecord;
  index: number;
  notAnswered: string[];
  setNotAnswered: (pV: string[]) => void;
  selectedStudent: StudentRecord;
};

function SurveyQuestion({ question, index, notAnswered, setNotAnswered, selectedStudent }: Props) {
  const [functionSurveyForm, setFunctionSurveyForm] = useRecoilState(surveyFormAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setFunctionSurveyForm((pV) => ({
      ...pV,
      responses: { ...pV.responses, [question.id]: value },
    }));
    const tempArray: string[] = [...notAnswered];
    tempArray.splice(tempArray.indexOf(question.id), 1);
    setNotAnswered(tempArray);
  };
  const error = notAnswered.includes(question.id);
  return (
    <>
      <Paper sx={{ mt: 2, padding: 1, border: error ? "1px solid red" : "none" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5} lg={7}>
            <Typography>{`${index + 1}: ${formatTextWithName({
              questionString: question.label,
              firstName: selectedStudent.firstName,
            })}`}</Typography>
            {error && (
              <Typography sx={{ color: "red" }} variant="body2">
                This Question Must Be Answered
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={5} sx={{ textAlign: "right" }}>
            <RadioOptions
              value={
                question.id in functionSurveyForm.responses
                  ? functionSurveyForm.responses[question.id]
                  : null
              }
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default SurveyQuestion;
