import { Box, Button } from "@mui/material";
import React from "react";

type Props = {
  setSelectedReport: React.Dispatch<React.SetStateAction<string>>;
};

const ReportSelectMenu = ({ setSelectedReport }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          onClick={() => setSelectedReport("frequency")}
          fullWidth
          variant="outlined"
          color="primary"
        >
          Frequency By Behavior
        </Button>
        <Button
          onClick={() => setSelectedReport("function")}
          fullWidth
          variant="outlined"
          color="primary"
        >
          Function of Behavior
        </Button>
        <Button
          onClick={() => setSelectedReport("location")}
          fullWidth
          variant="outlined"
          color="primary"
        >
          Location of Behavior
        </Button>
      </Box>
    </>
  );
};

export default ReportSelectMenu;
