import { FREQUENCY_RATES, USER_TYPES } from "../types/enums";
import {
  BehaviorPlanRecord,
  Observation,
  Staff,
  Student,
  SurveyQuestion,
  SurveyResult,
} from "../types/types";

export const BLANK_OBSERVATION_FORM: Observation = {
  behaviorId: "",
  antecedentId: "",
  consequenceId: "",
  locationId: "",
  date: new Date().toISOString(),
  anythingElse: "",
  staffId: "",
  studentId: "",
  functionOfBehavior: null,
  functionConfidence: 0,
};

export const BLANK_STUDENT_FORM: Student = {
  firstName: "",
  lastName: "",
  avatar: "",
  grade: "",
  dob: "",
  staffId: "",
  externalId: "",
  avatarURL: null,
  activeBehaviorPlanId: null,
};

export const BLANK_STAFF_FORM: Staff = {
  firstName: "",
  lastName: "",
  avatar: "",
  email: "",
  jobTitle: "",
  schoolName: "",
  districtName: "",
  schoolURL: "",
  avatarURL: null,
  userType: USER_TYPES.STAFF,
  completedTours: [],
};

export const BLANK_SURVEY_QUESTION_FORM: SurveyQuestion = {
  label: "",
  functionOfBehavior: null,
  order: 0,
};

export const BLANK_SURVEY_RESULT_FORM: SurveyResult = {
  submitter: "",
  relationship: "",
  studentId: "",
  behaviorId: "",
  descriptionOfBehavior: "",
  responses: {},
  staffId: "",
};

export const BLANK_BEHAVIOR_PLAN_FORM: BehaviorPlanRecord = {
  targetBehavior: "",
  targetBehaviorNotes: "",
  behaviorDefinition: "",
  functionOfBehavior: "",
  replacementBehavior: "",
  replacementBehaviorsNotes: "",
  antecedents: [],
  antecedentNotes: "",
  preventionStrategies: "",
  reinforcementStrategies: "",
  extinguishStrategies: "",
  studentId: "",
  staffId: "",
  bio: "",
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  startingFrequency: 0,
  frequencyRate: FREQUENCY_RATES.DAILY,
  weekOneTarget: 0,
  weekOneActual: null,
  weekTwoTarget: 0,
  weekTwoActual: null,
  weekThreeTarget: 0,
  weekThreeActual: null,
  weekFourTarget: 0,
  weekFourActual: null,
  weekFiveTarget: 0,
  weekFiveActual: null,
  weekSixTarget: 0,
  weekSixActual: null,
  weekSevenTarget: 0,
  weekSevenActual: null,
  weekEightTarget: 0,
  weekEightActual: null,
};
