import { Box } from "@mui/material";
import React from "react";
import SideNavigation from "../components/Navigation/SideNavigation";
import SupportContainer from "../components/Support/SupportContainer";
import MobileBottomBar from "../components/Navigation/MobileBottomBar";
import useWindowDimensions from "../hooks/shared/useWindowDimensions";

const SupportPage = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideNavigation />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <SupportContainer />
          </Box>
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <SupportContainer />
          <MobileBottomBar />
        </Box>
      )}
    </>
  );
};

export default SupportPage;
