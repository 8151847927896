import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import { useSetRecoilState } from "recoil";
import { behaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";

type Props = {
  id: string | null;
  setId: Dispatch<SetStateAction<string | null>>;
};

const DeleteBehaviorPlanDialog = ({ id, setId }: Props) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const setBehaviorPlans = useSetRecoilState(behaviorPlansAtom);

  const handleDelete = () => {
    if (!id) return;
    updateFSDoc({
      col: Collections.Behavior_Plans,
      data: { active: false },
      id: id,
    });
    setBehaviorPlans((prev) => prev.filter((record) => record.id !== id));
    setId(null);
  };
  return (
    <Dialog open={Boolean(id)} onClose={() => setId(null)}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this behavior plan? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setId(null)}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBehaviorPlanDialog;
