import { atom } from "recoil";
import { TOUR_NAMES } from "../types/enums";

type Steps = { selector: string; content: string };

export const tourStepsAtom = atom<Steps[]>({
  key: "tourStepsAtom",
  default: [],
});

export const tourNameAtom = atom<TOUR_NAMES | null>({
  key: "tourNameAtom",
  default: null,
});
