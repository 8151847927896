import { TextField, Typography } from "@mui/material";
import useContinueWithPassword from "../../hooks/Login/useContinueWithPassword";
import { EmailNextSteps } from "../../types/enums";
import { formatNextStepsMessage } from "../../utils/authUtils";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import useEnterKeyPress from "../../hooks/shared/useEnterKeyPress";
import { LoadingButton } from "@mui/lab";

//checks to see if user is in auth system, if not, asks to sign up, if so, asks to sign in
const ContinueWithPassword = () => {
  useEnterKeyPress("continue-login-with-email");
  const { searchForStaffEmail, email, handleEmailChange, emailNextSteps, isEmailValid, loading } =
    useContinueWithPassword();

  return (
    <>
      <Typography textAlign="center" variant="h6">
        Continue with Email
      </Typography>

      {/* next steps can be to check auth whether user exists, sign in, sign up, or use google */}
      <Typography sx={{ mt: 1 }} textAlign="center" variant="subtitle1">
        {formatNextStepsMessage(emailNextSteps)}
      </Typography>

      <TextField
        onChange={handleEmailChange}
        sx={{ mt: 2 }}
        fullWidth
        label="Email"
        type="email"
        value={email}
        disabled={Boolean(emailNextSteps) && emailNextSteps !== EmailNextSteps.GOOGLE}
        id="email"
      />

      {emailNextSteps === EmailNextSteps.SIGN_IN && <SignInForm email={email} />}
      {emailNextSteps === EmailNextSteps.SIGN_UP && <SignUpForm email={email} />}

      {(!emailNextSteps || emailNextSteps === EmailNextSteps.GOOGLE) && (
        <LoadingButton
          onClick={searchForStaffEmail}
          color="primary"
          sx={{ mt: 2 }}
          loading={loading}
          fullWidth
          variant="contained"
          disabled={!isEmailValid}
          id="continue-login-with-email"
        >
          Continue
        </LoadingButton>
      )}
    </>
  );
};

export default ContinueWithPassword;
