import React, { useCallback } from "react";
import { ABCData, ObservationRecord, SurveyResultRecord } from "../../types/types";
import { Box } from "@mui/material";

type Props = {
  behaviors: ABCData[];
  surveys: SurveyResultRecord[];
  observations: ObservationRecord[];
};

type BehaviorLabelCount = {
  id: string;
  label: JSX.Element;
};

const useCountTargetBehaviors = () => {
  const createBehaviorLabelCount = useCallback(
    ({ behaviors, surveys, observations }: Props): BehaviorLabelCount[] => {
      return behaviors
        .map((behavior): BehaviorLabelCount | null => {
          const behaviorSurveyCount = surveys.filter(
            (survey) => survey.behaviorId === behavior.id
          ).length;

          // Skip behaviors with no surveys
          if (behaviorSurveyCount === 0) {
            return null;
          }

          const behaviorObservationCount = observations.filter(
            (observation) => observation.behaviorId === behavior.id
          ).length;

          return {
            id: behavior.id,
            label: (
              <Box sx={{ width: "100%" }}>
                <strong>{behavior.label}</strong>
                <Box>{`Survey Count: ${behaviorSurveyCount}`}</Box>
                <Box>{`Observation Count: ${behaviorObservationCount}`}</Box>
              </Box>
            ),
          };
        })
        .filter((item): item is BehaviorLabelCount => item !== null);
    },
    []
  );
  return { createBehaviorLabelCount };
};

export default useCountTargetBehaviors;
