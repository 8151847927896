import { Box, Button, Grid, Typography } from "@mui/material";
import { useRef, useState } from "react";
import plus from "../../assets/icons/plus.svg";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { selectedStudentSurveyResultsAtom } from "../../recoil/surveyAtoms";
import SurveyCard from "./SurveyCard";
import ManageSurveyDialog from "../Surveys/ManageSurveyDialog";
import SurveyResultsDialog from "../Surveys/SurveyResultsDialog";
import DeleteSurveyDialog from "./DeleteSurveyDialog";
import { SurveyResultRecord } from "../../types/types";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";
import InfoBar from "../InfoBar/InfoBar";
import { Player } from "@lottiefiles/react-lottie-player";
import { COLORS } from "../../types/enums";

const Surveys = () => {
  const selectedStudentSurveyResults = useRecoilValue(selectedStudentSurveyResultsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyResultRecord | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [surveyOpen, setSurveyOpen] = useState(false);
  const { isMobile } = useWindowDimensions();
  const playerRef = useRef<any>(null);
  const arrowRef = useRef<any>(null);
  if (!selectedStudent) return null;

  return (
    <>
      <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Behavior Surveys</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {selectedStudentSurveyResults.length === 0 && (
            <Player
              ref={arrowRef}
              autoplay={true}
              loop={true}
              style={{ width: 50 }}
              src="https://lottie.host/0428d50e-10b0-416b-85d8-d13895096e3b/17u2JVorjD.json"
            />
          )}
          <Button variant="defaultOutlined" onClick={() => setSurveyOpen(true)}>
            <img src={plus} alt="Add New" style={{ width: 20, marginRight: isMobile ? 0 : 4 }} />
            {!isMobile ? "Add New" : ""}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <InfoBar content={INFO_TEXT} />
      </Box>
      <Box sx={{ mt: 2 }}>
        {selectedStudentSurveyResults.length === 0 ? (
          <>
            <Typography sx={{ color: COLORS.PRIMARY, mt: 2 }} variant="h2" textAlign={"center"}>
              Fill out at least one behavior survey for {selectedStudent.firstName}.
            </Typography>
            <Player
              ref={playerRef}
              autoplay={true}
              loop={true}
              style={{ width: 400 }}
              src="https://lottie.host/8644fcd4-91bd-411f-a2cf-99a4a742b99b/GKZhRHWnKh.json"
            />
          </>
        ) : (
          <Grid container spacing={2}>
            {selectedStudentSurveyResults.map((record) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={record.id}>
                <SurveyCard
                  surveyResult={record}
                  setDeleteId={setDeleteId}
                  setSelectedSurvey={setSelectedSurvey}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <ManageSurveyDialog open={surveyOpen} setOpen={setSurveyOpen} />
      <SurveyResultsDialog selectedSurvey={selectedSurvey} setSelectedSurvey={setSelectedSurvey} />
      <DeleteSurveyDialog id={deleteId} setId={setDeleteId} />
    </>
  );
};

export default Surveys;

const INFO_TEXT =
  "Step 1 to build a behavior plan is to determine why the behavior is happening (known as the function of the behavior). Complete a behavior survey below to determine the function and move on to step two: building a behavior plan.";
