import React from "react";
import BehaviorPlanPreview from "./BehaviorPlanPreview";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlan: BehaviorPlanRecord;
  setBehaviorPlan: React.Dispatch<React.SetStateAction<BehaviorPlanRecord | null>>;
};

const ShowBehaviorPlanDialog = ({ selectedStudent, behaviorPlan, setBehaviorPlan }: Props) => {
  return (
    <>
      {selectedStudent && behaviorPlan && (
        <Dialog open={true} fullWidth maxWidth="md">
          <DialogContent>
            <BehaviorPlanPreview behaviorPlan={behaviorPlan} selectedStudent={selectedStudent} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBehaviorPlan(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ShowBehaviorPlanDialog;
