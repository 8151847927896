import { Button } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import GoogleLogo from "../../assets/Google__G__logo.svg";

const ContinueWithGoogle = () => {
  const { signInWithGoogle } = useAuth();
  return (
    <>
      <Button
        size="large"
        type="submit"
        color="primary"
        fullWidth
        variant="outlined"
        onClick={signInWithGoogle}
        sx={{ mt: 2, padding: 1, fontSize: 16 }}
      >
        <img src={GoogleLogo} alt="Google Logo" style={{ marginRight: 10 }} />
        Continue With Google
      </Button>
    </>
  );
};

export default ContinueWithGoogle;
