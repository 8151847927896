import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { observationFormAtom } from "../../recoil/observationAtoms";
import { DateTime } from "luxon";

type ClickProps = {
  value: string;
  name: string;
};

const useObservationFormChange = () => {
  const setObservationForm = useSetRecoilState(observationFormAtom);
  const handleTextFormChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setObservationForm((pV) => ({ ...pV, [name]: value }));
    },
    [setObservationForm]
  );

  const handleClick = useCallback(
    ({ value, name }: ClickProps) => {
      setObservationForm((pV) => ({ ...pV, [name]: value }));
    },
    [setObservationForm]
  );

  const handleDateChange = useCallback(
    (date: DateTime | null) => {
      setObservationForm((pV) => ({ ...pV, date: date?.toISO() ?? "" }));
    },
    [setObservationForm]
  );
  return { handleTextFormChange, handleClick, handleDateChange };
};

export default useObservationFormChange;
