export enum LOG_SEVERITY {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export enum EVENT_TYPE {
  UPDATE_PROMPTS_FAILED = "update-prompts-failed",
  UPDATE_SURVEY_ANSWER = "update-survey-answer",
  UPDATE_SURVEY_ANSWER_FAILED = "update-survey-answer-failed",
  UPDATE_STAFF_PROFILE = "update-staff-profile",
  DELETE_LOCATION = "delete-location",
  DELETE_STUDENT = "delete-student",
  MAKE_BEHAVIOR_PLAN_ACTIVE = "make-behavior-plan-active",
  NO_ACTIVE_BEHAVIOR_PLAN = "no-active-behavior-plan",
  ERROR_SAVING_ACTUAL_GOAL_DATA = "error-saving-actual-goal-data",
  NEW_USER_CREATED = "new-user-created",
  USER_SIGNUP_ERROR = "user-signup-error",
  USER_LOGGED_IN = "user-logged-in",
  USER_LOGIN_ERROR = "user-login-error",
  RESET_PASSWORD_ERROR = "reset-password-error",
}
