import { Avatar, Badge, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from "react";
import useUploadImage from "../../hooks/shared/useUploadImage";
import FeatherIcon from "./FeatherIcon";
import { getImageURL } from "../../utils/imageUtils";
import { useSetRecoilState } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { Collections } from "../../types/enums";
import Loading from "./Loading";

type Props = {
  fileName: string;
  documentId: string | null;
  collection: string;
  size: number;
  setForm?: Dispatch<SetStateAction<any>>; // Optional prop to update a form
  edit?: boolean; // Optional prop to enable editing mode
};

const ProfilePicture = ({ fileName, documentId, collection, size, setForm, edit }: Props) => {
  const [loading, setLoading] = useState(false);
  const { uploadImage, isLoading } = useUploadImage();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  // State to store the current filename of the avatar
  const [avatar, setAvatar] = useState(fileName);

  // State to store the fetched URL for displaying the avatar
  const [avatarURL, setAvatarURL] = useState<string | null>(null);

  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);

  // Update the avatar state whenever the 'fileName' prop changes
  useEffect(() => {
    setAvatar(fileName);
  }, [fileName]);

  // Fetch the image URL for the current avatar whenever the 'avatar' state changes
  useEffect(() => {
    const fetchImage = async () => {
      if (avatar.length > 5) {
        setLoading(true);
        const url = await getImageURL(avatar);
        setAvatarURL(url);
        setLoading(false);
      }
    };
    fetchImage();
  }, [avatar]);

  // Handles image selection and upload
  const handleSelectImage = useCallback(
    async ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (!files) return;

      // Attempt to upload the image
      const newFileName = await uploadImage({
        image: files[0],
        col: collection,
        id: documentId,
      });

      // Update the form and logged-in staff data if the upload was successful
      if (!newFileName) return;
      setForm && setForm((pV: any) => ({ ...pV, avatar: newFileName }));
      if (collection === Collections.Staff)
        setLoggedInStaff((pV) => (pV ? { ...pV, avatar: newFileName } : null));
      setAvatar(newFileName);
    },
    [collection, documentId, setForm, setLoggedInStaff, uploadImage]
  );

  // Unique ID for the hidden file input element
  const id = `id-${avatar}${Math.random()}`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        textAlign: "center",
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <>
            {/* Only show if the 'edit' prop is true */}
            {true && (
              <label htmlFor={id}>
                {/* Hidden file input */}
                <input hidden accept="image/*" id={id} type="file" onChange={handleSelectImage} />

                {/* Edit button displayed if 'edit' prop is true */}
                {edit && (
                  <IconButton disableRipple aria-label="upload picture" component="span">
                    <FeatherIcon icon="edit" width="15px" />
                  </IconButton>
                )}
              </label>
            )}
          </>
        }
      >
        {/* Display the avatar using the fetched URL or a placeholder if not available */}
        {!loading ? (
          <Avatar
            alt={avatar}
            src={avatarURL ? avatarURL : ""}
            sx={{ width: size, height: size }}
          />
        ) : (
          <Loading size={size} />
        )}
      </Badge>
    </Box>
  );
};

export default ProfilePicture;
