import { useState } from "react";

const useSignupForm = () => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordConfirmCheck(e.target.value === verifyPassword && e.target.value.length > 7);
  };

  const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmCheck(e.target.value === password && password.length > 7);
    setVerifyPassword(e.target.value);
  };
  return {
    password,
    handlePasswordChange,
    handlePasswordConfirmChange,
    passwordConfirmCheck,
  };
};

export default useSignupForm;
