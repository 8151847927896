import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { observationFormAtom } from "../../recoil/observationAtoms";
import useObservationFormChange from "../../hooks/Observations/useObservationFormChange";

const DesktopAnythingElse = () => {
  const observationForm = useRecoilValue(observationFormAtom);

  const { handleTextFormChange } = useObservationFormChange();
  return (
    <>
      <Typography textAlign="left" variant="h6" sx={{ mt: 2 }}>
        Anything Else
      </Typography>
      <Typography textAlign="left" variant="body2">
        (Optional)
      </Typography>
      <Box sx={{ p: 1 }}>
        <TextField
          onChange={handleTextFormChange}
          multiline
          fullWidth
          rows={2}
          value={observationForm.anythingElse}
          name="anythingElse"
        />
      </Box>
    </>
  );
};

export default DesktopAnythingElse;
