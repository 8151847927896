import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { LocationRecord } from "../../types/types";
import FeatherIcon from "../shared/FeatherIcon";

type Props = {
  location: LocationRecord;
  setDeleteId: React.Dispatch<React.SetStateAction<string | null>>;
  setEditId: React.Dispatch<React.SetStateAction<string | null>>;
};

const LocationCard = ({ location, setDeleteId, setEditId }: Props) => {
  return (
    <>
      <Box sx={{ borderRadius: 4, border: "1px solid #ddd", p: 1, mt: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Typography variant="h6">{location.name}</Typography>
          <Box>
            <IconButton onClick={() => setEditId(location.id)}>
              <FeatherIcon icon="edit" width="20px" />
            </IconButton>
            <IconButton onClick={() => setDeleteId(location.id)}>
              <FeatherIcon icon="trashcan" width="20px" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LocationCard;
