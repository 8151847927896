import { useCallback, useEffect, useMemo, useState } from "react";
import { StudentRecord } from "../../types/types";
import ReactQuill from "react-quill";
import { useRecoilState } from "recoil";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";
import useFetchStrategy from "./useFetchStrategy";
import usePromptGenerator from "./usePromptGenerator";
import { STRATEGY_PROMPT_TYPES } from "../../types/enums";
import MarkdownIt from "markdown-it";

type Props = {
  selectedStudent: StudentRecord;
};

const usePreventionStrategies = ({ selectedStudent }: Props) => {
  const [open, setOpen] = useState(false);
  const md = useMemo(() => new MarkdownIt(), []);
  //teachers pick the reason to rerun the prompt to help make the prompt better
  const [rerunReason, setRerunReason] = useState<string>("");
  const [promptResponse, setPromptResponse] = useState<any>("");
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const { generatePrompt } = usePromptGenerator();
  const prompt = generatePrompt({
    selectedStudent,
    behaviorPlanForm,
    type: STRATEGY_PROMPT_TYPES.PREVENTION,
  });
  const { fetchStrategy, loading } = useFetchStrategy();

  //basic text change handler
  const handleTextChange = useCallback(
    (newValue: ReactQuill.Value, delta: any, source: any) => {
      if (source !== "user") return;
      setBehaviorPlanForm((prev) => ({ ...prev, preventionStrategies: newValue }));
    },
    [setBehaviorPlanForm]
  );

  //takes the prompt AI generated and adds it to the Quill editor and to the behaviorPlanForm
  const handleUseStrategy = useCallback(() => {
    setBehaviorPlanForm((prev) => ({
      ...prev,
      preventionStrategies: md.render(promptResponse as any),
    }));
  }, [md, promptResponse, setBehaviorPlanForm]);

  //prompt response is set and used here, so intentionally not in the dependency array
  useEffect(() => {
    if (rerunReason) {
      const getNewStrategy = async () => {
        const response = await fetchStrategy({ prompt, rerunReason, promptResponse });
        const converted = md.render(response as any);
        setBehaviorPlanForm((prev) => ({
          ...prev,
          preventionStrategies: converted,
        }));
        setPromptResponse(response);
      };
      getNewStrategy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStrategy, prompt, rerunReason]);

  //opens the GenerateRerunReason dialog in the shared directory which makes the use say why they want to generate new strategies, which then gets fed back into the AI system
  const handleResetAI = () => {
    setOpen(true);
  };

  //The button click that actually fetches the new strategies
  const handleFetchStrategy = async () => {
    const response = await fetchStrategy({ prompt, rerunReason, promptResponse });
    const converted = md.render(response as any);
    setBehaviorPlanForm((prev) => ({
      ...prev,
      preventionStrategies: converted,
    }));
    setPromptResponse(response);
  };

  return {
    handleTextChange,
    loading,
    promptResponse,
    handleResetAI,
    setRerunReason,
    behaviorPlanForm,
    open,
    setOpen,
    handleUseStrategy,
    handleFetchStrategy,
  };
};

export default usePreventionStrategies;
