import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { surveyFormAtom, surveyQuestionsAtom, surveyResultsAtom } from "../../recoil/surveyAtoms";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useCallback, useState } from "react";
import { BLANK_SURVEY_RESULT_FORM } from "../../libraries/blankForms";
import useAddFSDoc from "../../hooks/shared/useAddFSDoc";
import { Collections } from "../../types/enums";
import { useAuth } from "../../contexts/AuthContext";
import { useErrorContext } from "../../contexts/ErrorContext";
import { BaseRecord, SurveyResult } from "../../types/types";

type Props = {
  setOpen: (pV: boolean) => void;
};

function useManageSurveyDialog({ setOpen }: Props) {
  const { authUser } = useAuth();
  const [surveyForm, setSurveyForm] = useRecoilState(surveyFormAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setSurveyResults = useSetRecoilState(surveyResultsAtom);
  const questions = useRecoilValue(surveyQuestionsAtom);
  const [sendLinkOpen, setSendLinkOpen] = useState(false);
  const [notAnswered, setNotAnswered] = useState<string[]>([]);
  const { addFSDoc } = useAddFSDoc();
  const { setErrorMessage } = useErrorContext();

  const resetFormAndCloseDialog = useCallback(() => {
    setSurveyForm(BLANK_SURVEY_RESULT_FORM);
    setOpen(false);
  }, [setOpen, setSurveyForm]);

  const updateSurveyResults = useCallback(
    (newRecord: BaseRecord, surveyRecord: SurveyResult) => {
      setSurveyResults((prev) => [{ ...newRecord, ...surveyRecord }, ...prev]);
    },
    [setSurveyResults]
  );

  const handleSaveError = useCallback(
    (message: string, error?: Error) => {
      setErrorMessage(message);
      console.error(message, error);
    },
    [setErrorMessage]
  );

  const validateQuestions = useCallback(() => {
    if (!questions) return;
    const tempArray: string[] = [];
    // Check if all questions have been answered, return the id of the questions that have not been answered
    questions.forEach((question) => {
      if (!surveyForm.responses[question.id]) {
        tempArray.push(question.id);
      }
    });
    setNotAnswered(tempArray);
    return tempArray.length === 0 ? true : false;
  }, [questions, surveyForm.responses]);

  const handleSave = useCallback(async () => {
    // Early exit if essential data is missing
    if (!selectedStudent || !authUser) return;

    if (!validateQuestions()) {
      setErrorMessage("You must answer all questions before saving");
      return; // Early return if validation fails
    }

    const surveyRecord = {
      ...surveyForm,
      studentId: selectedStudent.id,
      staffId: authUser.uid,
    };

    try {
      const newRecord: BaseRecord | null = await addFSDoc({
        col: Collections.Surevey_Results,
        data: surveyRecord,
      });
      if (!newRecord) {
        handleSaveError("Error saving survey results");
        return;
      }
      updateSurveyResults(newRecord, surveyRecord);
      resetFormAndCloseDialog();
    } catch (error: any) {
      handleSaveError("Error saving survey results", error);
    }
  }, [
    addFSDoc,
    authUser,
    handleSaveError,
    resetFormAndCloseDialog,
    selectedStudent,
    setErrorMessage,
    surveyForm,
    updateSurveyResults,
    validateQuestions,
  ]);

  const handleSendLinkOpen = () => {
    setSendLinkOpen(true);
  };
  return {
    handleSave,
    handleSendLinkOpen,
    notAnswered,
    setNotAnswered,
    sendLinkOpen,
    setSendLinkOpen,
  };
}

export default useManageSurveyDialog;
