import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import infoIcon from "../../assets/icons/gradiant-info.svg";
import closeIcon from "../../assets/icons/no-circle-x.svg";

type Props = {
  content: string;
};
const infoBarStyle = {
  borderRadius: "8px",
  border: "1px solid #E6E5FB",
  backgroundImage:
    "linear-gradient(90deg, rgba(251, 255, 232, 0.20) 0%, rgba(207, 251, 245, 0.20) 50%, rgba(238, 243, 255, 0.20) 100%)",
};

const InfoBar: React.FC<Props> = ({ content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(true);

  const toggleInfoBar = () => setIsOpen(!isOpen);

  if (!isOpen) {
    return (
      <Box sx={{ display: "flex", justifyContent: "right" }} onClick={toggleInfoBar}>
        <img src={infoIcon} alt="Info" style={{ width: 30 }} />
      </Box>
    );
  }

  return isMobile ? (
    <MobileInfoBar content={content} toggleInfoBar={toggleInfoBar} />
  ) : (
    <DesktopInfoBar content={content} toggleInfoBar={toggleInfoBar} />
  );
};

const MobileInfoBar: React.FC<{ content: string; toggleInfoBar: () => void }> = ({
  content,
  toggleInfoBar,
}) => (
  <Box sx={infoBarStyle}>
    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1, alignItems: "center" }}>
      <InfoIcon size={30} />
      <img src={closeIcon} alt="Close" style={{ width: 20 }} onClick={toggleInfoBar} />
    </Box>
    <Box sx={{ px: 2, pb: 2 }}>
      <Typography textAlign="center">{content}</Typography>
    </Box>
  </Box>
);

const DesktopInfoBar: React.FC<{ content: string; toggleInfoBar: () => void }> = ({
  content,
  toggleInfoBar,
}) => (
  <Box sx={infoBarStyle}>
    <Box sx={{ display: "flex", alignItems: "flex-start", p: 2, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InfoIcon size={40} />
        <Typography sx={{ flex: 1 }}>{content}</Typography>
      </Box>
      <img
        src={closeIcon}
        alt="Close"
        style={{ width: 20, cursor: "pointer" }}
        onClick={toggleInfoBar}
      />
    </Box>
  </Box>
);

const InfoIcon: React.FC<{ size: number }> = ({ size }) => (
  <img src={infoIcon} alt="Info" style={{ width: size, marginRight: 24 }} />
);

export default InfoBar;
