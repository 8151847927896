import { useCallback, useEffect, useState } from "react";
import { auth, provider } from "../firebase";
import {
  User,
  signInWithPopup,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { AuthContext } from "../contexts/AuthContext";
import useLogger from "../hooks/shared/useLogger";
import useHandleError from "../hooks/shared/useHandleError";
import { EVENT_TYPE, LOG_SEVERITY } from "../types/logEnums";

type EmailPasswordProps = { email: string; password: string };

type Props = {
  children: JSX.Element;
};

const generateError = (error: string) => {
  switch (error) {
    case "auth/invalid-email":
      return "Invalid email address";
    case "auth/user-not-found":
      return "No user found with that email address";
    case "auth/invalid-credential":
      return "Incorrect password";
    case "auth/missing-passord":
      return "Password is required";
    default:
      return "An error occurred";
  }
};
const AuthProvider = ({ children }: Props) => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>("");
  const [message, setMessage] = useState<string | null>("");
  const { submitLog } = useLogger();
  const { handleError } = useHandleError();

  const signUpWithEmail = async ({ email, password }: EmailPasswordProps) => {
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      submitLog({
        severity: LOG_SEVERITY.INFO,
        eventType: EVENT_TYPE.NEW_USER_CREATED,
        changeLog: "User signed up with email",
        file: "AuthProvider.tsx",
      });
    } catch (error) {
      setError("Error signing up with Email, please try again");
      handleError({
        error,
        snackbarMessage: "Error signing up with Email, please try again",
        eventType: EVENT_TYPE.USER_SIGNUP_ERROR,
        file: "AuthProvider.tsx",
      });
    }
    setLoading(false);
  };

  const signInWithGoogle = useCallback(async () => {
    setLoading(true);
    signInWithPopup(auth, provider).then(async ({ user }: UserCredential) => {
      if (!user || !user.email) {
        setError("Error signing in with Google, please try again");
        handleError({
          error: "Error signing up with Google.",
          snackbarMessage: "Error signing in with Google, please try again",
          eventType: EVENT_TYPE.USER_LOGIN_ERROR,
          file: "AuthProvider.tsx",
        });
        auth.signOut();
        setLoading(false);
        return;
      }
      submitLog({
        severity: LOG_SEVERITY.INFO,
        eventType: EVENT_TYPE.USER_LOGGED_IN,
        changeLog: "User signed in up with google",
        file: "AuthProvider.tsx",
      });
      setLoading(false);
    });
  }, [handleError, submitLog]);

  const signInWithPassword = async ({ email, password }: EmailPasswordProps) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      submitLog({
        severity: LOG_SEVERITY.INFO,
        eventType: EVENT_TYPE.USER_LOGGED_IN,
        changeLog: "User signed in up with email",
        file: "AuthProvider.tsx",
      });
      return null;
    } catch (error: any) {
      const errorCode = error.code as string;
      setError(generateError(errorCode));
      handleError({
        error: errorCode,
        snackbarMessage: generateError(errorCode),
        eventType: EVENT_TYPE.USER_LOGIN_ERROR,
        file: "AuthProvider.tsx",
      });
    }
    setLoading(false);
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent");
    } catch (error: any) {
      const errorCode = error.code as string;
      setError(generateError(errorCode));
      handleError({
        error: errorCode,
        snackbarMessage: generateError(errorCode),
        eventType: EVENT_TYPE.RESET_PASSWORD_ERROR,
        file: "AuthProvider.tsx",
      });
    }
  };

  const signOut = useCallback(async () => {
    setLoading(true);
    await auth.signOut();
    setAuthUser(null);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser: User | null) => {
      setAuthUser(firebaseUser);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    authUser,
    loading,
    signInWithGoogle,
    signOut,
    signUpWithEmail,
    signInWithPassword,
    message,
    setMessage,
    error,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
