import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import ManageStudentForm from "./ManageStudentForm";
import { useRecoilState } from "recoil";
import { studentFormAtom } from "../../recoil/userAtoms";
import { BLANK_STUDENT_FORM } from "../../libraries/blankForms";
import useSaveStudent from "../../hooks/Observations/useSaveStudent";
import { isStudentFormValid } from "../../utils/formUtils";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ManageStudentDialog = ({ open, setOpen }: Props) => {
  const [studentForm, setStudentForm] = useRecoilState(studentFormAtom);
  const { saveStudent } = useSaveStudent();

  const handleSave = useCallback(async () => {
    await saveStudent();
    setStudentForm({ ...BLANK_STUDENT_FORM });
    setOpen(false);
  }, [saveStudent, setOpen, setStudentForm]);
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle sx={{ pb: 0, mb: 0 }}>Manage Student</DialogTitle>
      <DialogContent sx={{ pt: 0, mt: 0 }}>
        <ManageStudentForm />
      </DialogContent>
      <DialogActions>
        <Button color="cancel" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={!isStudentFormValid(studentForm)}
          onClick={handleSave}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageStudentDialog;
