import { List, ListItem, Typography } from "@mui/material";
import people from "../../assets/icons/people.svg";
import settings from "../../assets/icons/settings.svg";
import chat from "../../assets/icons/chat.svg";
import { Routes } from "../../types/enums";
import { useLocation, useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      <List sx={{ width: "100%" }}>
        <ListItem
          onClick={() => navigate(Routes.STUDENTS)}
          className={pathname === Routes.STUDENTS ? "selected-style" : "unselected-style"}
        >
          <img src={people} alt="Students" style={{ width: 20 }} />
          <Typography variant="sideMenu" sx={{ ml: 1 }}>
            Students
          </Typography>
        </ListItem>
        <ListItem
          onClick={() => navigate(Routes.SETTINGS)}
          className={pathname === Routes.SETTINGS ? "selected-style" : "unselected-style"}
        >
          <img src={settings} alt="Settings" style={{ width: 20 }} />
          <Typography variant="sideMenu" sx={{ ml: 1 }}>
            Settings
          </Typography>
        </ListItem>
        <ListItem
          onClick={() => navigate(Routes.SUPPORT)}
          className={pathname === Routes.SUPPORT ? "selected-style" : "unselected-style"}
        >
          <img src={chat} alt="Help & Support" style={{ width: 20 }} />
          <Typography variant="sideMenu" sx={{ ml: 1 }}>
            Help & Support
          </Typography>
        </ListItem>
      </List>
    </>
  );
};

export default Account;
