import { Observation, Staff, Student } from "../types/types";

export const isObservationFormValid = (observationForm: Observation) => {
  return (
    observationForm.behaviorId.length > 0 &&
    observationForm.antecedentId.length > 0 &&
    observationForm.consequenceId.length > 0 &&
    observationForm.date.length > 0 &&
    observationForm.locationId.length > 0
  );
};

export const isNextEnabled = (mobileStage: number, observationForm: Observation) => {
  if (mobileStage === 0) {
    return Boolean(observationForm.behaviorId.length === 0);
  }
  if (mobileStage === 1) {
    return Boolean(observationForm.antecedentId.length === 0);
  }
  if (mobileStage === 2) {
    return Boolean(observationForm.consequenceId.length === 0);
  }
};

export const isStudentFormValid = (studentForm: Student) => {
  return (
    studentForm.firstName.length > 0 &&
    studentForm.lastName.length > 0 &&
    studentForm.grade.length > 0
  );
};

export const isStaffFormValid = (staffForm: Staff) => {
  return (
    staffForm.firstName.length > 0 &&
    staffForm.lastName.length > 0 &&
    staffForm.email.length > 0 &&
    staffForm.jobTitle.length > 0 &&
    staffForm.schoolName.length > 0 &&
    staffForm.districtName.length > 0 &&
    staffForm.schoolURL.length > 0
  );
};
