import React, { useEffect } from "react";
import CompleteProfileDialog from "../components/CompleteProfile/CompleteProfileDialog";
import SideNavigation from "../components/Navigation/SideNavigation";
import { Box } from "@mui/material";
import CollectDataContainer from "../components/CollectData/CollectDataContainer";
import TopNavbarTracker from "../components/Navigation/TopNavbarTracker";
import useWindowDimensions from "../hooks/shared/useWindowDimensions";
import MobileBottomBar from "../components/Navigation/MobileBottomBar";
import SelectStudent from "../components/Navigation/SelectStudent";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, studentsAtom } from "../recoil/userAtoms";
import { selectedStudentSurveyResultsAtom } from "../recoil/surveyAtoms";
import { TOUR_NAMES } from "../types/enums";
import { postSurveySteps } from "../libraries/tourSteps";
import { tourNameAtom, tourStepsAtom } from "../recoil/tourAtoms";
import { useTour } from "@reactour/tour";

const CollectDataPage = () => {
  const { isMobile } = useWindowDimensions();
  const students = useRecoilValue(studentsAtom);
  const selectedStudentSurveyResults = useRecoilValue(selectedStudentSurveyResultsAtom);
  const setTourName = useSetRecoilState(tourNameAtom);
  const setTourSteps = useSetRecoilState(tourStepsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { setIsOpen } = useTour();

  useEffect(() => {
    if (!loggedInStaff) return;
    if (loggedInStaff.completedTours.includes(TOUR_NAMES.POST_SURVEY_TOUR)) return;
    if (selectedStudentSurveyResults.length === 0) return;
    setTourName(TOUR_NAMES.POST_SURVEY_TOUR);
    setTourSteps(postSurveySteps);
    setIsOpen(true);
  }, [loggedInStaff, selectedStudentSurveyResults.length, setIsOpen, setTourName, setTourSteps]);

  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideNavigation />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <TopNavbarTracker />
            <CollectDataContainer />
          </Box>
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 1 }}>
            <SelectStudent students={students} />
          </Box>
          <CollectDataContainer />
          <MobileBottomBar />
        </Box>
      )}
      <CompleteProfileDialog />
    </>
  );
};

export default CollectDataPage;
