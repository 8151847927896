import { useRef } from "react";
import { StudentRecord } from "../../types/types";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import "react-quill/dist/quill.snow.css";
import { toolbarOptions } from "../../utils/quillUtils";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { COLORS } from "../../types/enums";
import GenerateRerunReason from "../shared/GenerateRerunReason";
import useExtinguishStrategies from "../../hooks/BehaviorPlans/useExtinguishStrategies";
import InfoBar from "../InfoBar/InfoBar";

type Props = {
  selectedStudent: StudentRecord;
};

const ExtinguishStrategies = ({ selectedStudent }: Props) => {
  const playerRef = useRef<any>(null);
  const {
    loading,
    handleFetchStrategy,
    handleResetAI,
    setRerunReason,
    behaviorPlanForm,
    handleTextChange,
    setOpen,
    open,
  } = useExtinguishStrategies({ selectedStudent });

  return (
    <>
      <Typography textAlign={"center"} variant="h3">
        Extinguish Strategies
      </Typography>
      <Box sx={{ mt: 1 }}>
        <InfoBar
          content="Extinguish strategies are strategies that should be consistently used in response to the
        target behavior. They should not satisfy the function of the behavior to serve as a
        deterrent to the target behavior."
        />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {loading ? (
        <Box sx={{ minHeight: 300 }}>
          <Typography sx={{ color: COLORS.PRIMARY, mt: 2 }} variant="h5" textAlign={"center"}>
            Loading Your AI Recommended Extinguish Strategies
          </Typography>
          <Player
            ref={playerRef}
            autoplay={true}
            loop={true}
            style={{ width: 300 }}
            src="https://lottie.host/4170c946-13ca-46b6-9e69-1bcf94cdf652/a6FOLfF0bI.json"
          />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, mb: 4 }}>
            {behaviorPlanForm.extinguishStrategies ? (
              <Button color="primary" onClick={handleResetAI} size="large" variant="contained">
                Generate a New Set of Strategies
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={handleFetchStrategy}
                size="large"
              >
                Generate Extinguish Strategies
              </Button>
            )}
          </Box>
          <ReactQuill
            theme="snow"
            value={behaviorPlanForm.extinguishStrategies}
            onChange={handleTextChange}
            modules={{
              toolbar: toolbarOptions,
              clipboard: {
                allowed: {
                  tags: ["a", "u", "s", "i", "br", "ul", "ol", "li", "span"],
                  attributes: ["href"],
                },
              },
            }}
          />
        </>
      )}
      <GenerateRerunReason open={open} setOpen={setOpen} setReason={setRerunReason} />
    </>
  );
};

export default ExtinguishStrategies;
