const consistencyDenom = 20;
const entrenchedDenom = 35;

type GenerateDataProps = {
  startingFrequency: number;
  consistency: number;
  entrenched: number;
};

type Week = {
  name: string;
  incidents: number;
};

//this is a very rough and estimated formula, it should be refined with more research
const calcWeek = (
  previousFrequency: number,
  consistency: number,
  entrenched: number,
  multiplier: number
) => {
  const consistencyFactor = consistency / consistencyDenom;
  const entrenchedFactor = entrenched / entrenchedDenom;
  return Math.ceil(previousFrequency * (multiplier + entrenchedFactor - consistencyFactor));
};

export const generateData = ({ startingFrequency, consistency, entrenched }: GenerateDataProps) => {
  const weeks: Week[] = [{ name: "Baseline", incidents: startingFrequency }];

  weeks.push({
    name: "Week 1",
    incidents: calcWeek(weeks[0].incidents, consistency, entrenched, 1.3),
  });
  weeks.push({
    name: "Week 2",
    incidents: calcWeek(weeks[1].incidents, consistency, entrenched, 1),
  });
  weeks.push({
    name: "Week 3",
    incidents: calcWeek(weeks[2].incidents, consistency, entrenched, 0.85),
  });
  weeks.push({
    name: "Week 4",
    incidents: calcWeek(weeks[3].incidents, consistency, entrenched, 0.8),
  });
  weeks.push({
    name: "Week 5",
    incidents: calcWeek(weeks[4].incidents, consistency, entrenched, 0.7),
  });
  weeks.push({
    name: "Week 6",
    incidents: calcWeek(weeks[5].incidents, consistency, entrenched, 0.7),
  });
  weeks.push({
    name: "Week 7",
    incidents: calcWeek(weeks[6].incidents, consistency, entrenched, 0.6),
  });
  weeks.push({
    name: "Week 8",
    incidents: calcWeek(weeks[7].incidents, consistency, entrenched, 0.5),
  });

  return weeks;
};

export const calcDomain = (max: number) => {
  if (max < 10) return [0, 10];
  if (max < 20) return [0, 20];
  if (max < 30) return [0, 30];
  return [0, 40];
};

export const formatConsistency = (value: number) => {
  switch (value) {
    case 1:
      return "Not at all";
    case 2:
      return "Slightly";
    case 3:
      return "Moderately";
    case 4:
      return "Very";
    default:
      return "Not at all";
  }
};

export const formatEntrenched = (value: number) => {
  switch (value) {
    case 1:
      return "This is a new behavior";
    case 2:
      return "For a few weeks";
    case 3:
      return "For a few months";
    case 4:
      return "For years";
    default:
      return "This is a new behavior";
  }
};
