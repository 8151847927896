import { useErrorContext } from "../../contexts/ErrorContext";
import { EVENT_TYPE, LOG_SEVERITY } from "../../types/logEnums";
import { fetchData } from "../../utils/util";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { Log } from "../../types/types";

const useHandleError = () => {
  const { setErrorMessage } = useErrorContext();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  type Props = {
    error: any;
    snackbarMessage: string;
    eventType: EVENT_TYPE;
    file: string;
  };

  const handleError = ({ error, snackbarMessage, eventType, file }: Props) => {
    const errorProp =
      error instanceof Error
        ? { message: error.message, name: error.name, stack: error.stack }
        : null;
    const now = new Date().toISOString();
    const userId = loggedInStaff?.id || "No Logged In User";
    const payload = {
      severity: LOG_SEVERITY.ERROR,
      eventType: eventType,
      timestamp: now,
      userId: userId,
      error: errorProp,
      file: file,
      changeLog: null,
    } as Log;
    setErrorMessage(snackbarMessage);
    fetchData({
      url: "logger/bxLogger",
      payload,
    });
    console.error(payload);
  };

  return { handleError };
};

export default useHandleError;
