import React, { useRef } from "react";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import { Box, Divider, Typography } from "@mui/material";
import print from "../../assets/icons/print.svg";
import PrintTable from "./PrintTable";
import { useReactToPrint } from "react-to-print";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlan: BehaviorPlanRecord;
};

const BehaviorPlanPreview = ({ selectedStudent, behaviorPlan }: Props) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
        <Box
          onClick={handlePrint}
          sx={{
            background: "linear-gradient(90deg, #FBFFE8 0%, #CFFBF5 50%, #EEF3FF 100%)",
            borderRadius: "12px",
            width: "fit-content",
            px: 3,
            py: 1,
            display: "flex",
            border: "1px solid #eee",
            cursor: "pointer",
          }}
        >
          <img src={print} alt="Print" style={{ width: 20 }} />
          <Typography textAlign="center" variant="h6" sx={{ ml: 1, fontSize: 16 }}>
            Print
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />

      <Box
        ref={componentRef}
        sx={{ width: "8.5in", margin: "0px auto", backgroundColor: "#ffffff", padding: 2 }}
      >
        <PrintTable selectedStudent={selectedStudent} behaviorPlan={behaviorPlan} />
      </Box>
    </>
  );
};

export default BehaviorPlanPreview;
