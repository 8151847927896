import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import { useSetRecoilState } from "recoil";
import { studentsAtom } from "../../recoil/userAtoms";
import { Dispatch, SetStateAction, useCallback } from "react";
import useHandleError from "../shared/useHandleError";
import { EVENT_TYPE } from "../../types/logEnums";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  setDeleteId: Dispatch<SetStateAction<string | null>>;
};

const useDeleteStudentDialog = ({ setOpen, id, setDeleteId }: Props) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const setStudents = useSetRecoilState(studentsAtom);
  const { handleError } = useHandleError();

  const handleDelete = useCallback(async () => {
    try {
      await updateFSDoc({
        col: Collections.Students,
        data: { active: false },
        id: id,
      });
      setStudents((prev) => prev.filter((record) => record.id !== id));
      setOpen(false);
      setDeleteId(null);
    } catch (error) {
      handleError({
        error,
        file: "useDeleteStudentDialog.ts",
        eventType: EVENT_TYPE.DELETE_STUDENT,
        snackbarMessage: "Error deleting student, please refresh and try again",
      });
    }
  }, [handleError, id, setDeleteId, setOpen, setStudents, updateFSDoc]);
  return { handleDelete };
};

export default useDeleteStudentDialog;
