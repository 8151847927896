import { Box, Typography } from "@mui/material";
import InfoBar from "../InfoBar/InfoBar";
import ManageTrackingActuals from "./ManageTrackingActuals";
import TrackingChart from "./TrackingChart";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useEffect, useRef, useState } from "react";
import { BehaviorPlanRecord } from "../../types/types";
import { behaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/shared/useWindowDimensions";
import { COLORS } from "../../types/enums";
import { Player } from "@lottiefiles/react-lottie-player";

const TrackProgressContainer = () => {
  const { isMobile } = useWindowDimensions();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const behaviorPlans = useRecoilValue(behaviorPlansAtom);
  const playerRef = useRef<any>(null);
  const [activeBehaviorPlan, setActiveBehaviorPlan] = useState<BehaviorPlanRecord | null>(null);
  useEffect(() => {
    if (!selectedStudent) return;
    const filteredBehaviorPlan = behaviorPlans.find(
      (plan) => plan.id === selectedStudent.activeBehaviorPlanId
    );
    setActiveBehaviorPlan(filteredBehaviorPlan || null);
  }, [behaviorPlans, selectedStudent]);

  if (!selectedStudent) return null;
  return (
    <Box sx={{ p: isMobile ? 1 : 4, pt: isMobile ? 1 : 4, pr: isMobile ? 1 : 2 }}>
      {activeBehaviorPlan ? (
        <>
          <InfoBar content={INFO_TEXT} />
          <Typography sx={{ mb: 1 }} variant="h3">
            Track Progress
          </Typography>
          <ManageTrackingActuals selectedStudent={selectedStudent} />
          <Box sx={{ p: 2, width: "76=5VW", overflow: "scroll", height: "80VH" }}>
            <TrackingChart selectedStudent={selectedStudent} />
          </Box>
        </>
      ) : (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography
            sx={{ color: COLORS.PRIMARY, mt: 2, fontSize: 26 }}
            variant="h2"
            textAlign={"center"}
          >
            No active behavior plan found for this student
          </Typography>
          <Link to="/build-plan">
            <Typography sx={{ mt: 1, color: "#333" }} variant="h5">
              Click Here To Select Or Build A Behavior Plan
            </Typography>
            <Player
              ref={playerRef}
              autoplay={true}
              loop={true}
              style={{ width: 400 }}
              src="https://lottie.host/8644fcd4-91bd-411f-a2cf-99a4a742b99b/GKZhRHWnKh.json"
            />
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default TrackProgressContainer;

const INFO_TEXT =
  "Each week, enter the average number of times per day the target behavior occured. The more accurate you can be in your tracking, the better we will be able to determine what's working and what may not be working in your behavior plan.";
