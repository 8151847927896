import React from "react";
import CompleteProfileDialog from "../components/CompleteProfile/CompleteProfileDialog";
import SideNavigation from "../components/Navigation/SideNavigation";
import { Box } from "@mui/material";
import TopNavbarTracker from "../components/Navigation/TopNavbarTracker";
import TrackProgressContainer from "../components/TrackProgress/TrackProgressContainer";
import MobileBottomBar from "../components/Navigation/MobileBottomBar";
import useWindowDimensions from "../hooks/shared/useWindowDimensions";

const TrackProgressPage = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideNavigation />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <TopNavbarTracker />
            <TrackProgressContainer />
          </Box>
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <TrackProgressContainer />
          <MobileBottomBar />
        </Box>
      )}
      <CompleteProfileDialog />
    </>
  );
};

export default TrackProgressPage;
