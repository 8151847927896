import React, { useState } from "react";
import { SurveyQuestionRecord, SurveyResultRecord } from "../../types/types";
import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { surveyQuestionsAtom } from "../../recoil/surveyAtoms";
import Question from "./Question";
import FunctionOfBehaviorContainer from "./FunctionOfBehaviorContainer";
import EditAnswerDialog from "./EditAnswerDialog";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { getBehaviorLabel } from "../../utils/formatUtils";

type Props = {
  selectedSurvey: SurveyResultRecord | null;
  setSelectedSurvey: React.Dispatch<React.SetStateAction<SurveyResultRecord | null>>;
};

function SurveyContainer({ selectedSurvey, setSelectedSurvey }: Props) {
  const questions = useRecoilValue(surveyQuestionsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [selectedQuestion, setSelectedQuestion] = useState<SurveyQuestionRecord | null>(null);
  const [editOpen, setEditOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState<string | null>(null);

  return (
    <>
      {selectedStudent && questions && selectedSurvey && (
        <Box sx={{ mt: 1, textAlign: "center" }}>
          <Typography variant="h5">{getBehaviorLabel(selectedSurvey.behaviorId)}</Typography>
          <Box sx={{ overflow: "scroll" }}>
            <FunctionOfBehaviorContainer selectedSurvey={selectedSurvey} />
          </Box>
          {questions.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              index={index}
              selectedSurvey={selectedSurvey}
              setCurrentOption={setCurrentOption}
              setSelectedQuestion={setSelectedQuestion}
              setOpen={setEditOpen}
              selectedStudent={selectedStudent}
            />
          ))}
        </Box>
      )}
      <EditAnswerDialog
        question={selectedQuestion}
        currentOption={currentOption}
        open={editOpen}
        setOpen={setEditOpen}
        selectedSurvey={selectedSurvey}
        setSelectedSurvey={setSelectedSurvey}
      />
    </>
  );
}

export default SurveyContainer;
