import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SupportConfirmationDialog = ({ open, setOpen }: Props) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
        <DialogContent sx={{ p: 4 }}>
          <Typography variant="h4">Thank you for reaching out!</Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            We will get back to you as soon as possible.
          </Typography>
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SupportConfirmationDialog;
