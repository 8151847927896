import React, { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { locationsAtom } from "../../recoil/observationAtoms";
import useAddFSDoc from "../../hooks/shared/useAddFSDoc";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { Collections } from "../../types/enums";

const useLocationsContainer = () => {
  const setLocations = useSetRecoilState(locationsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [newLocation, setNewLocation] = useState("");
  const { addFSDoc } = useAddFSDoc();

  const handleSubmit = async () => {
    if (!loggedInStaff) return;
    const data = { name: newLocation, staffId: loggedInStaff?.id };
    const results = await addFSDoc({
      col: Collections.Locations,
      data: data,
    });
    if (!results) return;
    setLocations((prev) => [...prev, { ...results, ...data }]);
    setNewLocation("");
  };

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLocation(e.target.value);
  }, []);
  return { handleSubmit, handleTextChange, newLocation };
};

export default useLocationsContainer;
