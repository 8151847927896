import { useRecoilValue, useSetRecoilState } from "recoil";
import useAddFSDoc from "../shared/useAddFSDoc";
import { Collections } from "../../types/enums";
import {
  editStudentIdAtom,
  loggedInStaffAtom,
  selectedStudentAtom,
  studentFormAtom,
  studentsAtom,
} from "../../recoil/userAtoms";
import { BaseRecord, Student } from "../../types/types";
import useUpdateFSDoc from "../shared/useUpdateFSDoc";

const useSaveStudent = () => {
  const editStudentId = useRecoilValue(editStudentIdAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setStudents = useSetRecoilState(studentsAtom);
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const studentForm = useRecoilValue(studentFormAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);

  const editStudent = async (id: string) => {
    const lastUpdatedAt: string | null = await updateFSDoc({
      col: Collections.Students,
      data: studentForm,
      id: id,
    });
    if (!lastUpdatedAt) return;
    setStudents((pV) => {
      const toEdit = [...pV];
      const index = toEdit.findIndex((record) => record.id === editStudentId);
      toEdit[index] = { ...toEdit[index], ...studentForm, lastUpdatedAt };
      return [...toEdit];
    });
  };

  const createStudent = async (loggedInStaffId: string) => {
    const record: Student = { ...studentForm, staffId: loggedInStaffId };
    const docData: BaseRecord | null = await addFSDoc({
      col: Collections.Students,
      data: record,
    });
    if (!docData) return;
    setSelectedStudent({ ...docData, ...record });
    setStudents((prev) => [{ ...docData, ...record }, ...prev]);
  };

  const saveStudent = async () => {
    if (!loggedInStaff) return;
    // Edit existing record
    if (editStudentId) {
      editStudent(editStudentId);
    } else {
      createStudent(loggedInStaff.id);
    }
  };
  return { saveStudent };
};

export default useSaveStudent;
