import { atom, selector } from "recoil";
import { StaffRecord, Student, StudentRecord } from "../types/types";
import { BLANK_STUDENT_FORM } from "../libraries/blankForms";
import { observationsAtom } from "./observationAtoms";

export const loggedInStaffAtom = atom<StaffRecord | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffSignupOpenAtom = atom<boolean>({
  key: "staffSignupOpen",
  default: false,
});

export const studentFormAtom = atom<Student>({
  key: "studentForm",
  default: BLANK_STUDENT_FORM,
});

export const studentsAtom = atom<StudentRecord[]>({
  key: "students",
  default: [],
});

export const editStudentIdAtom = atom<string | null>({
  key: "editStudentId",
  default: null,
});

export const staffFormAtom = atom<StaffRecord | null>({
  key: "staffForm",
  default: null,
});

export const selectedStudentAtom = atom<StudentRecord | null>({
  key: "selectedStudent",
  default: null,
});

export const selectedStudentObservationsAtom = selector({
  key: "selectedStudentObservations",
  get: ({ get }) => {
    const selectedStudent = get(selectedStudentAtom);
    const observations = get(observationsAtom);
    if (!selectedStudent) return [];
    return observations.filter((observation) => observation.studentId === selectedStudent.id);
  },
});
