import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import { Avatar, Box, Divider, Typography } from "@mui/material";
import { behaviors, replacementBehaviors } from "../../libraries/abcData";
import parse from "html-react-parser";
import { COLORS } from "../../types/enums";
import { useEffect, useState } from "react";
import { getImageURL } from "../../utils/imageUtils";
import targetBehaviorIcon from "../../assets/icons/target-behavior-icon.svg";
import replacementBehaviorIcon from "../../assets/icons/replacement-behavior-icon.svg";
import PreviewChart from "./PreviewChart";
import "../../styles/behavior-plan.css";

type Props = {
  selectedStudent: StudentRecord;
  behaviorPlan: BehaviorPlanRecord;
};

const BehaviorPlanForPrint = ({ selectedStudent, behaviorPlan }: Props) => {
  const [avatarURL, setAvatarURL] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (selectedStudent.avatar.length > 5) {
        const url = await getImageURL(selectedStudent.avatar);
        setAvatarURL(url);
      }
    };
    fetchImage();
  }, [selectedStudent.avatar]);

  //Orange
  //Green
  //Blue
  //Purple

  return (
    <>
      <Box
        className="behavior-plan-background"
        sx={{
          borderRadius: 8,
          background: `
    radial-gradient(circle at 0% 15%, rgba(255, 232, 206, 0.5), transparent 40%),
    radial-gradient(circle at 30% 0%, rgba(236, 255, 236, 0.5), transparent 40%),
    radial-gradient(circle at 90% 5%, rgba(223, 240, 255, 0.5), transparent 40%),
    radial-gradient(circle at 95% 20%, rgba(255, 223, 255, 0.5), transparent 40%),
    white
  `,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "flex-start", p: 4 }}>
            <Box>
              <Avatar
                src={avatarURL ? avatarURL : ""}
                sx={{ bgcolor: COLORS.PRIMARY, width: 120, height: 120 }}
              >
                {selectedStudent.firstName.charAt(0)}
                {selectedStudent.lastName.charAt(0)}
              </Avatar>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {`${selectedStudent.firstName} ${selectedStudent.lastName}`}
                </Typography>
                <Box sx={{ border: 1, borderColor: COLORS.PRIMARY, borderRadius: 8, ml: 2, p: 1 }}>
                  <Typography variant="body2" sx={{ color: COLORS.PRIMARY }}>
                    {selectedStudent.grade}
                  </Typography>
                </Box>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: 400, textAlign: "justify" }} variant="body1">
                {behaviorPlan.bio}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mx: 4, backgroundColor: "#fff", p: 2, borderRadius: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Box sx={{ px: 2 }}>
              <img src={targetBehaviorIcon} alt="Target Behavior" style={{ width: 50 }} />
            </Box>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                Target Behavior
              </Typography>
              <Typography sx={{ mt: "2px" }} variant="body1">
                The challenging behavior you want to change
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box sx={{ mt: 2, ml: 2 }}>
            <Typography variant="h6">
              {behaviors.find((behavior) => behavior.id === behaviorPlan.targetBehavior)?.label}{" "}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {parse(behaviorPlan.targetBehaviorNotes.toString())}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mx: 4, backgroundColor: "#fff", p: 2, borderRadius: 4, mt: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Box sx={{ px: 2 }}>
              <img src={replacementBehaviorIcon} alt="Replacement Behavior" style={{ width: 50 }} />
            </Box>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                Replacement Behavior
              </Typography>
              <Typography sx={{ mt: "2px" }} variant="body1">
                The positive behavior you want to replace the target behavior with
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box sx={{ mt: 1, ml: 2 }}>
            <Typography variant="h6">
              {
                replacementBehaviors.find(
                  (behavior) => behavior.id === behaviorPlan.replacementBehavior
                )?.label
              }
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {parse(behaviorPlan.replacementBehaviorsNotes.toString())}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ p: 2, borderRadius: 2, mt: 2 }}>
          <Typography variant="h5">Prevention Strategies</Typography>
          <Divider sx={{ borderColor: COLORS.PRIMARY_LIGHT, borderWidth: 2 }} />
          <Typography variant="body1" sx={{ mt: 1 }}>
            {parse(behaviorPlan.preventionStrategies.toString())}
          </Typography>
        </Box>
        <Box sx={{ p: 2, borderRadius: 2, mt: 2 }}>
          <Typography variant="h5">Reinforcement Strategies</Typography>
          <Divider sx={{ borderColor: COLORS.PRIMARY_LIGHT, borderWidth: 2 }} />
          <Typography variant="body1" sx={{ mt: 1 }}>
            {parse(behaviorPlan.reinforcementStrategies.toString())}
          </Typography>
        </Box>
        <Box sx={{ p: 2, borderRadius: 2, mt: 2 }}>
          <Typography variant="h5">Extinguish Strategies</Typography>
          <Divider sx={{ borderColor: COLORS.PRIMARY_LIGHT, borderWidth: 2 }} />
          <Typography variant="body1" sx={{ mt: 1 }}>
            {parse(behaviorPlan.extinguishStrategies.toString())}
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h4">Weekly Goals</Typography>
          <Typography variant="subtitle1">Number of Incidents / Week</Typography>
          <PreviewChart behaviorPlan={behaviorPlan} />
        </Box>
      </Box>
    </>
  );
};

export default BehaviorPlanForPrint;
