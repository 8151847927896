import { DateTimeFormatOptions } from "luxon";

export function convertISOtoMMDDYYYY(isoString: string) {
  if (isoString === "") return "Unknown"; // Return empty string if isoString is empty (no date selected
  const date = new Date(isoString);
  const month = date.getMonth() + 1; // Months are zero-indexed (0-11)
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;
}

export const convertISOToReadable = (isoString: string) => {
  const date = new Date(isoString);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight for 'today' comparison

  // Today
  if (date.toDateString() === today.toDateString()) {
    return `Today`;
  }

  // Yesterday
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday`;
  }

  // Less than 90 days ago
  const ninetyDaysAgo = new Date(today);
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
  if (date > ninetyDaysAgo) {
    return formatDateWithSuffix(date);
  }

  // More than 90 days ago
  const options: DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

function formatDateWithSuffix(date: Date) {
  const day = date.getDate();
  const suffix = getDaySuffix(day);
  const options: DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options) + suffix;
}

function getDaySuffix(day: number) {
  if (day < 1 || day > 31) {
    throw new Error("Invalid day: Day must be between 1 and 31");
  }

  // Special cases: 11th, 12th, 13th
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
