import { useState, useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

type SendRequestProps = {
  col: string;
  data: {};
  id: string;
};

const useUpdateFSDoc = () => {
  const [isLoading, setIsLoading] = useState(false);

  const updateFSDoc = useCallback(
    async ({ col, data, id }: SendRequestProps): Promise<string | null> => {
      setIsLoading(true);
      const now = new Date().toISOString();
      const finalData = { ...data, lastUpdatedAt: now };
      const docRef = doc(db, col, id);
      await updateDoc(docRef, finalData);
      setIsLoading(false);
      return now;
    },
    []
  );
  return { isLoading, updateFSDoc };
};

export default useUpdateFSDoc;
