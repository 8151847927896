import { List, ListItem, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import clipboard from "../../assets/icons/clipboard.svg";
import stack from "../../assets/icons/stack.svg";
import chart from "../../assets/icons/chart.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, TourSelector } from "../../types/enums";

const Actions = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!loggedInStaff) return null;
  return (
    <List>
      <ListItem
        onClick={() => navigate(Routes.COLLECT_DATA)}
        className={pathname === Routes.COLLECT_DATA ? "selected-style" : "unselected-style"}
      >
        <img src={clipboard} alt="Collect Data" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          Collect Data
        </Typography>
      </ListItem>
      <ListItem
        onClick={() => navigate(Routes.BUILD_PLAN)}
        data-tut={TourSelector.BUILD_BX_PLAN}
        className={pathname === Routes.BUILD_PLAN ? "selected-style" : "unselected-style"}
      >
        <img src={stack} alt="Build Behavior Plan" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          Build Behavior Plan
        </Typography>
      </ListItem>
      <ListItem
        onClick={() => navigate(Routes.TRACK_PROGRESS)}
        className={pathname === Routes.TRACK_PROGRESS ? "selected-style" : "unselected-style"}
      >
        <img src={chart} alt="Track Progress" style={{ width: 20 }} />
        <Typography variant="sideMenu" sx={{ ml: 1 }}>
          Track Progress
        </Typography>
      </ListItem>
    </List>
  );
};

export default Actions;
