import React from "react";
import { loggedInStaffAtom } from "../recoil/userAtoms";
import { useRecoilValue } from "recoil";
import PromptsContainer from "../components/Prompts/PromptsContainer";
import { useNavigate } from "react-router-dom";
import { USER_TYPES } from "../types/enums";

const PromptsPage = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const navigate = useNavigate();
  return (
    <>
      {loggedInStaff?.userType === USER_TYPES.DEVELOPER ? (
        <>
          <PromptsContainer />
        </>
      ) : (
        navigate("/login")
      )}
    </>
  );
};

export default PromptsPage;
