import { Box, Drawer, List, ListItem, Typography } from "@mui/material";
import "./navigation.css";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom, studentsAtom } from "../../recoil/userAtoms";
import icon from "../../assets/images/bp-icon-purple.png";
import SelectStudent from "./SelectStudent";
import Actions from "./Actions";
import Account from "./Account";
import Profile from "./Profile";

const drawerWidth = 220;

const SideNavigation = () => {
  const students = useRecoilValue(studentsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  if (!loggedInStaff) return null;

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        open={true}
        color="primary"
      >
        <Box sx={{ overflow: "auto" }}>
          {/* Navigation content */}
          <List>
            <ListItem>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <img src={icon} alt="logo" style={{ width: 50 }} />
              </Box>
            </ListItem>
            <ListItem sx={{ mt: 2 }}>
              <SelectStudent students={students} />
            </ListItem>
            <ListItem sx={{ mt: 4 }}>
              <Typography variant="sideMenu">ACTIONS</Typography>
            </ListItem>
            <ListItem sx={{ pr: 0 }}>
              <Actions />
            </ListItem>
            <ListItem sx={{ mt: 4 }}>
              <Typography variant="sideMenu">ACCOUNT</Typography>
            </ListItem>
            <ListItem>
              <Account />
            </ListItem>
          </List>
        </Box>
        <Profile />
      </Drawer>
    </>
  );
};

export default SideNavigation;
