import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ProfilePicture from "../shared/ProfilePicture";
import { Collections } from "../../types/enums";
import { useAuth } from "../../contexts/AuthContext";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import ProfileDialog from "../Profile/ProfileDialog";

const Profile = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [profileOpen, setProfileOpen] = useState(false);

  const { signOut } = useAuth();
  if (!loggedInStaff) return null;
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)", // Optional: adds a border on top
          padding: 2, // Adjust padding as needed
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProfilePicture
            size={40}
            fileName={loggedInStaff?.avatar ?? ""}
            collection={Collections.Staff}
            documentId={loggedInStaff.id}
            edit={false}
          />
          <Box>
            <Typography
              onClick={() => setProfileOpen(true)}
              variant="sideMenu"
              sx={{ cursor: "pointer" }}
            >{`${loggedInStaff?.firstName} ${loggedInStaff?.lastName}`}</Typography>
            <Box>
              <Typography sx={{ cursor: "pointer" }} onClick={() => signOut()} variant="caption">
                Logout
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <ProfileDialog open={profileOpen} setOpen={setProfileOpen} />
    </>
  );
};

export default Profile;
