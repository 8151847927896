import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { SurveyQuestionRecord, SurveyResultRecord } from "../../types/types";
import useEditAnswerDialog from "../../hooks/Surveys/useEditAnswerDialog";
import RadioOptions from "./RadioOptions";

type Props = {
  question: SurveyQuestionRecord | null;
  currentOption: string | null;
  open: boolean;
  setOpen: (pV: boolean) => void;
  selectedSurvey: SurveyResultRecord | null;
  setSelectedSurvey: React.Dispatch<React.SetStateAction<SurveyResultRecord | null>>;
};

function EditAnswerDialog({
  question,
  currentOption,
  open,
  setOpen,
  selectedSurvey,
  setSelectedSurvey,
}: Props) {
  const { handleClose, handleSave, handleChange, formatTextWithName, selectedOption, loading } =
    useEditAnswerDialog({
      question,
      currentOption,
      setOpen,
      selectedSurvey,
      setSelectedSurvey,
    });
  return (
    <>
      {question && currentOption && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle sx={{ fontSize: 44, textAlign: "center" }}>Edit Response</DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
            <Typography>{`${formatTextWithName(question.label)}`}</Typography>
            <RadioOptions value={selectedOption} handleChange={handleChange} />
          </DialogContent>
          <DialogActions>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleSave} variant="contained">
                Save
              </Button>
            )}
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default EditAnswerDialog;
