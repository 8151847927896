import React, { useEffect } from "react";
import { STAGES } from "../../types/enums";
import { StudentRecord } from "../../types/types";
import Behaviors from "./Behaviors";
import PreventionStrategies from "./PreventionStrategies";
import ReinforceStrategies from "./ReinforceStrategies";
import ExtinguishStrategies from "./ExtinguishStrategies";
import BehaviorPlanPreview from "./BehaviorPlanPreview";
import { useRecoilValue } from "recoil";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";
import Goals from "./Goals";
import Bio from "./Bio";

type Props = {
  behaviorPlanStage: STAGES;
  selectedStudent: StudentRecord;
  setNextDisabled: (disabled: boolean) => void;
};

const ManageBehaviorPlanRouter = ({
  behaviorPlanStage,
  selectedStudent,
  setNextDisabled,
}: Props) => {
  const behaviorPlanForm = useRecoilValue(behaviorPlanFormAtom);

  useEffect(() => {
    if (behaviorPlanStage === STAGES.STAGE_1) {
      setNextDisabled(!behaviorPlanForm.bio);
    } else if (behaviorPlanStage === STAGES.STAGE_2) {
      setNextDisabled(!behaviorPlanForm.replacementBehavior);
    } else if (behaviorPlanStage === STAGES.STAGE_3) {
      setNextDisabled(!behaviorPlanForm.preventionStrategies);
    } else if (behaviorPlanStage === STAGES.STAGE_4) {
      setNextDisabled(!behaviorPlanForm.reinforcementStrategies);
    } else if (behaviorPlanStage === STAGES.STAGE_5) {
      setNextDisabled(!behaviorPlanForm.extinguishStrategies);
    } else if (behaviorPlanStage === STAGES.STAGE_6) {
      setNextDisabled(!behaviorPlanForm.weekOneTarget);
    }
  }, [behaviorPlanForm, behaviorPlanStage, setNextDisabled]);

  return (
    <>
      {behaviorPlanStage === STAGES.STAGE_1 && (
        <Bio selectedStudent={selectedStudent} setNextDisabled={setNextDisabled} />
      )}
      {behaviorPlanStage === STAGES.STAGE_2 && (
        <Behaviors selectedStudent={selectedStudent} setNextDisabled={setNextDisabled} />
      )}
      {behaviorPlanStage === STAGES.STAGE_3 && (
        <PreventionStrategies selectedStudent={selectedStudent} />
      )}
      {behaviorPlanStage === STAGES.STAGE_4 && (
        <ReinforceStrategies selectedStudent={selectedStudent} />
      )}
      {behaviorPlanStage === STAGES.STAGE_5 && (
        <ExtinguishStrategies selectedStudent={selectedStudent} />
      )}
      {behaviorPlanStage === STAGES.STAGE_6 && <Goals />}
      {behaviorPlanStage === STAGES.STAGE_7 && (
        <BehaviorPlanPreview selectedStudent={selectedStudent} behaviorPlan={behaviorPlanForm} />
      )}
    </>
  );
};

export default ManageBehaviorPlanRouter;
