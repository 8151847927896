import { SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { behaviorPlansAtom } from "../../recoil/behaviorPlanAtoms";
import { BehaviorPlanRecord, StudentRecord } from "../../types/types";
import useUpdateFSDoc from "../../hooks/shared/useUpdateFSDoc";
import { Collections } from "../../types/enums";
import useHandleError from "../shared/useHandleError";
import { EVENT_TYPE } from "../../types/logEnums";

type Props = {
  selectedStudent: StudentRecord;
};

const useManageTrackActuals = ({ selectedStudent }: Props) => {
  const { handleError } = useHandleError();
  const { updateFSDoc } = useUpdateFSDoc();
  const [behaviorPlans, setBehaviorPlans] = useRecoilState(behaviorPlansAtom);
  const activeBehaviorPlan = behaviorPlans.find(
    (plan) => plan.id === selectedStudent.activeBehaviorPlanId
  );
  const [selectedWeek, setSelectedWeek] = React.useState<keyof BehaviorPlanRecord>("weekOneActual");
  const [actualValue, setActualValue] = React.useState<number | null>(0);

  useEffect(() => {
    const prevValue = activeBehaviorPlan ? activeBehaviorPlan[selectedWeek] : 0;
    if (prevValue === null) {
      setActualValue(null);
      return;
    }
    setActualValue(parseInt(prevValue.toString()));
  }, [activeBehaviorPlan, selectedWeek]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const value = event.target.value as keyof BehaviorPlanRecord;
    setSelectedWeek(value);
    const prevValue = activeBehaviorPlan ? activeBehaviorPlan[value] : 0;
    if (prevValue === null) {
      setActualValue(null);
      return;
    }
    setActualValue(parseInt(prevValue.toString()));
  };

  const handleSave = async () => {
    if (!activeBehaviorPlan) {
      handleError({
        error: new Error("No active behavior plan"),
        snackbarMessage: "Error saving your goal data, please refresh and try again",
        eventType: EVENT_TYPE.NO_ACTIVE_BEHAVIOR_PLAN,
        file: "useManageTrackActuals.ts",
      });
      return;
    }
    try {
      await updateFSDoc({
        col: Collections.Behavior_Plans,
        id: activeBehaviorPlan.id,
        data: { [selectedWeek]: actualValue },
      });
      setBehaviorPlans(
        behaviorPlans.map((plan) => {
          if (plan.id === activeBehaviorPlan.id) {
            return {
              ...plan,
              [selectedWeek]: actualValue,
            };
          }
          return plan;
        })
      );
    } catch (error) {
      handleError({
        error: new Error("Error saving actual goal data to firestore"),
        snackbarMessage: "Error saving your goal data, please refresh and try again",
        eventType: EVENT_TYPE.ERROR_SAVING_ACTUAL_GOAL_DATA,
        file: "useManageTrackActuals.ts",
      });
    }
  };
  return { handleSave, handleSelectChange, selectedWeek, actualValue, setActualValue };
};

export default useManageTrackActuals;
