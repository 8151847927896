import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const REASONS = [
  "Make the Strategies More Relevant To The Child's Bio",
  "Make the Results Easier To Understand",
  "Make the Results More Detailed and Thorough",
];

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setReason: (reason: string) => void;
};

const GenerateRerunReason = ({ open, setOpen, setReason }: Props) => {
  const [text, setText] = useState("");
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const submitAnotherRequest = (reason: string) => {
    setReason(reason);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Generate New Strategies</DialogTitle>
      <DialogContent>
        <Typography>
          To help us generate better strategies for you, let us know what you would like to see
          improve in the responses:
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1, flexDirection: "column" }}>
          {REASONS.map((reason) => (
            <Button
              key={reason}
              onClick={() => submitAnotherRequest(reason)}
              fullWidth
              sx={{ mt: 2 }}
              variant="outlined"
            >
              {reason}
            </Button>
          ))}
          <Typography sx={{ mt: 2, mb: 2 }}>Or, write your own reason:</Typography>

          <TextField onChange={handleTextChange} fullWidth multiline rows={4} variant="outlined" />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="error" onClick={() => setOpen(false)} variant="outlined" sx={{ mt: 2 }}>
              Cancel
            </Button>
            <Button onClick={() => submitAnotherRequest(text)} variant="contained" sx={{ mt: 2 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateRerunReason;
