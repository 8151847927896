import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import SurveyForm from "./SurveyForm";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import SendLinkDialog from "./SendLinkDialog";
import useManageSurveyDialog from "../../hooks/Surveys/useManageSurveyDialog";

type Props = {
  open: boolean;
  setOpen: (pV: boolean) => void;
};

function ManageSurveyDialog({ open, setOpen }: Props) {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const {
    handleSave,
    handleSendLinkOpen,
    notAnswered,
    setNotAnswered,
    sendLinkOpen,
    setSendLinkOpen,
  } = useManageSurveyDialog({ setOpen });

  return (
    <>
      {selectedStudent && (
        <Dialog open={open} fullWidth maxWidth="lg">
          <DialogTitle sx={{ fontSize: 44, textAlign: "center" }}>New Behavior Survey</DialogTitle>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">
              {`For ${selectedStudent.firstName} ${selectedStudent.lastName}`}
            </Typography>
            <Box onClick={handleSendLinkOpen}>
              <Typography variant="body2" sx={{ cursor: "pointer", color: "#777" }}>
                If you want someone else to fill out this survey, click here to email it to them.
              </Typography>
            </Box>
          </Box>
          <DialogContent>
            <SurveyForm notAnswered={notAnswered} setNotAnswered={setNotAnswered} />
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" onClick={handleSave}>
              Submit
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
      <SendLinkDialog open={sendLinkOpen} setOpen={setSendLinkOpen} />
    </>
  );
}

export default ManageSurveyDialog;
