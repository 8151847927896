import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import useDeleteStudentDialog from "../../hooks/Settings/useDeleteStudentDialog";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  setDeleteId: Dispatch<SetStateAction<string | null>>;
};

const DeleteStudentDialog = ({ open, setOpen, id, setDeleteId }: Props) => {
  const { handleDelete } = useDeleteStudentDialog({ id, setDeleteId, setOpen });

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this student and all their associated data? This action
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DeleteStudentDialog);
