import { TextField, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import useSignupForm from "../../hooks/Login/useSignUpForm";
import useEnterKeyPress from "../../hooks/shared/useEnterKeyPress";
import { LoadingButton } from "@mui/lab";

type Props = {
  email: string;
};

const SignUpForm = ({ email }: Props) => {
  const { signUpWithEmail, loading } = useAuth();
  useEnterKeyPress("sign-up-form-button");
  const { password, passwordConfirmCheck, handlePasswordChange, handlePasswordConfirmChange } =
    useSignupForm();
  return (
    <>
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        label="Create Password"
        type="password"
        id="create-password"
        onChange={handlePasswordChange}
        value={password}
        autoFocus
      />
      <Typography variant="caption" sx={{ mt: 1 }}>
        Password must be at least 8 characters
      </Typography>
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        label="Verify Password"
        type="password"
        id="verify-password"
        onChange={handlePasswordConfirmChange}
      />
      <LoadingButton
        onClick={() => signUpWithEmail({ email, password })}
        id="sign-up-form-button"
        color="primary"
        sx={{ mt: 2 }}
        loading={loading}
        fullWidth
        variant="contained"
        disabled={!passwordConfirmCheck}
      >
        Sign Up
      </LoadingButton>
    </>
  );
};
export default SignUpForm;
