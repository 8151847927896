import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  observationFormAtom,
  mobileStageAtom,
  observationsAtom,
  observationEditIdAtom,
} from "../../recoil/observationAtoms";
import { BLANK_OBSERVATION_FORM } from "../../libraries/blankForms";
import { useNavigate } from "react-router-dom";

const MobileSuccess = () => {
  const playerRef = useRef<any>(null);
  const [title, setTitle] = useState("Submitting...");
  const setObservationForm = useSetRecoilState(observationFormAtom);
  const setObservationEditId = useSetRecoilState(observationEditIdAtom);
  const setMobileStage = useSetRecoilState(mobileStageAtom);
  const navigate = useNavigate();
  const observations = useRecoilValue(observationsAtom);

  useEffect(() => {
    setTimeout(() => {
      setTitle("Success!");
    }, 1700);
  }, []);

  const submitAnotherRequest = () => {
    setObservationForm(BLANK_OBSERVATION_FORM);
    setMobileStage(0);
  };

  const editSubmission = () => {
    if (observations.length === 0) return;
    setObservationForm(observations[0]);
    setObservationEditId(observations[0].id);
    setMobileStage(0);
  };

  const goBackToDashboard = () => {
    setObservationForm(BLANK_OBSERVATION_FORM);
    setMobileStage(0);
    navigate("/dashboard");
  };

  return (
    <>
      <Typography textAlign="center" variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Box sx={{ mt: -10, height: 400 }}>
        <Player
          ref={playerRef}
          autoplay={true}
          loop={false}
          keepLastFrame={true}
          src="https://lottie.host/deefaeeb-19c2-4443-b833-f2cb33aa75bb/JsNx7jrqaw.json"
        />
      </Box>
      <Typography textAlign="center" variant="h6" sx={{ mt: -8 }}>
        What's Next?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <>
          <Button onClick={submitAnotherRequest} fullWidth sx={{ mt: 2 }} variant="outlined">
            Submit Another Record
          </Button>
          <Button onClick={editSubmission} fullWidth sx={{ mt: 2 }} variant="outlined">
            Go Back and Edit This Record
          </Button>
          <Button fullWidth sx={{ mt: 2 }} variant="outlined" onClick={goBackToDashboard}>
            Go Back To Your Dashboard
          </Button>
        </>
      </Box>
    </>
  );
};

export default MobileSuccess;
