import { Button, TextField } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import useSignInForm from "../../hooks/Login/useSignInForm";
import { LoadingButton } from "@mui/lab";
import useEnterKeyPress from "../../hooks/shared/useEnterKeyPress";

type Props = {
  email: string;
};

const SignInForm = ({ email }: Props) => {
  useEnterKeyPress("sign-in-form-button");
  const { signInWithPassword, resetPassword, loading } = useAuth();
  const { password, handlePasswordChange } = useSignInForm();
  return (
    <>
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        label="Password"
        type="password"
        id="enter-password"
        onChange={handlePasswordChange}
        value={password}
        autoFocus
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => resetPassword(email)}
          sx={{ mt: 2, fontSize: 12 }}
          variant="text"
          color="primary"
        >
          Forgot Password?
        </Button>
      </div>
      <LoadingButton
        onClick={() => signInWithPassword({ email, password })}
        color="primary"
        sx={{ mt: 2 }}
        fullWidth
        id="sign-in-form-button"
        variant="contained"
        loading={loading}
      >
        Log In
      </LoadingButton>
    </>
  );
};
export default SignInForm;
