import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ABCData, StudentRecord } from "../../types/types";
import { replacementBehaviors } from "../../libraries/abcData";
import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { behaviorPlanFormAtom } from "../../recoil/behaviorPlanAtoms";
import { FUNCTIONS_OF_BEHAVIOR } from "../../types/enums";

type Props = {
  selectedStudent: StudentRecord;
};

// const DEMO_TEXT =
//   "When Jeremy is starting to feel overwhelmed, he will raise his hand, and when the teacher comes to him, he will ask for a reduced amount of work. This could be skipping a question, it could be limiting the number of questions, or it could be not having to fully complete something, just doing part of a task.";

const ReplacementBehavior = ({ selectedStudent }: Props) => {
  const [behaviorPlanForm, setBehaviorPlanForm] = useRecoilState(behaviorPlanFormAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [replacementBehaviorOptions, setReplacementBehaviorOptions] = useState<ABCData[]>([]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setBehaviorPlanForm((prev) => ({ ...prev, replacementBehavior: event.target.value }));
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBehaviorPlanForm((prev) => ({ ...prev, replacementBehaviorsNotes: event.target.value }));
  };

  useEffect(() => {
    const functionOfBehavior = behaviorPlanForm.functionOfBehavior;
    switch (functionOfBehavior) {
      case FUNCTIONS_OF_BEHAVIOR.ATTENTION:
        setReplacementBehaviorOptions(
          replacementBehaviors.filter(
            (behavior) => behavior.functionOfBehavior === FUNCTIONS_OF_BEHAVIOR.ATTENTION
          )
        );
        break;
      case FUNCTIONS_OF_BEHAVIOR.ACCESS:
        setReplacementBehaviorOptions(
          replacementBehaviors.filter(
            (behavior) => behavior.functionOfBehavior === FUNCTIONS_OF_BEHAVIOR.ACCESS
          )
        );
        break;
      case FUNCTIONS_OF_BEHAVIOR.ESCAPE:
        setReplacementBehaviorOptions(
          replacementBehaviors.filter(
            (behavior) => behavior.functionOfBehavior === FUNCTIONS_OF_BEHAVIOR.ESCAPE
          )
        );
        break;
      case FUNCTIONS_OF_BEHAVIOR.SENSORY:
        setReplacementBehaviorOptions(
          replacementBehaviors.filter(
            (behavior) => behavior.functionOfBehavior === FUNCTIONS_OF_BEHAVIOR.SENSORY
          )
        );
        break;
      default:
        setReplacementBehaviorOptions([]);
    }
  }, [behaviorPlanForm.functionOfBehavior]);

  return (
    <>
      {behaviorPlanForm.targetBehavior && (
        <Box sx={{ mt: 4 }}>
          <Typography textAlign={"center"} variant="h2">
            Replacement Behavior
          </Typography>
          <Typography textAlign={"center"} variant="body1" sx={{ mt: 1 }}>
            {`Your data indicates the function of the behavior is ${behaviorPlanForm.functionOfBehavior}. Based on this information, one of the following replacement behaviors is recommended:`}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Select
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              value={behaviorPlanForm.replacementBehavior}
              onChange={handleSelectChange}
              fullWidth
            >
              {replacementBehaviorOptions.map((behavior) => (
                <MenuItem
                  sx={{ wordBreak: "break-word", whiteSpace: "normal" }}
                  key={behavior.id}
                  value={behavior.id}
                  className={isDropdownOpen ? "dropdown-open" : ""}
                >
                  {behavior.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* TODO: Make sure to create a ? that can be clicked to provide several examples of notes for replacement behaviors */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {`Please provide a brief description of the replacement behavior and any additional notes that may be helpful for staff implementing the behavior plan.`}
            </Typography>
            <TextField
              onChange={handleTextFieldChange}
              value={behaviorPlanForm.replacementBehaviorsNotes}
              multiline
              rows={3}
              fullWidth
            />
          </Box>
          {/* <Button
            onClick={() =>
              setBehaviorPlanForm((prev) => ({ ...prev, replacementBehaviorsNotes: DEMO_TEXT }))
            }
          >
            Use Demo Data
          </Button> */}
        </Box>
      )}
    </>
  );
};

export default ReplacementBehavior;
