import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { behaviors } from "../../libraries/abcData";
import useSendLinkDialog from "../../hooks/Surveys/useSendLinkDialog";

type Props = {
  open: boolean;
  setOpen: (pV: boolean) => void;
};

function SendLinkDialog({ open, setOpen }: Props) {
  const {
    handleClose,
    handleSubmit,
    handleNameChange,
    handleEmailChange,
    handleSelectChange,
    name,
    email,
    behaviorId,
  } = useSendLinkDialog({ open, setOpen });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ padding: 1 }}>
        <DialogTitle sx={{ fontSize: 44, textAlign: "center" }}>
          Send Behavior Survey Via Email
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={name}
                type="name"
                label="Name"
                fullWidth
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={email}
                type="email"
                label="Email"
                fullWidth
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="target-behavior-label">Select Target Behavior</InputLabel>
                <Select
                  value={behaviorId ? behaviorId : ""}
                  labelId="target-behavior-label"
                  required
                  label="Select Target Behavior"
                  onChange={handleSelectChange}
                >
                  {behaviors &&
                    behaviors.map((behavior) => (
                      <MenuItem key={behavior.id} value={behavior.id}>
                        {behavior.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          Send
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendLinkDialog;
