import { STRATEGY_PROMPT_TYPES } from "../types/enums";
import {
  BehaviorPlanRecord,
  LocationRecord,
  ObservationRecord,
  PromptRecord,
  StaffRecord,
  StudentRecord,
  SurveyQuestionRecord,
  SurveyResultRecord,
} from "../types/types";

export const parseObservationsResponse = (response: ObservationRecord[]): ObservationRecord[] =>
  response.map((response: ObservationRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    behaviorId: response?.behaviorId ?? "",
    antecedentId: response?.antecedentId ?? "",
    consequenceId: response?.consequenceId ?? "",
    locationId: response?.locationId ?? "",
    date: response?.date ?? "",
    anythingElse: response?.anythingElse ?? "",
    staffId: response?.staffId ?? "",
    studentId: response?.studentId ?? "",
    functionOfBehavior: response?.functionOfBehavior ?? null,
    functionConfidence: response?.functionConfidence ?? 0,
  }));

export const parseStaffResponse = (response: StaffRecord): StaffRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  avatar: response?.avatar ?? "",
  email: response?.email ?? "",
  jobTitle: response?.jobTitle ?? "",
  schoolName: response?.schoolName ?? "",
  districtName: response?.districtName ?? "",
  schoolURL: response?.schoolURL ?? "",
  avatarURL: response?.avatarURL ?? null,
  userType: response?.userType ?? null,
  completedTours: response?.completedTours ?? [],
});

export const parseStudentsResponse = (response: StudentRecord[]): StudentRecord[] =>
  response.map((response: StudentRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    firstName: response?.firstName ?? "",
    lastName: response?.lastName ?? "",
    avatar: response?.avatar ?? "",
    grade: response?.grade ?? "",
    dob: response?.dob ?? "",
    staffId: response?.staffId ?? "",
    externalId: response?.externalId ?? "",
    avatarURL: response?.avatarURL ?? null,
    activeBehaviorPlanId: response?.activeBehaviorPlanId ?? null,
  }));

export const parseStudentResponse = (response: StudentRecord): StudentRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  avatar: response?.avatar ?? "",
  grade: response?.grade ?? "",
  dob: response?.dob ?? "",
  staffId: response?.staffId ?? "",
  externalId: response?.externalId ?? "",
  avatarURL: response?.avatarURL ?? null,
  activeBehaviorPlanId: response?.activeBehaviorPlanId ?? null,
});

export const parseLocationResponse = (response: LocationRecord[]): LocationRecord[] =>
  response.map((response: LocationRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? "",
    staffId: response?.staffId ?? "",
  }));

export const parseSurveyQuestionsResponse = (
  response: SurveyQuestionRecord[]
): SurveyQuestionRecord[] =>
  response.map((response: SurveyQuestionRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    label: response?.label ?? "",
    functionOfBehavior: response?.functionOfBehavior ?? null,
    order: response?.order ?? 0,
  }));

export const parseSurveyResultsResponse = (response: SurveyResultRecord[]): SurveyResultRecord[] =>
  response.map((response: SurveyResultRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    behaviorId: response?.behaviorId ?? "",
    submitter: response?.submitter ?? "",
    relationship: response?.relationship ?? "",
    responses: response?.responses ?? {},
    staffId: response?.staffId ?? "",
    descriptionOfBehavior: response?.descriptionOfBehavior ?? "",
  }));

export const parseBehaviorPlanResponse = (response: BehaviorPlanRecord[]): BehaviorPlanRecord[] =>
  response.map((response: BehaviorPlanRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    targetBehavior: response?.targetBehavior ?? "",
    targetBehaviorNotes: response?.targetBehaviorNotes ?? "",
    behaviorDefinition: response?.behaviorDefinition ?? "",
    functionOfBehavior: response?.functionOfBehavior ?? "",
    replacementBehavior: response?.replacementBehavior ?? "",
    replacementBehaviorsNotes: response?.replacementBehaviorsNotes ?? "",
    antecedents: response?.antecedents ?? [],
    antecedentNotes: response?.antecedentNotes ?? "",
    preventionStrategies: response?.preventionStrategies ?? "",
    reinforcementStrategies: response?.reinforcementStrategies ?? "",
    extinguishStrategies: response?.extinguishStrategies ?? "",
    studentId: response?.studentId ?? "",
    staffId: response?.staffId ?? "",
    bio: response?.bio ?? "",
    startingFrequency: response?.startingFrequency ?? 0,
    frequencyRate: response?.frequencyRate ?? "",
    weekOneTarget: response?.weekOneTarget ?? 0,
    weekOneActual: response?.weekOneActual ?? null,
    weekTwoTarget: response?.weekTwoTarget ?? 0,
    weekTwoActual: response?.weekTwoActual ?? null,
    weekThreeTarget: response?.weekThreeTarget ?? 0,
    weekThreeActual: response?.weekThreeActual ?? null,
    weekFourTarget: response?.weekFourTarget ?? 0,
    weekFourActual: response?.weekFourActual ?? null,
    weekFiveTarget: response?.weekFiveTarget ?? 0,
    weekFiveActual: response?.weekFiveActual ?? null,
    weekSixTarget: response?.weekSixTarget ?? 0,
    weekSixActual: response?.weekSixActual ?? null,
    weekSevenTarget: response?.weekSevenTarget ?? 0,
    weekSevenActual: response?.weekSevenActual ?? null,
    weekEightTarget: response?.weekEightTarget ?? 0,
    weekEightActual: response?.weekEightActual ?? null,
  }));

export const parsePromptsResponse = (response: PromptRecord[]): PromptRecord[] =>
  response.map((response: PromptRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    prompt: response?.prompt ?? "",
    type: response?.type ?? STRATEGY_PROMPT_TYPES.ALL,
  }));
