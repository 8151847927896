import { atom, selector } from "recoil";
import { PromptRecord } from "../types/types";
import { STRATEGY_PROMPT_TYPES } from "../types/enums";

export const promptsAtom = atom<PromptRecord[]>({
  key: "prompts",
  default: [],
});

export const preventionPromptAtom = selector({
  key: "preventionPrompt",
  get: ({ get }) => {
    const prompts = get(promptsAtom);
    return prompts.find((prompt) => prompt.type === STRATEGY_PROMPT_TYPES.PREVENTION);
  },
});

export const reinforcementPromptAtom = selector({
  key: "reinforcementPrompt",
  get: ({ get }) => {
    const prompts = get(promptsAtom);
    return prompts.find((prompt) => prompt.type === STRATEGY_PROMPT_TYPES.REINFORCEMENT);
  },
});

export const extinguishPromptAtom = selector({
  key: "extinguishPrompt",
  get: ({ get }) => {
    const prompts = get(promptsAtom);
    return prompts.find((prompt) => prompt.type === STRATEGY_PROMPT_TYPES.EXTINGUISH);
  },
});

export const allPromptAtom = selector({
  key: "allPrompt",
  get: ({ get }) => {
    const prompts = get(promptsAtom);
    return prompts.find((prompt) => prompt.type === STRATEGY_PROMPT_TYPES.ALL);
  },
});
